import {Deployment} from "@common-core/runtime-js/runtime";
import {Metadata} from "./Metadata";
import {DeploymentType} from "./DeploymentType";

export const local: Deployment<Metadata> = {
  id: "local",
  hostname: "localhost.coxautoservices.com",
  secure: true,
  port: 8443,
  metadata: {
    type: DeploymentType.LOCAL
  }
};

export const dangerzone: Deployment<Metadata> = {
  id: "dangerzone",
  hostname: "coat2.dangerzone.coxautoservices.com",
  secure: true,
  metadata: {
    type: DeploymentType.DZ
  }
};

export const dangerzoneIntegration: Deployment<Metadata> = {
  id: "dangerzone-integration",
  hostname: "integration.coat2.dangerzone.coxautoservices.com",
  secure: true,
  metadata: {
    type: DeploymentType.DZ_INTEGRATION
  }
};

export const dangerzoneLegacy: Deployment<Metadata> = {
  id: "dangerzone-legacy",
  hostname: "dangerzone.coat2.coxautoservices.com",
  secure: true,
  metadata: {
    type: DeploymentType.DZ_LEGACY
  }
};

export const live: Deployment<Metadata> = {
  id: "live",
  hostname: "coat2.coxautoinc.com",
  secure: true,
  metadata: {
    type: DeploymentType.LIVE
  }
};

export const liveNew: Deployment<Metadata> = {
  id: "live-new",
  hostname: "coat.coxautoinc.com",
  secure: true,
  metadata: {
    type: DeploymentType.LIVE_NEW
  }
};

export const liveNewIntegration: Deployment<Metadata> = {
  id: "live-new-integration",
  hostname: "integration.coat.coxautoinc.com",
  secure: true,
  metadata: {
    type: DeploymentType.LIVE_NEW_INTEGRATION
  }
};

export const liveIntegration: Deployment<Metadata> = {
  id: "live-integration",
  hostname: "integration.coat2.coxautoinc.com",
  secure: true,
  metadata: {
    type: DeploymentType.LIVE_INTEGRATION
  }
};
