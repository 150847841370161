import {FC, useLayoutEffect} from "react";
import {Alert} from "@interstate/components/Alert";
import {Link} from "@interstate/components/Link";
import {PendoInitializerProps} from "./PendoInitializerProps";

const installGuideHref =
  "https://ghe.coxautoinc.com/PPE-CoreDomain/react-pendo#installation-guide";

export const PendoInitializer: FC<PendoInitializerProps> = ({options}) => {
  /**
   * This effect will run before the DOM is painted which ensures the agent
   * is initialized before any user interactions can occur in the app.
   *
   * If conditions dictate that the agent can be initialized, then an
   * unmount function will be returned which will tear down the agent;
   * after teardown, the agent may be safely re-initialized.
   */
  useLayoutEffect(() => {
    if (window.pendo && options) {
      console.group("Pendo Initializer");
      console.dir(options);
      console.groupEnd();
      window.pendo.initialize(options);
      return () => {
        if (window.pendo?.teardown) {
          window.pendo.teardown();
        } else {
          console.warn(
            "The Pendo agent is present but teardown function does not exist"
          );
        }
      };
    }
  }, [options]);

  return window.pendo ? null : (
    <div
      data-testid={"pendo-agent-error"}
      className={"pendo-agent-error"}
      style={{
        display: "flex",
        placeContent: "center",
        paddingTop: "16px",
        paddingBottom: "16px",
        marginBottom: "1px",
        boxShadow: "0px 1px 1px rgb(0 0 0 / 15%)"
      }}>
      <Alert type={"error"}>
        <span>
          The Pendo Agent could not be found, please see the {""}
          <Link href={installGuideHref} inline={true}>
            Pendo Installation Guide
          </Link>
          {""} for instructions.
        </span>
      </Alert>
    </div>
  );
};
