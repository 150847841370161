import {ClientHierarchyNode} from "./ClientHierarchyNode";

export function collectClientSelectionPath(
  node: ClientHierarchyNode,
  selected: string,
  accumulator: string[] = []
): boolean {
  if (node.caId === selected) {
    accumulator.push(node.caId);
    return true;
  }
  for (const child of node.children || []) {
    if (collectClientSelectionPath(child, selected, accumulator)) {
      accumulator.push(node.caId);
      return true;
    }
  }
  return false;
}
