import {Runtime} from "@common-core/runtime-js/runtime";

export function filter<M>(current: Runtime<M>, available: Runtime<M>): boolean {
  // If on localhost, then show everything
  if (current.deployment.hostname === "localhost") {
    return true;
  }
  // When not on localhost, exclude all localhost runtimes
  return available.deployment.hostname !== "localhost";
}
