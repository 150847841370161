import {SubAccount} from "@common-core/coat-operational-hierarchy-appsync-model";
import {BoidGroup} from "../model";

export type BoidAllocationReducer = (
  allocations: BoidGroup<Record<string, boolean>>[],
  subAccount: SubAccount
) => BoidGroup<Record<string, boolean>>[];

/**
 * This reducer function collects a list of {@link BoidGroup} instances from a stream of
 * {@link SubAccount} instances. It treats them as potential allocations so all are marked as
 * initially unassigned.
 *
 * In another stream processing step, the current assignments will be merged to form the
 * final set.
 *
 * @param allocations The accumulator holding the collected allocations
 * @param subAccount The current {@link SubAccount} being processed from the stream
 *
 * @see {@link supportedBoidAllocations} for creating the accumulator
 * @see {@link mergeBoidAssignmentsIntoBoidAllocations} for merging assignments
 */
export const subAccountsToBoidAllocations: BoidAllocationReducer = (
  allocations: BoidGroup<Record<string, boolean>>[],
  subAccount: SubAccount
): BoidGroup<Record<string, boolean>>[] => {
  // Find the allocation for the current business operation's type
  const allocation = allocations.find(
    allocation =>
      allocation.type.toUpperCase() === subAccount.subAccountType.toUpperCase()
  );
  // If the allocation is present and not already assigned, mark the BOID as available for assignment
  if (allocation) {
    if (!allocation.boids[subAccount.subAccountId]) {
      allocation.boids[subAccount.subAccountId] = false;
    }
  }
  // Otherwise add a new allocation with the BOID marked as available for assignment
  else {
    allocations.push({
      type: subAccount.subAccountType.toUpperCase(),
      boids: {[subAccount.subAccountId]: false}
    });
  }
  return allocations;
};
