import {FC, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "@common-core/react-analytics";
import {SortableDetailsType, Table} from "@interstate/components/Table";
import "./ClientHierarchySearch.scss";

import type {GetComponentProps} from "rc-table/lib/interface";
import {useLazyQuery} from "@apollo/client";

import {useNavigation} from "../../../hooks";
import {useClientHierarchySearchState} from "./context/useClientHierarchySearchState";
import {
  ClientHierarchySearchResponse,
  SearchClientHierarchy
} from "../../backend";
import {Endpoints} from "../../../runtime";
import {EntitySourceType} from "../../EntitySourceType";
import {
  hasClientHierarchySearchResults,
  saveTablePaginationState,
  saveTableSortState
} from "../../../search/SearchFunctions";
import {SearchBar} from "../../../search/search-bar";
import {
  ClientHierarchyTableData,
  toClientTableData,
  useClientHierarchyTableColumns
} from "./table";

export interface SearchProps {
  query?: string;
}

export const ClientHierarchySearch: FC<SearchProps> = ({query}) => {
  const {t} = useTranslation();
  const {
    searchQuery,
    setSearchQuery,
    tableState,
    setTableState,
    searchResult,
    setSearchResult,
    resetSearchState
  } = useClientHierarchySearchState();
  const [initialRender, setInitialRender] = useState(true);
  const [searchValue, setSearchValue] = useState(query || searchQuery);
  const [search, {loading, error, data}] =
    useLazyQuery<ClientHierarchySearchResponse>(SearchClientHierarchy);
  const {navigateToEntityFromEvent} = useNavigation();
  const columns = useClientHierarchyTableColumns();
  const {productEvent} = useAnalytics();

  const onSearch = (event: any, query: string): void => {
    setSearchValue(query);
    // This messes up search if written as variables:{query: {query}}
    search({
      variables: {query},
      context: {
        endpoint: Endpoints.APPSYNC
      }
    });
  };

  const onSearchResultInteraction: GetComponentProps<
    ClientHierarchyTableData
  > = record => {
    return {
      onClick: (event: any) => {
        productEvent({
          name: "client_entity_selected",
          properties: {
            location: `${t(
              `analytics.location.${EntitySourceType.CLIENT_HIERARCHY}`
            )} Search`,
            value: `${record.key}`,
            result: "Client Entity Details Displayed"
          }
        });
        navigateToEntityFromEvent(
          event,
          record.key,
          EntitySourceType.CLIENT_HIERARCHY,
          "/client-hierarchy-search"
        );
      }
    };
  };

  /**
   * This effect monitors changes to the search result which will be
   * updated asynchronously after the search is initiated. When the
   * result is updated internally by the entity search hook, this
   * effect will run. The dependency list reflects the fact that
   * this effect only cares about changes to the result.
   */
  useEffect(() => {
    // When there are results, save to search state
    if (data) {
      setSearchQuery(searchValue);
      setSearchResult(data.results);
    }
  }, [data, error]);

  /**
   * This effect monitors changes to the search value and the filters
   * and initiates a search when they change.
   */
  useEffect(() => {
    // If this is the first render and there are cached search results,
    // do not initiate a search
    try {
      const displayCachedResults =
        initialRender &&
        hasClientHierarchySearchResults(searchResult) &&
        searchValue === searchQuery;
      if (!displayCachedResults && searchValue.trim()) {
        search({variables: {query: searchValue}});
      }
    } finally {
      setInitialRender(false);
    }
  }, []);

  const tableData = useMemo<ClientHierarchyTableData[]>(() => {
    return searchResult?.map(toClientTableData) || [];
  }, [searchResult]);

  return (
    <div
      className="client-hierarchy-search"
      data-testid={"client-hierarchy-search"}>
      <div className="client-hierarchy-search-controls">
        <SearchBar
          id={"client-hierarchy-search-box"}
          query={searchValue}
          onQueryCleared={() => {
            setSearchValue("");
            resetSearchState();
          }}
          onQueryChange={query => {
            setSearchValue(query);
          }}
          onSearch={onSearch}
          analyticsLocation={EntitySourceType.CLIENT_HIERARCHY}
          displayAssistanceButton={false}
        />
      </div>
      <Table
        id="client-hierarchy-table"
        data-testid="client-hierarchy-table"
        onChangePageSize={size => {
          saveTablePaginationState(
            {
              page: tableState.pagination.page,
              size
            },
            setTableState
          );
        }}
        onChangePage={page => {
          saveTablePaginationState(
            {
              page,
              size: tableState.pagination.size
            },
            setTableState
          );
        }}
        onChangeSorter={(sorter: any) => {
          productEvent({
            name: "table_column_sorted",
            properties: {
              value: `${sorter.field}`,
              location: `${t(
                `analytics.location.${EntitySourceType.OPERATIONAL}`
              )} Search Results`,
              result: `Columns Sorted ${sorter.order}`
            }
          });
          saveTableSortState(
            {
              column: sorter.field,
              order: sorter.order
            },
            setTableState
          );
        }}
        onRowCallback={onSearchResultInteraction}
        emptyText={
          searchQuery
            ? t("client-hierarchy-search.no-results") || "???"
            : t("client-hierarchy-search.suggestion") || "???"
        }
        isLoading={loading}
        sortableColumns={true}
        sortableDetails={
          {
            activeSortColumn: tableState.sort.column,
            sortOrder: tableState.sort.order,
            // The list of sortable columns is dictated by which columns in the column definition have a sorter attached,
            // but this sortableColumns attribute must be supplied in order to prevent all columns from being sortable.
            sortableColumns: []
          } as SortableDetailsType
        }
        columns={columns}
        data={tableData}
        enablePagination={true}
        paginationCurrent={tableState.pagination.page}
        displayPageSizeSelector={true}
        defaultPageSize={10}
        currentPageSize={tableState.pagination.size}
        paginationPageSizeOptions={[10, 20, 50, 100]}
        pageSizePosition={"bottom"}
        bordered={true}
        borderType={"contained"}
        size={"small"}
        dataDensity={"small"}
        highlightOnHover={true}
      />
    </div>
  );
};
