import {Location} from "./Location";
import {Runtime} from "./Runtime";
import {RuntimeEnvironment} from "./RuntimeEnvironment";
import {RuntimeCollection} from "./RuntimeCollection";
import {RuntimeActivator} from "./RuntimeActivator";

// prettier-ignore
export interface RuntimeController<M, E>
  extends RuntimeActivator<M, E>, RuntimeCollection<M> {
  environment(runtime: Runtime<M> | string): E | never;
  activate(from: Runtime<M> | string | Location): RuntimeEnvironment<M, E> | never;
}

export function isRuntimeController<M, E>(
  arg: any
): arg is RuntimeController<M, E> {
  const candidate = arg as RuntimeController<M, E>;
  return (
    !!candidate.onActivation &&
    typeof candidate.onActivation === "function" &&
    !!candidate.remove &&
    typeof candidate.remove === "function" &&
    !!candidate.activated &&
    typeof candidate.activated === "function" &&
    !!candidate.current &&
    typeof candidate.current === "function" &&
    !!candidate.activate &&
    typeof candidate.activate === "function" &&
    !!candidate.list &&
    typeof candidate.list === "function" &&
    !!candidate.find &&
    typeof candidate.find === "function" &&
    !!candidate.resolve &&
    typeof candidate.resolve === "function"
  );
}
