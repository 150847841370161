import {
  BoidAssignment,
  EntityStatus,
  Oem,
  OperationalEntity
} from "@common-core/coat-operational-hierarchy-appsync-model";

export interface OperationalTableData {
  key: string;
  name: string;
  city: string;
  state: string;
  oems: Oem[];
  boidAssignments: BoidAssignment[];
  statuses: EntityStatus[];
}

/**
 * Flattens the entity into structure compatible with
 * the CX Table sorting capabilities
 */
export function toOperationalTableData(
  entity: OperationalEntity
): OperationalTableData {
  return {
    key: entity.id,
    name: entity.organizationInformation?.dbaName || "",
    city: entity.organizationInformation?.address?.city || "",
    state: entity.organizationInformation?.address?.state || "",
    oems: entity.oems || [],
    boidAssignments: entity.boidAssignments || [],
    statuses: entity.statuses || []
  };
}
