import {AnalyticsUser} from "@common-core/react-analytics";
import {Subject} from "../access";

export function toAnalyticsUser(subject: Subject): AnalyticsUser {
  return {
    bridgePlatformId: subject.platformId,
    bridgeUsername: subject.username,
    isInternalUser: false
  };
}
