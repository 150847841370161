import {FC} from "react";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "@common-core/react-analytics";
import {Button} from "@interstate/components/Button";
import {useClientEntity} from "../context";
import {useNavigation} from "../../hooks";

export const CreateOperationalEntityButton: FC = () => {
  const {t} = useTranslation();
  const {entity} = useClientEntity();
  const {navigateToCreateOperationalEntity} = useNavigation();
  const {productEvent} = useAnalytics();
  return (
    <Button
      id={`entity-create-button-${entity.caId}`}
      data-testid={`entity-create-button-${entity.caId}`}
      className={"entity-create-button"}
      buttonStyle={"primary"}
      onClick={(event: any) => {
        productEvent({
          name: "create_operational_entity",
          properties: {
            location: "Client Hierarchy Page",
            value: `${entity.caId}`,
            result: `${entity.caId}`
          }
        });
        navigateToCreateOperationalEntity(
          event,
          entity.caId,
          "/client-hierarchy"
        );
      }}>
      {t("entity-detail.create-button-text")}
    </Button>
  );
};
