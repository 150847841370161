import {useEffect, useState} from "react";
import {ClientEntity} from "@common-core/coat-operational-hierarchy-appsync-model";
import {defaultTableState, TableState} from "../../../../search/context";

export function useDefaultClientHierarchySearchState(
  query: string | undefined,
  table: TableState | undefined,
  result: ClientEntity[] | undefined
) {
  const [searchQuery, setSearchQuery] = useState<string>(() => query || "");
  const [tableState, setTableState] = useState<TableState>(
    () => table || defaultTableState()
  );
  const [searchResult, setSearchResult] = useState<ClientEntity[] | undefined>(
    () => result
  );
  // Resets the search state to the initial values

  const resetSearchState = (): void => {
    setSearchQuery(query || "");
    setTableState(table || defaultTableState());
    setSearchResult(result);
  };

  useEffect(resetSearchState, [query, table, result]);

  return {
    searchQuery,
    setSearchQuery,
    tableState,
    setTableState,
    searchResult,
    setSearchResult,
    resetSearchState
  };
}
