import {FC} from "react";
import {Badge} from "@interstate/components/Badge";
import {IdentityProvider} from "@bridge/okta-auth/src/IdentityProvider";

export const IdentityProviderBadge: FC<{
  id: string;
  idp: IdentityProvider;
}> = ({id, idp}) => {
  return (
    <Badge id={id} data-testid={id} className={"idp-badge"} variant={"caution"}>
      {idp.key}
    </Badge>
  );
};
