import {Mapper} from "../util";

export interface SearchParameterArguments<R> {
  parameters: URLSearchParams;
  name: string;
  mapper: Mapper<string, R>;
  defaultValue?: string | null;
}

/**
 * Resolves a named URL search parameter by looking for the parameter
 * in the specified {@link URLSearchParams} or using the optional
 * default value if the named parameter is not present.
 *
 * Once the raw value has been resolved, it is handed to a {@link Mapper}
 * function which can map the raw value into a formal value expected by
 * the application.
 *
 * @param parameters The collection of parameters to examine
 * @param name The name of the parameter of interest
 * @param mapper A function to map the resolved search parameter
 * @param defaultValue The default value to use if the parameter is not present;
 * null by default
 */
export function resolveSearchParameter<R>({
  parameters,
  name,
  mapper,
  defaultValue = null
}: SearchParameterArguments<R>): R | null | undefined {
  const value = parameters.get(name) || defaultValue;
  return mapper(value);
}
