import {Location} from "./Location";
import {Runtime} from "./Runtime";

export type RuntimeResolverFn<M> = (location: Location) => Runtime<M> | never;

export interface RuntimeResolver<M> {
  resolve: RuntimeResolverFn<M>;
}

export function isRuntimeResolver<M>(arg: any): arg is RuntimeResolver<M> {
  return !!arg.resolve && typeof arg.resolve === "function";
}

export function asRuntimeResolver<M>(
  fn: RuntimeResolverFn<M>
): RuntimeResolver<M> {
  return {resolve: fn};
}

export function isRuntimeResolverFn<M>(arg: any): arg is RuntimeResolverFn<M> {
  return typeof arg === "function";
}

export function asRuntimeResolverFn<M>(
  resolver: RuntimeResolver<M>
): RuntimeResolverFn<M> {
  return location => resolver.resolve(location);
}
