import {FC, PropsWithChildren, useEffect, useState} from "react";
import {useAuthorization} from "./useAuthorization";
import {ProtectedProps} from "./ProtectedProps";

/**
 * The {@link Protected} component offers a simple protection
 * mechanism in that it will only render child content when the
 * {@link Subject} is authorized for the given {@link Permission}
 *
 * For a situation in which content is intended to be displayed
 * conditionally, the {@link AuthorizationCheck} may be a better
 * choice.
 *
 * @param permissions
 * @param children
 * @see Subject
 * @see useAuthorization
 * @see Permission
 * @see AuthorizationCheck
 */
export const Protected: FC<PropsWithChildren<ProtectedProps>> = ({
  permissions,
  children
}) => {
  const granted = useAuthorization();
  const [authorized, setAuthorized] = useState<boolean>(false);

  useEffect(() => {
    granted(permissions).then(setAuthorized);
  }, [permissions, granted]);

  return authorized ? <>{children}</> : null;
};
