import {FC} from "react";
import {TabRow} from "../../../tab-content";

import {OperationalEntity} from "@common-core/coat-operational-hierarchy-appsync-model";
import {Typography} from "@interstate/components/Typography";
import {EntitySourceType} from "../../EntitySourceType";
import {useNavigation} from "../../../hooks";
import "./LinkedOperationalEntities.scss";
import {
  SimpleTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@interstate/components/SimpleTable";
import {ActionButtons, CopyButton} from "../../../action-buttons";
import {useTranslation} from "react-i18next";

export const LinkedOperationalEntities: FC<{
  linkedEntities: OperationalEntity[];
}> = ({linkedEntities}) => {
  const {navigateToEntity} = useNavigation();
  const {t} = useTranslation();
  return (
    <TabRow
      name={"linked-common-org-entities"}
      header={
        <h4>{t("client-entity-details.linked-entities-table.header")}</h4>
      }>
      {linkedEntities && linkedEntities.length > 0 ? (
        <SimpleTable
          background={"white"}
          id={"linked-common-org-entity-table"}
          data-testid={"linked-common-org-entity-table"}>
          <TableHead>
            <TableRow>
              <TableCell>
                {t("client-entity-details.linked-entities-table.column-name")}
              </TableCell>
              <TableCell>
                {t("client-entity-details.linked-entities-table.column-id")}
              </TableCell>
              <TableCell className={"linked-common-org-actions"} />
            </TableRow>
          </TableHead>
          <TableBody>
            {linkedEntities
              .sort((a: OperationalEntity, b: OperationalEntity) =>
                a.organizationInformation?.dbaName.localeCompare(
                  b.organizationInformation?.dbaName
                )
              )
              .map(entity => (
                <TableRow key={`${entity.id}`}>
                  <TableCell className={"linked-entity-name"}>
                    {entity.organizationInformation?.dbaName}
                  </TableCell>
                  <TableCell className={"linked-entity-common-org-id"}>
                    <div
                      onClick={() =>
                        navigateToEntity(
                          entity.id,
                          EntitySourceType.OPERATIONAL
                        )
                      }>
                      {entity.id}
                    </div>
                  </TableCell>
                  <TableCell className={"linked-entity-actions"}>
                    <ActionButtons>
                      <CopyButton
                        qualifier={entity.id}
                        item={t("entity-detail.common-org-id")}
                        content={entity.id}
                        inline={true}
                      />
                    </ActionButtons>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </SimpleTable>
      ) : (
        <Typography
          tag={"span"}
          className={"no-linked-entities"}
          variant={"body-sm"}>
          {t("client-entity-details.linked-entities-table.empty")}
        </Typography>
      )}
    </TabRow>
  );
};
