export enum Partition {
  PROD = "prod",
  NON_PROD = "non-prod"
}

export type PartitionFormatter = (partition: Partition) => string;

const values = Object.values(Partition);

export function valueOf(alias?: string | null): Partition | null {
  const normalized = alias?.toLowerCase().replace("_", "-");
  return values.find(partition => partition.valueOf() === normalized) || null;
}
