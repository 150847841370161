import {resolveSearchParameter, resolveStoredSearchParameter} from "../search";
import {Partition, valueOf} from "./Partition";

export interface PartitionOptions {
  persistent?: boolean;
  defaultValue?: "prod" | "non-prod";
  storageKey?: string;
  storage?: Storage;
}

export function currentPartition(
  parameters: URLSearchParams,
  {
    persistent = false,
    defaultValue = "prod",
    storageKey = "partition",
    storage = sessionStorage
  }: PartitionOptions = {}
): Partition {
  if (persistent) {
    const partition = resolveStoredSearchParameter<Partition>({
      parameters,
      name: "partition",
      mapper: valueOf,
      defaultValue,
      key: storageKey,
      storage
    });
    return partition ? partition : Partition.PROD;
  } else {
    const partition = resolveSearchParameter<Partition>({
      parameters,
      name: "partition",
      mapper: valueOf,
      defaultValue
    });
    return partition ? partition : Partition.PROD;
  }
}
