/* eslint-disable react/no-multi-comp */
import {
  Context,
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from "react";
import {useAuthorization} from "./useAuthorization";
import {ProtectedProps} from "./ProtectedProps";

export interface AccessDecision {
  authorized: boolean;
}

export const AccessDecisionContext: Context<AccessDecision> =
  createContext<AccessDecision>({
    authorized: false
  });

export const useDecision = (): AccessDecision =>
  useContext(AccessDecisionContext);

/**
 * As a child of the {@link AuthorizationCheck} component, the {@link Authorized}
 * component will only render content if the {@link Subject} is authorized
 * for the given {@link Permission}
 *
 * <i><b>Note:</b> currently access control is being implemented
 * with LaunchDarkly; these components are intended to insulate
 * callers from a change in the access control mechanism. It
 * is expected that in the future, this would be a concern of
 * the {@link Subject}</i>
 *
 * @param {ReactNode} children
 * @see AuthorizationCheck
 */
export const Authorized: FC<PropsWithChildren> = ({children}) => {
  const {authorized} = useDecision();
  return authorized ? <>{children}</> : null;
};

/**
 * As a child of the {@link AuthorizationCheck} component, the {@link Unauthorized}
 * component will only render content if the {@link Subject} is not authorized
 * for the given {@link Permission}
 *
 * @param {ReactNode} children
 * @see AuthorizationCheck
 */
export const Unauthorized: FC<PropsWithChildren> = ({children}) => {
  const {authorized} = useDecision();
  return !authorized ? <>{children}</> : null;
};

/**
 * The {@link AuthorizationCheck} component provides the ability to conditionally
 * render content if the {@link Subject} is authorized or unauthorized for
 * a given {@link Permission}.
 *
 * For simple checks that do no require conditional content, consider
 * the {@link Protected} component.
 *
 * @param permissions
 * @param children
 * @see useAuthorization
 * @see Authorized
 * @see Unauthorized
 * @see Subject
 * @see Protected
 */
export const AuthorizationCheck: FC<PropsWithChildren<ProtectedProps>> = ({
  permissions,
  children
}) => {
  const granted = useAuthorization();
  const [authorized, setAuthorized] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    granted(permissions).then(setAuthorized);
  }, [permissions, granted]);

  // Do not render anything until the authorization check is complete
  if (authorized === undefined) {
    return null;
  }

  return (
    <AccessDecisionContext.Provider value={{authorized}}>
      {children}
    </AccessDecisionContext.Provider>
  );
};
