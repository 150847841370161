import {Location} from "./Location";
import {RuntimeCollection} from "./RuntimeCollection";
import {EnvironmentResolver} from "./EnvironmentResolver";
import {RuntimeEnvironment} from "./RuntimeEnvironment";
import {Runtime} from "./Runtime";
import {RuntimeError} from "./RuntimeError";
import {RuntimeController} from "./RuntimeController";
import {DefaultRuntimeActivator} from "./DefaultRuntimeActivator";

function isLocation(arg: any): arg is Location {
  return "hostname" in arg;
}

// prettier-ignore
export class DefaultRuntimeController<M, E>
  extends DefaultRuntimeActivator<M, E>
  implements RuntimeController<M, E> {
  constructor(runtimes: RuntimeCollection<M>, environments: EnvironmentResolver<M, E>) {
    super(runtimes, environments);
  }

  public list(): Runtime<M>[] {
    return (this.runtimes as RuntimeCollection<M>).list();
  }

  public find(id: string): Runtime<M> {
    return (this.runtimes as RuntimeCollection<M>).find(id);
  }

  public environment(runtime: Runtime<M> | string): E | never {
    // eslint-disable-next-line no-param-reassign
    runtime = this.runtime(runtime);
    const environment = this.environments.resolve(runtime);
    if (!environment) {
      throw new RuntimeError(
        `No environment available for runtime ${runtime.id}`
      );
    }
    return environment;
  }

  public activate(from: Runtime<M> | string | Location): RuntimeEnvironment<M, E> | never {
    const runtime = this.runtime(from);
    const environment = this.environment(runtime);
    return this.apply({runtime, environment});
  }

  private runtime(from: Runtime<M> | string | Location): Runtime<M> | never {
    // prettier-ignore
    if (typeof from === "string") {
      const found = (this.runtimes as RuntimeCollection<M>).find(from);
      if (!found) {
        throw new RuntimeError(`No runtime available for ${location}`);
      }
      return found;
    }
    if (isLocation(from)) {
      return this.resolve(from);
    }
    return from;
  }
}
