import {FC, ReactNode, useMemo} from "react";
import {
  SimpleTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@interstate/components/SimpleTable";

export interface PropertyTableData {
  key: string;
  name: string;
  value: ReactNode;
}

export interface PropertyTableProps {
  id: string;
  className?: string;
  data: PropertyTableData[];
}

function toTableRow(data: PropertyTableData): ReactNode {
  return (
    <TableRow key={data.key}>
      <TableCell>{data.name}</TableCell>
      <TableCell>{data.value}</TableCell>
    </TableRow>
  );
}

export const PropertyTable: FC<PropertyTableProps> = ({
  id,
  className,
  data
}) => {
  const rows = useMemo<ReactNode[]>(() => data.map(toTableRow), [data]);
  return (
    <SimpleTable
      id={id}
      data-testid={id}
      background={"white"}
      dataDensity={"small"}>
      <TableHead>
        <TableRow>
          <TableCell>Property</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </SimpleTable>
  );
};
