import {FC, ReactNode} from "react";
import {useOktaAuth} from "@okta/okta-react";
import {Alert} from "@interstate/components/Alert";
import {Box} from "@interstate/components/Box";
import {Partition} from "@common-core/runtime-js/partition";
import {useRuntime} from "@common-core/react-runtime/context";
import {Metadata} from "../runtime";
import {BasicPage} from "./BasicPage";
import {Header} from "./Header";
import {Sidebar, SidebarItem} from "./Sidebar";
import {Banner as BannerBox} from "./Banner";
import {Alert as AlertBox} from "./Alert";
import {Breadcrumbs} from "./Breadcrumbs";
import {BodyFooter} from "./BodyFooter";
import "./PageTemplate.scss";

export interface PageTemplateProps {
  id: string;
  title?: string;
  header?: ReactNode;
  banner?: ReactNode;
  bodyFooter?: ReactNode;
  alert?: ReactNode;
  breadcrumbs?: ReactNode;
  sidebarItems?: SidebarItem[];
  children?: ReactNode;
}

/**
 * The {@link PageTemplate} builds upon the {@link BasicPage} by providing:
 *
 * <ul>
 *   <li>A container for page content</li>
 *   <li>A partition label when NON-PROD</li>
 *   <li>A page header area</li>
 *   <li>A sidebar area</li>
 *   <li>A banner area</li>
 *   <li>An alert area</li>
 *   <li>A breadcrumbs area</li>
 *   <li>A designated area for displaying alerts</li>
 *   <li>A container for main content</li>
 * </ul>
 *
 * This component does assume that it resides within a protected route.
 * Note that in the future sidebar navigation would be a concern
 * handled by this component.
 *
 * @param id The id of the page
 * @param title The title of the page
 * @param header Header element
 * @param banner Banner element
 * @param bodyFooter Footer element
 * @param breadcrumbs Breadcrumbs element
 * @param sidebarItems A list of items to display in the sidebar
 * @param alert An element to display alerts
 * @param children The contained components
 * @constructor
 */
export const PageTemplate: FC<PageTemplateProps> = ({
  id,
  title,
  header,
  banner,
  bodyFooter,
  alert,
  breadcrumbs,
  sidebarItems,
  children
}) => {
  const runtime = useRuntime<Metadata>();
  const {authState} = useOktaAuth();

  if (authState?.error) {
    // eslint-disable-next-line no-param-reassign
    alert = (
      <Alert
        id={"identity-error"}
        data-testid={"identity-error"}
        type={"error"}>
        {authState.error.message}
      </Alert>
    );
  }

  return (
    <BasicPage id={id} className={"coat-page-template"} title={title}>
      {header && <Header id={`${id}-header`}>{header}</Header>}
      {runtime.partition === Partition.NON_PROD && (
        <Box className="partition-label">NON-PRODUCTION</Box>
      )}
      <Box data-testid={"coat-pane"} className={"coat-pane"}>
        {sidebarItems && <Sidebar items={sidebarItems} />}
        <Box data-testid={"coat-body"} className={"coat-body"}>
          {banner && <BannerBox>{banner}</BannerBox>}
          {alert && <AlertBox>{alert}</AlertBox>}
          {breadcrumbs && (
            <Breadcrumbs id={`${id}-breadcrumbs`}>{breadcrumbs}</Breadcrumbs>
          )}
          <Box
            component={"main"}
            data-testid={"coat-content"}
            className={"coat-content"}>
            {children}
          </Box>
          {bodyFooter && <BodyFooter>{bodyFooter}</BodyFooter>}
        </Box>
      </Box>
    </BasicPage>
  );
};
