import {FC, ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {
  SimpleTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@interstate/components/SimpleTable";
import {QueryExample} from "./QueryExample";
import "./FieldTable.scss";

const translationKey = (header: string): string => {
  return `entity-search.assistance.available-fields.field-table-head.${header}`;
};

export interface FieldData {
  name: string;
  description: string;
  examples: ReactNode | ReactNode[];
}

export interface FieldTableProps {
  category: string;
  data: FieldData[];
}

export const FieldTable: FC<FieldTableProps> = ({category, data}) => {
  const {t} = useTranslation();
  return (
    <SimpleTable
      id={`${category}-field-table`}
      data-testid={`${category}-field-table`}
      className={"field-table"}
      bordered={true}
      hover={true}
      striped={true}
      background={"white"}>
      <TableHead className={"field-table-head"}>
        <TableRow>
          <TableCell>{t(translationKey("field-name"))}</TableCell>
          <TableCell>{t(translationKey("description"))}</TableCell>
          <TableCell>{t(translationKey("examples"))}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={"field-table-body"}>
        {data.map(field => (
          <TableRow key={field.name}>
            <TableCell className={"field-name"}>
              <QueryExample>{field.name}</QueryExample>
            </TableCell>
            <TableCell className={"field-description"}>
              {field.description}
            </TableCell>
            <TableCell className={"field-examples"}>{field.examples}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </SimpleTable>
  );
};
