import {BoidAggregationType, BoidGroup} from "./BoidGroup";

/**
 * The {@link NamedBoidGroup} data structure is used when mapping
 * a {@link BoidGroup} data structure into a form which has a
 * display name.
 *
 * @see {@link import {ListItem} from "@interstate/components/List"}
 */
export interface NamedBoidGroup<T = BoidAggregationType> {
  name: string;
  group: BoidGroup<T>;
}

/**
 * A sort function that sorts a {@link NamedBoidGroup} list by their name.
 *
 * @param a
 * @param b
 */
export const byName = <T>(a: NamedBoidGroup<T>, b: NamedBoidGroup<T>) =>
  a.name.localeCompare(b.name);
