import {FC, PropsWithChildren} from "react";
import {
  useDefaultOperationalSearchState,
  createOperationalSearchStateContext,
  OperationalSearchStateProviderProps
} from "../context";

/**
 * The {@link OperationalSearchStateProvider} exposes a way to store search state so
 * that the entity search state will be remembered while navigating or
 * changing views in the application.
 *
 * @constructor
 */
export const OperationalSearchStateContext =
  createOperationalSearchStateContext();

export const OperationalSearchStateProvider: FC<
  PropsWithChildren<OperationalSearchStateProviderProps>
> = ({query, table, result, children}) => {
  return (
    <OperationalSearchStateContext.Provider
      value={useDefaultOperationalSearchState(query, table, result)}>
      {children}
    </OperationalSearchStateContext.Provider>
  );
};
