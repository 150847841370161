import {FC} from "react";
import {useTranslation} from "react-i18next";
import {
  SimpleTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@interstate/components/SimpleTable";
import {TabRow} from "../../tab-content";
import "./Oems.scss";
import {Oem} from "@common-core/coat-operational-hierarchy-appsync-model";
import {Typography} from "@interstate/components/Typography";

interface OemsProps {
  id: string;
  oems: Oem[] | undefined;
}
export const Oems: FC<OemsProps> = ({id, oems}) => {
  const {t} = useTranslation();
  return (
    <TabRow
      name={"oems"}
      header={<h4>{t("entity-detail.dealer.oems.details")}</h4>}>
      {oems && oems.length > 0 ? (
        <SimpleTable
          id={`oem-table-${id}`}
          data-testid={`oem-table-${id}`}
          dataDensity={"small"}
          className={"oem-table"}
          background={"white"}>
          <TableHead>
            <TableRow>
              <TableCell>{t("entity-detail.dealer.oems.oem")}</TableCell>
              <TableCell>{t("entity-detail.dealer.oems.id")}</TableCell>
              <TableCell>{t("entity-detail.dealer.oems.location")}</TableCell>
              <TableCell>{t("entity-detail.dealer.oems.nameplate")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {oems.map(oem => (
              <TableRow
                key={`${oem.abbreviation}|${oem.id}|${oem.locationId}|${oem.nameplateDescription}`}
                className={"oem-table-row"}>
                <TableCell
                  className={"table-cell-oem table-cell-oem-abbreviation"}>
                  {oem.abbreviation}
                </TableCell>
                <TableCell className={"table-cell-oem table-cell-oem-id"}>
                  {oem.id}
                </TableCell>
                <TableCell className={"table-cell-oem table-cell-oem-location"}>
                  {oem.locationId}
                </TableCell>
                <TableCell
                  className={"table-cell-oem table-cell-oem-nameplate"}>
                  {oem.nameplateDescription}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </SimpleTable>
      ) : (
        <Typography tag={"span"} className={"no-oems"} variant={"body-sm"}>
          {t("entity-detail.dealer.oems.none")}
        </Typography>
      )}
    </TabRow>
  );
};
