import {Box} from "@interstate/components/Box";
import {CoxAutoLogo} from "../icons";
import "./CoxAutoHeader.scss";

export const CoxAutoHeader = () => {
  return (
    <Box id={"cox-auto-header"} data-testid={"cox-auto-header"}>
      <CoxAutoLogo />
    </Box>
  );
};
