import {FC, PropsWithChildren} from "react";
import {SectionProps, BodySection} from "@common-core/react-content-sections";
import "./TabBody.scss";

export const TabBody: FC<PropsWithChildren<SectionProps>> = ({
  name,
  qualifier,
  header,
  description,
  children
}) => {
  return (
    <BodySection
      type={"tab"}
      name={name}
      qualifier={qualifier}
      header={header}
      description={description}>
      {children}
    </BodySection>
  );
};
