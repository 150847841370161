import {FC, PropsWithChildren} from "react";
import {createClientHierarchySearchStateContext} from "./CreateClientHierarchySearchStateContext";
import {ClientHierarchySearchStateProviderProps} from "./ClientHierarchySearchStateProviderProps";
import {useDefaultClientHierarchySearchState} from "./DefaultClientHierarchySearchState";

/**
 * The {@link ClientHierarchySearchStateProvider} exposes a way to store search state so
 * that the entity search state will be remembered while navigating or
 * changing views in the application.
 *
 * @constructor
 */
export const ClientHierarchySearchStateContext =
  createClientHierarchySearchStateContext();

export const ClientHierarchySearchStateProvider: FC<
  PropsWithChildren<ClientHierarchySearchStateProviderProps>
> = ({query, table, result, children}) => {
  return (
    <ClientHierarchySearchStateContext.Provider
      value={useDefaultClientHierarchySearchState(query, table, result)}>
      {children}
    </ClientHierarchySearchStateContext.Provider>
  );
};
