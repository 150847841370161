import {FC} from "react";
import {useTranslation} from "react-i18next";
import {TabBody, TabColumn, TabRow} from "../../tab-content";
import {QueryExample} from "./QueryExample";
import "./QueryLanguageTabBody.scss";
import {Callout} from "./Callout";
import {QueryBlock} from "./QueryBlock";

// https://lucene.apache.org/core/2_9_4/queryparsersyntax.html
export const QueryLanguageTabBody: FC = () => {
  const {t} = useTranslation();
  return (
    <TabBody name={"query-language"}>
      <TabColumn
        name={"term-modifiers"}
        header={<h3>Term Modifiers</h3>}
        description={"Terms can be modified to provide many search options"}>
        <TabRow name={"wildcard-queries"} header={<h4>Wildcard Queries</h4>}>
          <p>
            COAT supports single and multiple character wildcard queries within
            single terms:
          </p>
          <ul>
            <li>
              <QueryExample>?</QueryExample> indicates a{" "}
              <em>single character</em> wildcard
            </li>
            <li>
              <QueryExample>*</QueryExample> indicates a{" "}
              <em>multiple character</em> wildcard
            </li>
          </ul>
          <QueryBlock name={"wildcard-examples"} header={"Examples"}>
            <QueryExample>dba:K?ng</QueryExample>
            <QueryExample>dba:Even*</QueryExample>
            <QueryExample>Happ*</QueryExample>
          </QueryBlock>
          <Callout type={"alert"}>
            <QueryExample>*</QueryExample> or <QueryExample>?</QueryExample>
            cannot be used as the first character.
          </Callout>
          <Callout type={"alert"}>
            <QueryExample>*</QueryExample> or <QueryExample>?</QueryExample>
            cannot be used in a phrase.
          </Callout>
        </TabRow>
        <TabRow name={"fuzzy-queries"} header={<h4>Fuzzy Queries</h4>}>
          <p>
            COAT supports similarity or fuzzy queries through the{" "}
            <QueryExample>~</QueryExample> character.
          </p>
          <QueryBlock name={"fuzzy-examples"} header={"Examples"}>
            <QueryExample>roam~</QueryExample>
            <QueryExample>dba:Happ~</QueryExample>
          </QueryBlock>
        </TabRow>
        <TabRow name={"proximity-queries"} header={<h4>Proximity Queries</h4>}>
          <p>
            COAT supports finding terms that are a within a specific distance
            from each other. To do a proximity search use the{" "}
            <QueryExample>~</QueryExample> character at the end of a phrase
            followed by the maximum number of words between them.
          </p>
          <QueryBlock name={"proximity-examples"} header={"Examples"}>
            <QueryExample>"happy dream"~3</QueryExample>
            <QueryExample>legal:"happy dream"~3</QueryExample>
          </QueryBlock>
        </TabRow>
        <TabRow name={"boosting"} header={<h4>Boosting a Term</h4>}>
          <p>
            COAT determines the relevancy of matching entities based on the
            terms found. To boost a term or phrase, use the{" "}
            <QueryExample>^</QueryExample>
            character followed by a boost number. The higher the boost factor,
            the more relevant the term will be.
          </p>
          <QueryBlock
            name={"boosted-queries"}
            header={"Examples"}
            description={"Boosting a specific term over a general term:"}>
            <QueryExample>group "(D)"^10</QueryExample>
          </QueryBlock>
        </TabRow>
      </TabColumn>
      <TabColumn
        name={"boolean-operators"}
        header={<h3>Boolean Operators</h3>}
        description={"Boolean operators allow terms to be combined"}>
        <Callout type={"info"}>Boolean operators are case sensitive.</Callout>
        <TabRow name={"and-operator"} header={<h4>AND</h4>}>
          <p>
            The <QueryExample>AND</QueryExample> or{" "}
            <QueryExample>&&</QueryExample> operators matches entities where
            both terms exist.
          </p>
          <QueryBlock name={"and-operator"} header={"Examples"}>
            <QueryExample>happy AND dream</QueryExample>
            <QueryExample>happy && dream</QueryExample>
          </QueryBlock>
        </TabRow>
        <TabRow name={"or-operator"} header={<h4>OR</h4>}>
          <p>
            The <QueryExample>OR</QueryExample> or{" "}
            <QueryExample>||</QueryExample> operators matches entities where
            either term exists.
          </p>
          <QueryBlock name={"or-operator"} header={"Examples"}>
            <QueryExample>happy OR dream</QueryExample>
            <QueryExample>happy || dream</QueryExample>
          </QueryBlock>
        </TabRow>
        <TabRow name={"not-operator"} header={<h4>NOT</h4>}>
          <p>
            The <QueryExample>NOT</QueryExample> or{" "}
            <QueryExample>!</QueryExample> operator excludes entities that
            contain the term.
          </p>
          <QueryBlock name={"not-operator"} header={"Examples"}>
            <QueryExample>happy NOT daze</QueryExample>
            <QueryExample>happy !daze</QueryExample>
          </QueryBlock>
        </TabRow>
        <TabRow name={"inclusion-operator"} header={<h4>Inclusion</h4>}>
          <p>
            The <QueryExample>+</QueryExample> operator requires that the term
            must exist.
          </p>
          <QueryBlock name={"inclusion-operator"} header={"Examples"}>
            <QueryExample>+happy</QueryExample>
            <QueryExample>state:tx dba:+happy</QueryExample>
          </QueryBlock>
        </TabRow>
        <TabRow name={"exclusion-operator"} header={<h4>Exclusion</h4>}>
          <p>
            The <QueryExample>-</QueryExample> operator excludes entities where
            the term exists.
          </p>
          <QueryBlock name={"exclusion-operator"} header={"Examples"}>
            <QueryExample>happy -daze</QueryExample>
            <QueryExample>dba:(happy -daze)</QueryExample>
          </QueryBlock>
        </TabRow>
      </TabColumn>
      <TabColumn
        name={"grouping"}
        header={<h3>Grouping</h3>}
        description={"Use parentheses to form sub-queries"}>
        <TabRow name={"term-grouping"} header={<h4>Term Grouping</h4>}>
          <p>
            COAT supports the use of parentheses to group clauses into
            sub-queries.
          </p>
          <QueryBlock name={"term-grouping"} header={"Examples"}>
            <QueryExample>happy AND (days OR dream)</QueryExample>
          </QueryBlock>
        </TabRow>
        <TabRow name={"field-grouping"} header={<h4>Field Grouping</h4>}>
          <p>
            COAT supports the use of parentheses to group clauses used in field
            queries.
          </p>
          <QueryBlock name={"field-grouping"} header={"Examples"}>
            <QueryExample>dba:(+happy +"camper llc"~3)</QueryExample>
          </QueryBlock>
        </TabRow>
      </TabColumn>
    </TabBody>
  );
};
