import {FC} from "react";
import "./ClientHierarchyEntityCardHeader.scss";
import {Heading} from "../../card";

export interface ClientHierarchyEntityCardHeaderProps {
  entityId: string;
  entityName: string;
  heading: Heading;
}

export const ClientHierarchyEntityCardHeader: FC<
  ClientHierarchyEntityCardHeaderProps
> = ({entityId, entityName, heading}) => {
  const Heading = heading;
  return (
    <header
      id={`client-hierarchy-entity-card-header`}
      data-testid={`client-hierarchy-entity-card-header-${entityId}`}
      className={"client-hierarchy-entity-card-header"}>
      <Heading className={"client-hierarchy-entity-card-header-heading"}>
        {entityName}
      </Heading>
    </header>
  );
};
