import {FC, PropsWithChildren} from "react";
import {LDProvider} from "launchdarkly-react-client-sdk";
import {useEnvironment} from "@common-core/react-runtime/context";
import {Environment} from "../runtime";
import {toLDContext} from "./toLDContext";
import {anonymous} from "../access";

/**
 * The FeatureProvider initializes the LDProvider context with the client-side
 * identifier and a single static anonymous user context. The default behavior
 * is for the provider to create a unique anonymous user per-device which
 * can seriously affect LD costs.
 *
 * @param children
 * @constructor
 * @see FeatureGuard
 * @see https://rally1.rallydev.com/#/?detail=/defect/793542662861&fdp=true
 */
export const FeatureProvider: FC<PropsWithChildren> = ({children}) => {
  const environment = useEnvironment<Environment>();
  return (
    <LDProvider
      clientSideID={environment.ldClientSideID}
      context={toLDContext(anonymous)}
      reactOptions={{
        useCamelCaseFlagKeys: false
      }}>
      {children}
    </LDProvider>
  );
};
