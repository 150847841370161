import {Supplier} from "./Supplier";

export class UrlBuilder {
  private relative: boolean;
  private encodeSearch?: boolean;
  private protocol?: string | null;
  private hostname?: string | null;
  private port?: number | null;
  private pathname?: string | null;
  private search: URLSearchParams;
  private hash?: string | null;

  private constructor() {
    this.relative = false;
    this.encodeSearch = true;
    this.protocol = "http";
    this.hostname = null;
    this.port = null;
    this.pathname = null;
    this.search = new URLSearchParams();
    this.hash = null;
  }

  withRelative(relative: boolean): UrlBuilder {
    this.relative = relative;
    return this;
  }

  withProtocol(protocol?: string | null): UrlBuilder {
    this.protocol = protocol;
    return this;
  }

  withSecurity(secure: boolean): UrlBuilder {
    if (secure) {
      return this.withProtocol("https");
    }
    return this.withProtocol("http");
  }

  withHostname(hostname?: string | null): UrlBuilder {
    this.hostname = hostname;
    return this;
  }

  withPort(port?: number | null): UrlBuilder {
    this.port = port;
    return this;
  }

  withPathname(path?: string | null): UrlBuilder {
    this.pathname = path;
    return this;
  }

  withSearch(search?: URLSearchParams | string | null): UrlBuilder {
    if (search != null) {
      // prettier-ignore
      this.search = typeof search === "string"
        ? new URLSearchParams(search)
        : search;
    }
    return this;
  }

  withEncodeSearch(encodeSearch: boolean) {
    this.encodeSearch = encodeSearch;
    return this;
  }

  withParameter(name: string, value: string): UrlBuilder {
    this.search.set(name, value);
    return this;
  }

  withOptionalParameter(
    include: boolean,
    name: string,
    value: string
  ): UrlBuilder {
    if (include) {
      this.search.set(name, value);
    }
    return this;
  }

  withParameters(
    source?:
      | Record<string, string>
      | Supplier<Record<string, string> | null | undefined>
  ): UrlBuilder {
    // prettier-ignore
    const parameters = typeof source === "function"
      ? source()
      : source;
    if (parameters) {
      // prettier-ignore
      Object.entries(parameters)
        .forEach(([name, value]) =>
          this.withParameter(name, value)
      );
    }
    return this;
  }

  withHash(hash?: string | null): UrlBuilder {
    this.hash = hash;
    return this;
  }

  build(): string {
    let url = "";
    if (!this.relative) {
      if (!this.protocol) {
        throw new Error("Cannot build URL without a protocol");
      }
      if (!this.hostname) {
        throw new Error("Cannot build URL without a hostname");
      }
      url = `${this.protocol}://${this.hostname}`;
      if (this.port) {
        url = `${url}:${this.port}`;
      }
    }
    if (this.pathname) {
      url = this.pathname.startsWith("/")
        ? `${url}${this.pathname}`
        : `${url}/${this.pathname}`;
    }
    let parameters = this.search.toString();
    if (!this.encodeSearch) {
      parameters = decodeURIComponent(parameters);
    }

    if (parameters) {
      url = `${url}?${parameters}`;
    }
    if (this.hash) {
      url = this.hash.startsWith("#")
        ? `${url}${this.hash}`
        : `${url}#${this.hash}`;
    }
    return url;
  }

  toUrl(): URL {
    return new URL(this.build());
  }

  static builder(secure?: boolean): UrlBuilder {
    if (secure) {
      // prettier-ignore
      return new UrlBuilder()
        .withSecurity(secure);
    }
    return new UrlBuilder();
  }
}
