import {QueryResult} from "@apollo/client";
import {BackendError} from "@common-core/runtime-js/backend";
import {ClientEntity} from "@common-core/coat-operational-hierarchy-appsync-model";
import {LookupClientEntitiesResponse} from "../../backend";

export const assertClientsPresent = (
  result: QueryResult<LookupClientEntitiesResponse>
): ClientEntity[] => {
  if (!result.data?.entities) {
    throw new BackendError(`Client Entities of "sources" not found`, 404);
  }
  return result.data.entities;
};
