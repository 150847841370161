import {ModalWithAlert, useAlert} from "../../../alerting";
import {TextInput} from "@interstate/components/TextInput";
import {useTranslation} from "react-i18next";
import {FC, useState} from "react";
import {empty, ErrorHandler, OnCompleteCallback} from "../../../utils";
import {useOperationalEntity} from "../../context";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
  EntityResponse,
  LookupOperationalEntity,
  UpdateOperationalEntityDbaName
} from "../../backend";
import {
  OperationalEntity,
  UpdateDbaNameInput,
  UpdateResponse
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {Endpoints} from "../../../runtime";
import {assertEntityPresent} from "../../boids/transforms";
import "./EditDbaNameModal.scss";

export const EditDbaNameModal: FC<{
  id: string;
  currentDbaName: string;
  onComplete: OnCompleteCallback;
}> = ({id, currentDbaName, onComplete}) => {
  const {t} = useTranslation();
  const {alert, clearAlert, createErrorAlert} = useAlert({
    errorTitleKey: "toast.backend-error"
  });
  const [inputErrorMessage, setInputErrorMessage] = useState<string>();
  const [disabledButton, setDisabledButton] = useState(false);
  const [working, setWorking] = useState(false);
  const [value, setValue] = useState(currentDbaName);
  const {entity, setEntity} = useOperationalEntity();
  const [lookUpOperationalEntity] = useLazyQuery<
    EntityResponse<OperationalEntity>
  >(LookupOperationalEntity, {
    context: {
      endpoint: Endpoints.APPSYNC
    },
    fetchPolicy: "network-only"
  });

  const [updateDbaName] = useMutation<UpdateResponse>(
    UpdateOperationalEntityDbaName,
    {
      context: {
        endpoint: Endpoints.APPSYNC
      }
    }
  );

  const clearErrors = (): void => {
    clearAlert();
    setInputErrorMessage(undefined);
  };

  const handleError: ErrorHandler = (error: any) => {
    // TODO: We need a generic mechanism to indicate validation-related errors along with a reason-code
    error.message.includes("parent id:")
      ? setInputErrorMessage(error.message)
      : createErrorAlert(error);
  };

  const refreshEntity = async (): Promise<void> => {
    return await lookUpOperationalEntity({variables: {id: entity.id}})
      .then(assertEntityPresent)
      .then(setEntity);
  };

  const saveChanges = async () => {
    setWorking(true);
    clearErrors();
    const dbaNameInput: UpdateDbaNameInput = {
      dbaName: value,
      entityId: id
    };
    await updateDbaName({
      variables: {dbaNameInput}
    })
      .then(refreshEntity)
      // If the update call returns an error, give the user the opportunity to try again
      .then(() => onComplete(true))
      .catch(handleError)
      .finally(() => setWorking(false));
  };
  const cancelChanges = () => {
    onComplete(true);
  };

  const onInputChange = (event: any) => {
    const val = event.target?.value.trim();
    setValue(val);
    if (val.length === 0) {
      const emptyMessage = t("modal.empty-text.dba-name");
      setInputErrorMessage(emptyMessage);
      setDisabledButton(true);
    } else if (disabledButton) {
      clearErrors();
      setDisabledButton(false);
    }
  };
  return (
    <ModalWithAlert
      id={"edit-dba-modal"}
      data-testid={"edit-dba-modal"}
      show={true}
      size={"large"}
      header={t("modal.header.edit-dba-name")}
      alert={alert}
      footer={{
        primary: {
          action: saveChanges,
          label: t("common-actions.save-changes"),
          isLoading: working,
          disabled: disabledButton
        },
        options: {
          action: cancelChanges,
          label: t("common-actions.cancel"),
          isLoading: working,
          buttonStyle: "tertiary"
        }
      }}>
      <TextInput
        id={"dba-text-input"}
        data-testid={"dba-text-input"}
        name={"dba"}
        label={`${t("modal.text-input.edit-dba-name")}`}
        value={value}
        onChange={onInputChange}
        onKeyDown={async (event: any) => {
          if (event.key === "Enter" && !disabledButton) {
            await saveChanges();
          }
        }}
        clearButton={!empty(value)}
        hasError={!!inputErrorMessage}
        errorMessage={inputErrorMessage}
      />
    </ModalWithAlert>
  );
};
