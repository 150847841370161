import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLazyQuery} from "@apollo/client";
import {Badge} from "@interstate/components/Badge";
import {List, ListItem} from "@interstate/components/List";
import {Endpoints} from "../../runtime";
import {useToastErrorHandler} from "../../backend";
import {TabColumn, TabRow} from "../../tab-content";
import {
  LookUpClientEntitiesByIds,
  LookupClientEntitiesResponse
} from "../backend";
import {useOperationalEntity} from "../context";
import {BoidGroup, NamedBoidGroup, byName} from "./model";
import {
  assertClientsPresent,
  collectBoidAllocationsFromClientEntities,
  groupBoidAssignmentsByType,
  mergeBoidAssignmentsIntoBoidAllocations,
  supportedBoidAssignments
} from "./transforms";
import {EditBoidAssignmentsModal} from "./EditBoidAssignmentsModal";
import "./OperationalBoidsColumn.scss";
import {PermissionBasedEditButton} from "../../action-buttons";
import {OnCompleteCallback} from "../../utils";

export const OperationalBoidsColumn: FC = () => {
  const {t} = useTranslation();
  const {entity} = useOperationalEntity();

  const [editing, setEditing] = useState<boolean>(false);
  const [currentAllocations, setCurrentAllocations] = useState<
    BoidGroup<Record<string, boolean>>[]
  >([]);

  const toastError = useToastErrorHandler();
  const [lookUpClientEntities, {loading}] =
    useLazyQuery<LookupClientEntitiesResponse>(LookUpClientEntitiesByIds, {
      context: {
        endpoint: Endpoints.APPSYNC
      },
      fetchPolicy: "network-only"
    });

  const beginEditing = () => {
    // Gather the client identifiers from which the current entity is sourced
    const ids = entity.sources.map(source => source.sourceId);
    // Collect the current boid allocations grouped by type and then display the edit modal
    lookUpClientEntities({variables: {ids}})
      .then(assertClientsPresent)
      .then(collectBoidAllocationsFromClientEntities)
      .then(mergeBoidAssignmentsIntoBoidAllocations(entity.boidAssignments))
      .then(setCurrentAllocations)
      .then(() => setEditing(true))
      .catch(toastError);
  };

  const finishEditing: OnCompleteCallback = () => {
    setCurrentAllocations([]);
    setEditing(false);
  };

  const toNamedBoidGroup = (
    group: BoidGroup<string[]>
  ): NamedBoidGroup<string[]> => {
    return {
      name: t(`entity-detail.business-operation-types.${group.type}`),
      group
    };
  };

  return (
    <TabColumn
      name={"boids"}
      header={
        <hgroup className={"boid-column-header"}>
          <h3>{t("entity-detail.business-operations-ids")}</h3>
          <PermissionBasedEditButton
            loading={loading}
            onClick={beginEditing}
            qualifier={"boid-assignments"}
            tooltip={t("entity-detail.edit-boid-assignments")}
            permissions={[
              {
                resource: "operational-entity",
                action: "edit"
              }
            ]}
          />
        </hgroup>
      }>
      {editing && (
        <EditBoidAssignmentsModal
          allocations={currentAllocations}
          onComplete={finishEditing}
        />
      )}
      <TabRow name={"boids"}>
        <List
          id={"boid-assignments-list"}
          data-testid={"boid-assignments-list"}
          className={"boid-assignments-list"}>
          {(entity.boidAssignments || [])
            .reduce(groupBoidAssignmentsByType, supportedBoidAssignments())
            .map(toNamedBoidGroup)
            .sort(byName)
            .map(({name, group: {type, boids}}) => (
              <ListItem
                key={type}
                id={`${type}-boid-assignments-list-item`}
                data-testid={`${type}-boid-assignments-list-item`}
                className={"boid-assignments-list-item"}>
                <h4 className={"business-operation-type-name"}>{name}</h4>
                {boids.length > 0 ? (
                  <ul
                    id={`${type}-boid-badge-list`}
                    data-testid={`${type}-boid-badge-list`}
                    className={"boid-badge-list"}>
                    {boids
                      .sort((a: string, b: string) => a.localeCompare(b))
                      .map(boid => (
                        <li
                          key={`${type}-${boid}`}
                          id={`${type}-${boid}-boid-badge-item`}
                          data-testid={`${type}-${boid}-boid-badge-item`}
                          className={"boid-badge-item"}>
                          <Badge variant={"success"}>{boid}</Badge>
                        </li>
                      ))}
                  </ul>
                ) : (
                  <span
                    className={"no-assignments"}
                    data-testid={`no-${type}-assignments`}>
                    {t("entity-detail.business-operation-types.no-assignments")}
                  </span>
                )}
              </ListItem>
            ))}
        </List>
      </TabRow>
    </TabColumn>
  );
};
