import {FC} from "react";
import {Modal, ModalProps} from "@interstate/components/Modal";
import {Alert} from "@interstate/components/Alert";
import {AlertDescriptor} from "./AlertDescriptor";
import "./ModalWithAlert.scss";

export type ModalWithAlertProps = ModalProps & {
  alert?: AlertDescriptor;
};

export const ModalWithAlert: FC<ModalWithAlertProps> = ({
  id,
  "data-testid": testId,
  className,
  alert,
  children,
  ...props
}) => {
  const alertId = id ? `${id}-alert` : "modal-alert";
  const alertTestId = testId ? `${testId}-alert` : "modal-alert";
  const classes = className
    ? `modal-with-alert ${className}`
    : "modal-with-alert";

  return (
    <Modal id={id} data-testid={testId} className={classes} {...props}>
      {alert && (
        <Alert
          id={alertId}
          data-testid={alertTestId}
          className={"modal-alert"}
          type={alert.type}
          title={alert.title}>
          {alert.content()}
        </Alert>
      )}
      {children}
    </Modal>
  );
};
