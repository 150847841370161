import {useEffect, useState} from "react";
import {defaultTableState, TableState} from "../../../../search/context";
import {OperationalSearchResult} from "../../../model";

export function useDefaultOperationalSearchState(
  query: string | undefined,
  table: TableState | undefined,
  result: OperationalSearchResult | undefined
) {
  const [searchQuery, setSearchQuery] = useState<string>(() => query || "");

  const [tableState, setTableState] = useState<TableState>(
    () => table || defaultTableState()
  );
  const [searchResult, setSearchResult] = useState<
    OperationalSearchResult | undefined
  >(() => result);

  // Resets the search state to the initial values
  const resetSearchState = (): void => {
    setSearchQuery(query || "");
    setTableState(table || defaultTableState());
    setSearchResult(result);
  };

  useEffect(resetSearchState, [query, table, result]);

  return {
    searchQuery,
    setSearchQuery,
    tableState,
    setTableState,
    searchResult,
    setSearchResult,
    resetSearchState
  };
}
