import {
  AnalyticsIdentity,
  AnalyticsIdentityHook
} from "@common-core/react-analytics";
import {isIdentified, useSubject} from "../access";
import {toAnalyticsUser} from "./toAnalyticsUser";

export const useAnalyticsIdentity: AnalyticsIdentityHook =
  (): AnalyticsIdentity => {
    const {subject, authState} = useSubject();
    return {
      signal: authState,
      isKnown: () => isIdentified(subject),
      toAnalyticsUser: () => toAnalyticsUser(subject)
    };
  };
