import {Runtime} from "./Runtime";
import {RuntimeError} from "./RuntimeError";
import {isTransform, RuntimeTransform} from "./RuntimeTransform";
import {EnvironmentResolver} from "./EnvironmentResolver";

// prettier-ignore
export class DefaultEnvironmentResolver<M, E>
  implements EnvironmentResolver<M, E> {
  private readonly environments: Record<string, E | RuntimeTransform<M, E>>;

  constructor(environments: Record<string, RuntimeTransform<M, E> | E>) {
    this.environments = environments;
  }

  resolve(runtime: Runtime<M>): E {
    const environment = this.environments[runtime.id];
    if (!environment) {
      throw new RuntimeError(`Environment for runtime ${runtime.id} not found`);
    }
    return isTransform<M, E>(environment) ? environment(runtime) : environment;
  }
}
