import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {TableColumn} from "@interstate/components/Table";
import {ClientHierarchyTableData} from "./ClientHierarchyTableData";
import {statesMapping} from "../../../../utils/dataMappers";

export function useClientHierarchyTableColumns(): TableColumn[] {
  const {t} = useTranslation();

  return useMemo(
    () => [
      {
        title: t("table.columns.name"),
        dataIndex: "name",
        className: "entity-name",
        columnSortable: true,
        sorter: (a: ClientHierarchyTableData, b: ClientHierarchyTableData) =>
          a.name.localeCompare(b.name)
      },
      {
        title: t("table.columns.city"),
        dataIndex: "city",
        className: "entity-city",
        columnSortable: true,
        sorter: (a: ClientHierarchyTableData, b: ClientHierarchyTableData) =>
          a.city.localeCompare(b.city)
      },
      {
        title: t("table.columns.state"),
        dataIndex: "state",
        className: "entity-state",
        columnSortable: true,
        sorter: (a: ClientHierarchyTableData, b: ClientHierarchyTableData) =>
          a.state.localeCompare(b.state),
        render: (text: string, record: ClientHierarchyTableData) =>
          statesMapping[record.state] || record.state
      },
      {
        title: t("table.columns.oems"),
        dataIndex: "nameplates",
        className: "entity-nameplates",
        columnSortable: false,
        render: (text: string, record: ClientHierarchyTableData) =>
          record.nameplates.map(nameplate => nameplate.nameplateDesc).join(", ")
      },
      {
        title: t("table.columns.solutions"),
        dataIndex: "solutions",
        className: "entity-subaccounts",
        columnSortable: false,
        render: (text: string, record: ClientHierarchyTableData) =>
          record.subaccounts
            .map(sa => sa.subAccountType)
            .filter((item, i, ar) => ar.indexOf(item) === i)
            .map(subaccountType =>
              t(
                `entity-detail.business-operation-types.${subaccountType.toUpperCase()}`
              )
            )
            .join(", ")
      }
    ],
    [t]
  );
}
