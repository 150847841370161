/* eslint-disable react-hooks/exhaustive-deps */
import {PropsWithChildren, ReactElement, useEffect} from "react";
import {RuntimeActivator, RuntimeEvent} from "@common-core/runtime-js/runtime";
import {toUrl} from "@common-core/runtime-js/support";
import {RuntimeContext} from "./RuntimeContext";

export interface RuntimeProviderProps<M, E> {
  activator: RuntimeActivator<M, E>;
}

export function RuntimeProvider<M, E>({
  activator,
  children
}: PropsWithChildren<RuntimeProviderProps<M, E>>): ReactElement<any, any> {
  if (!activator.activated()) {
    activator.activate(window.location);
  }

  useEffect(() => {
    const switchRuntime = (event: RuntimeEvent<M, E>) => {
      window.location.assign(toUrl(event.current.runtime));
    };
    activator.onActivation(switchRuntime);
    return () => {
      activator.remove(switchRuntime);
    };
  }, []);

  return (
    <RuntimeContext.Provider value={activator}>
      {children}
    </RuntimeContext.Provider>
  );
}
