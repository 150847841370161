/* eslint-disable no-console */
import {useTranslation} from "react-i18next";
import {useToaster} from "@interstate/components/Toast";
import {useInterstateToken} from "@interstate/components/InterstateThemeProvider";
import {ExclamationCircleIcon} from "@interstate/components/Icons/ExclamationCircleIcon";
import {ErrorHandler} from "../utils";

export function useToastErrorHandler(): ErrorHandler {
  const {t} = useTranslation();
  const {toast} = useToaster();
  const token = useInterstateToken();

  return error => {
    console.error(error);
    toast(
      t("toast.backend-error", "???"),
      error.message,
      undefined,
      <ExclamationCircleIcon
        height={24}
        width={24}
        color={token("sem.color.border.caution")}
      />
    );
  };
}
