import {FC} from "react";
import {useTranslation} from "react-i18next";
import {
  StatusUiProps,
  StatusIndicator,
  IconComponent
} from "@common-core/react-composable/script-injector";
import {Alert} from "@interstate/components/Alert";

export const EmbeddedComponentFailure: FC<StatusUiProps> = ({scriptId}) => {
  const {t} = useTranslation();
  return (
    <StatusIndicator scriptId={scriptId} status={"failure"}>
      <Alert id={`${scriptId}-alert`} type={"error"}>
        <p>{t("entity-tabs.embedded-components.not-available")}</p>
      </Alert>
      <IconComponent color={"rgba(221, 43, 43, 0.1)"} />
    </StatusIndicator>
  );
};
