/**
 * Returns the identical supplied value
 */
export function identity<T>(value?: T | null): T | null | undefined {
  return value;
}

/**
 * Returns the supplied value in lower case
 */
export function lowerCase(value?: string | null): string | null | undefined {
  return value?.toLowerCase() || value;
}
