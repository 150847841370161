import {ClientEntity} from "@common-core/coat-operational-hierarchy-appsync-model";
import {BoidGroup} from "../model";
import {subAccountsToBoidAllocations} from "./subAccountsToBoidAllocations";
import {supportedBoidAllocations} from "./supportedBoidAllocations";

/**
 * This mapping function produces the initial {@link BoidGroup} collection
 * using the {@link SubAccount} collection of each {@link ClientEntity} as the
 * source of BOIDs available for allocation.
 *
 * After collection of available BOIDs, it is expected that the current
 * {@link BoidGroup} collection will be merged into the collection
 * produced from this function to generate the final collection.
 *
 * @param sources
 * @see {@link mergeBoidAssignmentsIntoBoidAllocations}
 * @see {@link subAccountsToBoidAllocations}
 * @see {@link supportedBoidAllocations}
 */
export const collectBoidAllocationsFromClientEntities = (
  sources: ClientEntity[]
): BoidGroup<Record<string, boolean>>[] => {
  return sources
    .flatMap(source => source.subaccounts)
    .reduce(subAccountsToBoidAllocations, supportedBoidAllocations());
};
