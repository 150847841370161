/* eslint-disable no-console */
import {isValidElement, ReactElement} from "react";
import {HashTabProps} from "./HashTabProps";

export function isHashTabElement(arg: any): arg is ReactElement<HashTabProps> {
  // prettier-ignore
  const valid = isValidElement<HashTabProps>(arg)
        && !!arg.props.hash
        && !!arg.props.label;
  if (!valid) {
    console.warn(`Detected an invalid HashTab child: ${arg}`);
  }
  return valid;
}
