import {Partition, PartitionPredicate} from "../partition";
import {Runtime} from "../runtime/Runtime";
import {UrlBuilder} from "../util";

export interface UrlOptions {
  search?: string | URLSearchParams;
  pathname?: string | null;
  hash?: string | null;
  include?: PartitionPredicate;
}

/**
 * This function converts the supplied {@link Runtime} instance into
 * a URL.
 *
 * @param runtime
 * @param options
 */
export function toUrl(
  runtime: Runtime<any>,
  {
    pathname,
    search,
    hash,
    include = partition => partition !== Partition.PROD
  }: UrlOptions = {}
): string {
  const deployment = runtime.deployment;
  return UrlBuilder.builder(deployment.secure)
    .withHostname(deployment.hostname)
    .withPort(deployment.port)
    .withPathname(pathname)
    .withSearch(search)
    .withOptionalParameter(
      include(runtime.partition),
      "partition",
      runtime.partition
    )
    .withParameters(runtime.parameters)
    .withEncodeSearch(false)
    .withHash(hash)
    .build();
}
