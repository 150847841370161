import {FC, ReactNode, PropsWithChildren, MouseEventHandler} from "react";
import {useTranslation} from "react-i18next";
import {Card} from "@interstate/components/Card";
import {Heading} from "../../card";
import {ClientHierarchyEntityCardHeader} from "./ClientHierarchyEntityCardHeader";
import "./ClientHierarchyEntityCard.scss";

export interface ClientHierarchyEntityCardProps {
  entityId: string;
  className: string;
  entityName: string;
  heading?: Heading;
  footer?: ReactNode;
  onClick?: MouseEventHandler;
}

export const ClientHierarchyEntityCard: FC<
  PropsWithChildren<ClientHierarchyEntityCardProps>
> = ({
  entityId,
  className,
  entityName,
  heading = "h4",
  footer,
  onClick = entity => {},
  children
}) => {
  const {t} = useTranslation();
  return (
    <Card
      id={`client-hierarchy-entity-card`}
      data-testid={`client-hierarchy-entity-card-${entityId}`}
      className={`client-hierarchy-entity-card ${className}`}
      header={
        <ClientHierarchyEntityCardHeader
          entityId={entityId}
          heading={heading}
          entityName={entityName}
        />
      }
      content={
        <div className={"client-hierarchy-entity-card-body"}>{children}</div>
      }
      footer={
        footer && (
          <div className={"client-hierarchy-entity-card-footer"}>{footer}</div>
        )
      }
      primaryAction={event => onClick(event)}
    />
  );
};
