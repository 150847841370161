import {useRuntime} from "@common-core/react-runtime/context";
import {Metadata} from "../runtime";
import {Partition} from "@common-core/runtime-js/partition";
import {useStickyState} from "./useSitckyState";
import {useCallback} from "react";

const MAX_HISTORY = 12;

export interface RecentlyViewed {
  id: string;
  timestamp: number;
}
export interface BrowsedOperationalEntities {
  browsedOperationalEntities: RecentlyViewed[];
  addBrowsedOperationalEntity: OperationalRecentlyViewedFunction<void>;
}
export type OperationalRecentlyViewedFunction<R> = (entityId: string) => R;

export function useRecentlyViewedOperationalEntities(): BrowsedOperationalEntities {
  const runtime = useRuntime<Metadata>();
  const key =
    runtime.partition === Partition.PROD
      ? "browsedOperationalEntitiesProd"
      : "browsedOperationsEntitiesNonProd";

  const [browsedOperationalEntities, setBrowsedOperationalEntities] =
    useStickyState<RecentlyViewed[]>([], key);

  const addBrowsedOperationalEntity = useCallback(
    (entityId: string): void => {
      const entities = browsedOperationalEntities.filter(
        viewed => viewed.id !== entityId
      );

      entities.push({
        id: entityId,
        timestamp: Date.now()
      });
      while (entities.length > MAX_HISTORY) {
        entities.shift();
      }
      setBrowsedOperationalEntities(entities);
    },
    [browsedOperationalEntities, setBrowsedOperationalEntities]
  );

  return {
    browsedOperationalEntities,
    addBrowsedOperationalEntity
  };
}
