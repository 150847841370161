import {FC} from "react";
import {Backend} from "@common-core/runtime-js/backend";
import {Section} from "./Section";
import {PropertyTable} from "./PropertyTable";

export const BackendSection: FC<{
  backend: Backend;
}> = ({backend}) => {
  return (
    <Section className={"backend-section"}>
      <h5>Backend</h5>
      <PropertyTable
        id={"backend-table"}
        data={[
          {
            key: "baseUri",
            name: "Base URI",
            value: backend.baseUri
          }
        ]}
      />
      {backend.headers && (
        <Section className={"backend-headers-section"}>
          <h6>Backend Headers</h6>
          <PropertyTable
            id={"backend-headers-table"}
            data={Object.entries(backend.headers).map(([name, value]) => {
              return {key: name, name, value};
            })}
          />
        </Section>
      )}
    </Section>
  );
};
