import {
  ClientAddress,
  OrganizationAddress
} from "@common-core/coat-operational-hierarchy-appsync-model";

export const toOrganizationAddress = (
  address: ClientAddress
): OrganizationAddress => ({
  streetAddressLine1: address.addrLine1,
  streetAddressLine2: address.addrLine2,
  city: address.city,
  state: address.provinceCd,
  country: address.countryCd,
  zip: address.postalCd
});
