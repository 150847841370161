/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import {FC} from "react";
import {useTranslation} from "react-i18next";
import {FetchResult, useMutation} from "@apollo/client";
import {BackendError} from "@common-core/runtime-js/backend";
import {
  ClientEntity,
  OperationalEntityReference,
  OrganizationInformation
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {Endpoints} from "../../../runtime";
import {useFeedback} from "../../../feedback";
import {useNavigation, useSidebarItems} from "../../../hooks";
import {BodyFooter, PageHeading, PageTemplate} from "../../../layout";
import {useOperationalEntity} from "../../context";
import {EntityResponse, SaveOperationalEntity} from "../../backend";
import {EntitySourceType} from "../../EntitySourceType";
import {toOperationalEntityInput} from "./transforms";
import {CreateDetailsColumn} from "./CreateDetailsColumn";
import {CancelWorkflowButton} from "./CancelWorkflowButton";
import {SaveOperationalEntityButton} from "./SaveOperationalEntityButton";

export interface OperationalEntityEditorProps {
  source: ClientEntity;
}

const assertIdentifierAssigned = (
  result: FetchResult<EntityResponse<OperationalEntityReference>>
): string => {
  if (!result.data?.entity?.id) {
    throw new BackendError(`Failure saving operational entity`, 500);
  }
  return result.data.entity.id;
};

// TODO: Report Snackbar.onClose useEffect bug resulting in nasty workaround below
export const OperationalEntityEditor: FC<OperationalEntityEditorProps> = ({
  source
}) => {
  const {t} = useTranslation();
  const [feedback, setFeedback] = useFeedback();
  const {navigateToEntityFromEvent, navigateToClientSearch} = useNavigation();
  const sidebarItems = useSidebarItems();
  const {entity} = useOperationalEntity();
  const [saveOperationalEntity, {loading}] = useMutation<
    EntityResponse<OperationalEntityReference>
  >(SaveOperationalEntity, {
    fetchPolicy: "no-cache",
    context: {
      endpoint: Endpoints.APPSYNC
    }
  });

  const showErrorNotification = (error: any): void => {
    console.error(error);
    setFeedback({
      type: "error",
      message: "Failure saving operational entity.",
      autoHide: true,
      onClose: () => setFeedback(undefined)
    });
  };

  /**
   * Sets the stateful success notification to be rendered by the Snackbar.
   *
   * Note that there are some Snackbar implementation quirks that require
   * special attention below due to the fact that when a Snackbar action
   * button is clicked, it ALSO calls the onClose handler immediately after
   * invoking the onClick handler. To work around this, the first onClick
   * invocation stops event propagation and the subsequent onClose checks
   * this state to know if it is being invoked as part of a sequence vs
   * being invoked when the user clicks the X button to dismiss the
   * notification.
   *
   * @param identifier The newly assigned identifier
   */
  const showSuccessNotification = (identifier: string): void => {
    setFeedback({
      type: "success",
      autoHide: false,
      message: "Operational Entity Created",
      action: {
        label: "View Entity",
        onClick: event => {
          navigateToEntityFromEvent(
            event,
            identifier,
            EntitySourceType.OPERATIONAL
          );
          // SnackbarButtons calls action onClick, then onClose. Stop
          // propagation so that the onClose handler can check the state
          // to know if it should ignore the event
          event.preventDefault();
        }
      },
      onClose: event => {
        // Don't process the event if the action's onClick handler was already invoked
        // during the current execution path
        if (!event.defaultPrevented) {
          navigateToClientSearch(event);
        }
      }
    });
  };

  return (
    <PageTemplate
      id={"create-operational-entity-page"}
      title={"Create Operational Entity Page"}
      sidebarItems={sidebarItems}
      banner={
        <PageHeading
          title={t("client-page.banner.title")}
          summary={t("client-page.banner.summary")}
        />
      }
      bodyFooter={
        <BodyFooter>
          <CancelWorkflowButton
            disabled={!!feedback}
            onClick={event => {
              navigateToEntityFromEvent(
                event,
                source.caId,
                EntitySourceType.CLIENT_HIERARCHY
              );
            }}
          />
          <SaveOperationalEntityButton
            disabled={!!feedback}
            loading={loading}
            onClick={() => {
              saveOperationalEntity({
                variables: {
                  operationalEntity: toOperationalEntityInput(entity)
                }
              })
                .then(assertIdentifierAssigned)
                .then(showSuccessNotification)
                .catch(showErrorNotification);
            }}
          />
        </BodyFooter>
      }>
      <header className={"entity-header"} data-testid={"entity-header"}>
        <h2 className={"entity-business-name"}>{source.custName}</h2>
      </header>
      <CreateDetailsColumn
        id={entity.id}
        organizationInformation={
          entity.organizationInformation || ({} as OrganizationInformation)
        }
        oems={entity.oems}
      />
    </PageTemplate>
  );
};
