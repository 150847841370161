/* eslint-disable no-console */
import {AuthState, OktaAuthCoreInterface} from "@okta/okta-auth-js";
import {LDClient} from "launchdarkly-js-client-sdk";
import {anonymous, Subject} from "../access";
import {toLDContext} from "../features";

export async function transformAuthState(
  oktaAuth: OktaAuthCoreInterface,
  authState: AuthState,
  ldClient?: LDClient
): Promise<AuthState> {
  // Subject is unauthenticated
  if (!authState.isAuthenticated || !authState.accessToken) {
    authState.subject = anonymous;
    return authState;
  }
  authState.subject = {
    platformId: authState.accessToken.claims.sub,
    username: authState.idToken?.claims.preferred_username,
    name: authState.idToken?.claims.name,
    firstName: authState.idToken?.claims.given_name,
    lastName: authState.idToken?.claims.family_name
  } as Subject;
  await ldClient?.identify(toLDContext(authState.subject)).catch(error => {
    console.error(error);
    authState.error = new Error(
      "There was an error initializing LaunchDarkly. Some features in COAT will not be available."
    );
  });
  return authState;
}
