import {FC} from "react";
import {AuthConfiguration} from "@bridge/okta-auth";
import {CheckmarkSolidIcon} from "@interstate/components/Icons/CheckmarkSolidIcon";
import {useInterstateToken} from "@interstate/components/InterstateThemeProvider";
import {Section} from "./Section";
import {PropertyTable} from "./PropertyTable";
import {IdentityProviderBadge} from "./IdentityProviderBadge";
import {ScopeBadge} from "./ScopeBadge";

export const AuthConfigurationSection: FC<{
  configuration: AuthConfiguration;
}> = ({configuration}) => {
  const token = useInterstateToken();

  return (
    <Section className={"auth-configuration-section"}>
      <h5>Auth Configuration</h5>
      <PropertyTable
        id={"auth-configuration-table"}
        data={[
          {
            key: "idp.key",
            name: "IDP",
            value: (
              <IdentityProviderBadge
                id={`idp-${configuration.idp.key}`}
                idp={configuration.idp}
              />
            )
          },
          {
            key: "idp.issuer",
            name: "Issuer",
            value: configuration.idp.issuer
          },
          {
            key: "idp.authorizeUrl",
            name: "Authorize URL",
            value: configuration.idp.authorizeUrl
          },
          {
            key: "clientId",
            name: "Client ID",
            value: configuration.clientId
          },
          {
            key: "scopes",
            name: "Scopes",
            value: configuration.scopes.map(scope => (
              <ScopeBadge
                id={`scope-${scope}-badge`}
                scope={scope}
                key={scope}
              />
            ))
          },
          {
            key: "redirectUri",
            name: "Redirect URI",
            value: configuration.redirectUri
          },
          {
            key: "postLogoutRedirectUri",
            name: "Post Logout Redirect URI",
            value: configuration.postLogoutRedirectUri
          },
          {
            key: "renewTokensOnExpiration",
            name: "Renew Tokens on Expiration",
            value: configuration.renewTokensOnExpiration ? (
              <CheckmarkSolidIcon color={token("sem.color.border.success")} />
            ) : (
              ""
            )
          }
        ]}
      />
    </Section>
  );
};
