import {FC} from "react";
import {useTranslation} from "react-i18next";
import {
  SimpleTable,
  TableBody,
  TableRow,
  TableCell
} from "@interstate/components/SimpleTable";
import {TabRow} from "../../tab-content";
import "./Address.scss";
import {OrganizationAddress} from "@common-core/coat-operational-hierarchy-appsync-model";

interface AddressProps extends OrganizationAddress {
  id: string;
  telecomNumbers?: string[];
}

export const Address: FC<AddressProps> = ({
  id,
  streetAddressLine1,
  streetAddressLine2,
  city,
  state,
  zip,
  country,
  telecomNumbers
}) => {
  const {t} = useTranslation();
  return (
    <TabRow
      name={"address"}
      header={<h4>{t("entity-detail.dealer.address.details")}</h4>}>
      <SimpleTable
        id={`address-table-${id}`}
        data-testid={`address-table-${id}`}
        dataDensity={"small"}
        background={"white"}
        className={"address-table"}
        bordered={false}
        hover={false}
        striped={false}>
        <TableBody>
          <TableRow>
            <TableCell className={"address-field"}>
              {t("entity-detail.dealer.address.street")}
            </TableCell>
            <TableCell className={"address-value"}>
              {streetAddressLine1}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"address-field"}>
              {t("entity-detail.dealer.address.streetAddressLine2")}
            </TableCell>
            <TableCell className={"address-value"}>
              {streetAddressLine2}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"address-field"}>
              {t("entity-detail.dealer.address.city")}
            </TableCell>
            <TableCell className={"address-value"}>{city}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"address-field"}>
              {t("entity-detail.dealer.address.state")}
            </TableCell>
            <TableCell className={"address-value"}>{state}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"address-field"}>
              {t("entity-detail.dealer.address.zip-code")}
            </TableCell>
            <TableCell className={"address-value"}>{zip}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"address-field"}>
              {t("entity-detail.dealer.address.country")}
            </TableCell>
            <TableCell className={"address-value"}>{country}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"address-field"}>
              {t("entity-detail.dealer.phone")}
            </TableCell>
            <TableCell className={"address-value"} id={"address-phone-numbers"}>
              <ul className={"phone-numbers"}>
                {telecomNumbers?.map(phone => (
                  <li key={phone} className={"phone-number"}>
                    {phone}
                  </li>
                ))}
              </ul>
            </TableCell>
          </TableRow>
        </TableBody>
      </SimpleTable>
    </TabRow>
  );
};
