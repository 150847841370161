import {FC} from "react";
import {Runtime} from "@common-core/runtime-js/runtime";
import {PropertyTable} from "./PropertyTable";
import {Section} from "./Section";

export const RuntimeParametersSection: FC<{runtime: Runtime<any>}> = ({
  runtime
}) => {
  const parameters = runtime.parameters;
  if (!parameters) {
    return null;
  }
  return (
    <Section className={"runtime-parameters-section"}>
      <h4>Parameters</h4>
      <PropertyTable
        id={"runtime-parameters-table"}
        data={Object.keys(parameters).map(name => {
          return {key: name, name, value: parameters[name]};
        })}
      />
    </Section>
  );
};
