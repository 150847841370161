import {createContext, Dispatch, SetStateAction, useContext} from "react";

export type ActivityController = [boolean, Dispatch<SetStateAction<boolean>>];

export const ActivityIndicatorContext = createContext<ActivityController>([
  false,
  () => {}
]);

export const useActivityIndicator = (): ActivityController =>
  useContext(ActivityIndicatorContext);
