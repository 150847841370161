import {Children, FC, ReactElement, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Tabs} from "@interstate/components/Tabs";
import {DEFAULT_NAME, HashParameters} from "./HashParameters";
import {HashTabProps} from "./HashTabProps";
import {HashTabsEvent, HashTabsListener} from "./HashTabsListener";
import {isHashTabElement} from "./isHashTabElement";

export type HashTabsChildren =
  | ReactElement<HashTabProps>[]
  | ReactElement<HashTabProps>
  | any;

export interface HashTabsProps {
  id: string;
  name?: string;
  initial: string;
  children: HashTabsChildren;
  onTabActivated?: HashTabsListener;
}

export const HashTabs: FC<HashTabsProps> = ({
  id,
  name = DEFAULT_NAME,
  initial,
  onTabActivated = (event: HashTabsEvent) => {},
  children
}) => {
  const location = useLocation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState<string>(
    () =>
      // prettier-ignore
      HashParameters.from(location)
          .get(name)
      || HashParameters.value(initial)
  );

  // prettier-ignore
  const tabs: ReactElement<HashTabProps>[] = Children
      .toArray(children)
      .filter(isHashTabElement);

  // For deep-linking
  const onActivate = (index: number): void => {
    const tab = HashParameters.value(tabs[index].props.hash);
    const label = tabs[index].props.label;
    // prettier-ignore
    const hash = HashParameters.from(location)
        .set(name, tab)
        .hash();
    history.push({...location, hash});
    onTabActivated({
      name,
      tab,
      label
    });
    setActiveTab(tab);
  };

  useEffect(() => {
    setActiveTab(HashParameters.from(location).get(name));
  }, [location, name]);

  return (
    <Tabs
      id={id}
      data-testid={id}
      onActivate={onActivate}
      tabs={tabs.map((tab, index) => {
        const hash = HashParameters.value(tab.props.hash);
        return {
          "key": hash,
          "data-testid": `${id}-${index}`,
          "label": tab.props.label,
          "active": hash === activeTab,
          "component": tab
        };
      })}
    />
  );
};
