import {useMemo} from "react";
import {ComboBoxOption, ComboBoxOptions} from "@interstate/components/ComboBox";
import {Runtime, RuntimeTransform} from "@common-core/runtime-js/runtime";
import {useRuntimeController} from "../context";
import {RuntimeGroup} from "./RuntimeGroup";
import {RuntimeFilter} from "./RuntimeFilter";
import {toComboBoxOptions} from "./toComboBoxOptions";

export interface AvailableRuntimesOptions<M> {
  filter?: RuntimeFilter<M>;
  grouper?: RuntimeTransform<M, string>;
}

export function useAvailableRuntimes<M, E>(
  selected: Runtime<M>,
  {
    filter = () => true,
    grouper = () => "All Runtimes"
  }: AvailableRuntimesOptions<M> = {}): ComboBoxOptions {

  const controller = useRuntimeController<M, E>();
  const {runtime: current} = controller.current();
  return useMemo<ComboBoxOption[]>(
    () => {
      return controller
        .list()
        .filter(available => filter(current, available))
        .reduce((groups, member) => {
          const name = grouper(member);
          const group = groups.find(group => group.name === name);
          if (group) {
            group?.members.push(member);
          } else {
            groups.push({name, members: [member]});
          }
          return groups;
        }, [] as RuntimeGroup<M>[])
        .flatMap(toComboBoxOptions(selected));
    },
    [controller, current, filter, grouper, selected]);
}
