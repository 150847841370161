import {FC} from "react";
import {useTranslation} from "react-i18next";
import {OrganizationInformation} from "@common-core/coat-operational-hierarchy-appsync-model";
import {
  SimpleTable,
  TableBody,
  TableRow,
  TableCell
} from "@interstate/components/SimpleTable";
import {TabRow} from "../../../tab-content";

export interface BusinessInformationProps {
  id: string;
  organizationInformation: OrganizationInformation;
}

export const BusinessInformation: FC<BusinessInformationProps> = ({
  id,
  organizationInformation
}) => {
  const {t} = useTranslation();
  return (
    <TabRow
      name={"business-information"}
      header={<h4>{t("entity-detail.dealer.business-information")}</h4>}>
      <SimpleTable
        id={`business-information-table-${id}`}
        data-testid={`business-information-table-${id}`}
        dataDensity={"small"}
        background={"white"}
        className={"business-information-table"}
        bordered={false}
        hover={false}
        striped={false}>
        <TableBody>
          <TableRow>
            <TableCell className={"information-field"}>
              {t("entity-detail.dealer.legal-name")}
            </TableCell>
            <TableCell
              className={"information-value"}
              id={"dealer-information-legal-name"}>
              {organizationInformation.legalName}
            </TableCell>
          </TableRow>
        </TableBody>
      </SimpleTable>
    </TabRow>
  );
};
