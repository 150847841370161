import {FC, ReactNode, PropsWithChildren, MouseEventHandler} from "react";
import {Card} from "@interstate/components/Card";
import {EntityStatusBar} from "../../badges";

import {Heading} from "../../card";
import "../../card/EntityCard.scss";
import {OperationalEntityCardHeader} from "./OperationalEntityCardHeader";
import {OperationalEntity} from "@common-core/coat-operational-hierarchy-appsync-model";

export interface EntityCardProps {
  id: string;
  className: string;
  heading?: Heading;
  entity: OperationalEntityWithTimestamp;
  footer?: ReactNode;
  onClick?: MouseEventHandler;
}

export interface OperationalEntityWithTimestamp {
  id: string;
  timestamp: number;
  entity: OperationalEntity;
}

export const OperationalEntityCard: FC<PropsWithChildren<EntityCardProps>> = ({
  id,
  className,
  heading = "h4",
  entity: {entity},
  footer,
  onClick = entity => {},
  children
}) => {
  return (
    <Card
      id={id}
      data-testid={id}
      className={`entity-card ${className}`}
      header={
        <>
          <OperationalEntityCardHeader heading={heading} entity={entity} />
        </>
      }
      content={
        <>
          <EntityStatusBar
            id={entity.id}
            statuses={entity.statuses || []}
            compact={false}
          />
          <div className={"entity-card-body"}>{children}</div>
        </>
      }
      footer={footer && <div className={"entity-card-footer"}>{footer}</div>}
      primaryAction={event => onClick(event)}
    />
  );
};
