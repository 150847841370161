import {FC, PropsWithChildren} from "react";
import {Box} from "@interstate/components/Box";
import "./Header.scss";

export interface HeaderProps {
  id: string;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({id, children}) => {
  return (
    <Box
      component={"header"}
      id={id}
      data-testid={id}
      className={"coat-header"}>
      {children}
    </Box>
  );
};
