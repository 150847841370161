import {Runtime} from "@common-core/runtime-js/runtime";
import {AnalyticsConfiguration} from "@common-core/react-analytics";
import {currentRuntime} from "@common-core/runtime-js/support";
import {isLive, isLiveProd, Metadata} from "../runtime";

function toAnalyticsEnvironment(runtime: Runtime<Metadata>): string {
  const deployment = isLive(runtime.deployment.metadata) ? "live" : "dz";
  return `${deployment}/${runtime.partition}`;
}

export function createAnalyticsConfiguration(): AnalyticsConfiguration {
  const runtime = currentRuntime<Metadata>();
  return {
    gtmId: "GTM-KJC56KR6",
    name: "Common Operations Admin Tool",
    version: "1.0.0",
    environment: toAnalyticsEnvironment(runtime),
    isProduction: isLiveProd(runtime)
  };
}
