import {FC, PropsWithChildren} from "react";
import {Box} from "@interstate/components/Box";
import "./BodyFooter.scss";

type FooterProps = {};

export const BodyFooter: FC<PropsWithChildren<FooterProps>> = ({children}) => {
  return (
    <Box data-test-id={"coat-body-footer"} className={"coat-body-footer"}>
      {children}
    </Box>
  );
};
