import {FC} from "react";
import {LockClosedIcon} from "@interstate/components/Icons";
import {Flag} from "@interstate/components/Flag";
import {EntityStatus} from "@common-core/coat-operational-hierarchy-appsync-model";
import {EntityData} from "./EntityData";
import {Heading} from "./Heading";
import "./EntityCardHeader.scss";

export interface EntityCardHeaderProps {
  entity: EntityData;
  heading: Heading;
}

export const EntityCardHeader: FC<EntityCardHeaderProps> = ({
  entity,
  heading
}) => {
  const Heading = heading;
  return (
    <header
      id={`entity-card-header-${entity.id}`}
      data-testid={`entity-card-header-${entity.id}`}
      className={"entity-card-header"}>
      <Heading className={"entity-card-header-heading"}>
        {entity.organizationInformation?.dbaName}
      </Heading>
      {entity.statuses?.includes(EntityStatus.PROTECTED) && (
        <aside className={"entity-card-header-callout"}>
          <Flag
            id={entity.id}
            data-testid={entity.id}
            variant={"info"}
            startIcon={<LockClosedIcon />}>
            Protected
          </Flag>
        </aside>
      )}
    </header>
  );
};
