import {History} from "history";
import {FC, PropsWithChildren, useMemo} from "react";
import {OktaAuth, toRelativeUrl} from "@okta/okta-auth-js";
import {Security} from "@okta/okta-react";
import {useEnvironment} from "@common-core/react-runtime/context";
import {Environment} from "../runtime";
import {createOktaAuth} from "./createOktaAuth";
import {useLDClient} from "launchdarkly-react-client-sdk";

export declare type RestoreOriginalUriFunction = (
  oktaAuth: OktaAuth,
  originalUri: string
) => Promise<void> | void;

export interface SecurityProviderProps {
  history: History;
}

export const SecurityProvider: FC<PropsWithChildren<SecurityProviderProps>> = ({
  history,
  children
}) => {
  const environment = useEnvironment<Environment>();
  const ldClient = useLDClient();
  const oktaAuth = useMemo<OktaAuth>(
    () => {
      return createOktaAuth(environment.auth, ldClient);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [environment]
  );
  const restoreOriginalUri = useMemo<RestoreOriginalUriFunction>(
    () =>
      (oktaAuth: OktaAuth, originalUri: string): void => {
        history.replace(
          toRelativeUrl(originalUri || "/", window.location.origin)
        );
      },
    [history]
  );
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};
