import {FC, SVGProps} from "react";
import "./IconCoat.scss";

interface IconProps extends SVGProps<SVGSVGElement> {
  title?: string;
  titleId?: string;
  desc?: string;
  descId?: string;
  isActive?: boolean;
  displayCircle?: boolean;
}

export const IconCoat: FC<IconProps> = ({
  title,
  titleId,
  desc,
  descId,
  className,
  isActive = false,
  displayCircle = false,
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 36 36"
      className={`svgicon ${className ? `${className} ` : ""}icon-coat${
        isActive ? " svgicon--active" : ""
      }`}
      aria-labelledby={titleId}
      aria-describedby={descId}
      {...props}>
      {desc ? <desc id={descId}>{desc}</desc> : null}
      {title ? <title id={titleId}>{title}</title> : null}
      {displayCircle && (
        <ellipse
          className={"icon-circle"}
          cx="18.2084"
          cy="18"
          rx="17.625"
          ry="18"
          fill="#FAFAFA"
        />
      )}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9725 26.6182L24.5106 23.8705L22.1606 23.7818L22.9725 26.6182Z"
        fill="url(#paint0_linear_196_51283)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8788 23.7818H22.3209L22.8016 26.6767L21.3158 27.4909L20.8788 23.7818Z"
        fill="#C58958"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0075 26.8364L25.1516 27.7091L21.5197 27.6632L23.0075 26.8364Z"
        fill="url(#paint1_linear_196_51283)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3561 11.7818L31.1334 12.1393V14.0162L29.1176 15.4909L28.3561 11.7818Z"
        fill="url(#paint2_linear_196_51283)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4097 6.98182L26.4334 10.0021L23.2288 12.4364L25.4097 6.98182Z"
        fill="url(#paint3_linear_196_51283)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.325 14.8364L28.5697 17.0283L28.3919 20.7273L26.1246 22.6909L20.8788 14.8364H27.325Z"
        fill="url(#paint4_linear_196_51283)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3135 10.0364L28.2746 11.1058V11.5708L28.4529 11.8498L29.2106 15.7091H24.5752L23.0152 12.5937L26.3135 10.0364Z"
        fill="#D5935C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.172 12.4364L24.297 14.6182H20.8788L23.172 12.4364Z"
        fill="url(#paint5_linear_196_51283)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.597 19.3042L19.7296 15.7026L20.7904 14.8364L26.0061 22.6779L24.4149 24L19.597 19.3042Z"
        fill="#D29867"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5202 15.4909L19.7749 15.5829L19.6409 19.2625L24.5106 24L12.7606 23.862L13.5202 15.4909Z"
        fill="#BC7B48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86618 26.6182L12.8473 23.7188L13.6152 15.3426L7.47223 14.8364L5.71064 18.3801L6.16233 21.8778L9.86618 26.6182Z"
        fill="#D5935C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41973 15.0545L5.72922 18.7636H4.90564L4.42883 17.5731L7.41973 15.0545Z"
        fill="#BC7846"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.76974 26.7111L8.57948 27.7091L6.99246 24.216L7.12471 23.1273L9.76974 26.7111Z"
        fill="url(#paint6_linear_196_51283)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70155 27.6617L9.85519 26.6182L9.98337 26.6656L8.87246 27.7091L8.70155 27.6617Z"
        fill="#A37047"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0261 26.6182L11.2652 27.7091H8.91519L10.0261 26.6182Z"
        fill="url(#paint7_linear_196_51283)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_196_51283"
          x1="23.2143"
          y1="25.1017"
          x2="22.8365"
          y2="24.1964"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EEEEEE" />
          <stop offset="1" stopColor="#D5D3D2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_196_51283"
          x1="23.3356"
          y1="26.8364"
          x2="23.3356"
          y2="27.7091"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#A37047" />
          <stop offset="1" stopColor="#BE9574" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_196_51283"
          x1="28.3677"
          y1="11.7818"
          x2="28.3677"
          y2="15.4598"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BC7B48" />
          <stop offset="1" stopColor="#A37047" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_196_51283"
          x1="23.2423"
          y1="6.98182"
          x2="23.2423"
          y2="12.3906"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C58958" />
          <stop offset="1" stopColor="#BC7846" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_196_51283"
          x1="25.4531"
          y1="19.9263"
          x2="32.0126"
          y2="19.9263"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EEEEEE" />
          <stop offset="1" stopColor="#B8B4B2" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_196_51283"
          x1="20.8932"
          y1="12.4364"
          x2="20.8932"
          y2="14.5999"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BC7B48" />
          <stop offset="1" stopColor="#BC7B48" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_196_51283"
          x1="6.57139"
          y1="24.5297"
          x2="8.36607"
          y2="25.7735"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#D2CFCB" />
          <stop offset="1" stopColor="#EEEEEE" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_196_51283"
          x1="13.0728"
          y1="27.0395"
          x2="12.178"
          y2="24.624"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EEEEEE" />
          <stop offset="1" stopColor="#D8D8D8" />
        </linearGradient>
      </defs>
    </svg>
  );
};
