import {businessOperationTypes} from "@common-core/coat-operational-hierarchy-appsync-model";
import {BoidGroup} from "../model";

/**
 * This function creates an accumulator for a {@link BoidGroup} reduction function
 * using the supported business operation types to create an empty group for
 * each type.
 *
 * This accumulator guarantees that all known types will have an associated display
 * item while supporting additional types that may be discovered during the reduction
 * phase of stream processing. This allows the testing types to show up if they are
 * present in an assignment.
 *
 * @see {@link groupBoidAssignmentsByType} for the reduction function
 */
export const supportedBoidAssignments = (): BoidGroup<string[]>[] => {
  return businessOperationTypes().map(
    type =>
      ({
        type,
        boids: []
      }) satisfies BoidGroup<string[]>
  );
};
