import {FC, useState} from "react";
import {useTranslation} from "react-i18next";

import {useOperationalEntity} from "../../context";
import "../../overview/DetailsColumnHeader.scss";
import {Flag} from "@interstate/components/Flag";
import {LockClosedIcon} from "@interstate/components/Icons";
import {EntityStatus} from "@common-core/coat-operational-hierarchy-appsync-model";
import {PermissionBasedEditButton} from "../../../action-buttons";
import {OnCompleteCallback} from "../../../utils";
import {EditStatusModal} from "./EditStatusModal";
import {DealerStatus} from "../../model";

export const OperationalDetailsHeader: FC = () => {
  const {t} = useTranslation();
  const {
    entity: {
      id,
      statuses,
      organizationInformation: {status}
    }
  } = useOperationalEntity();
  const [editingStatus, setEditingStatus] = useState<boolean>(false);
  const finishStatusEditing: OnCompleteCallback = () => {
    setEditingStatus(false);
  };

  return (
    <header
      id={`operational-details-header-${id}`}
      data-testid={`operational-details-header-${id}`}
      className={"details-column-header"}>
      {editingStatus && (
        <EditStatusModal
          id={id}
          onComplete={finishStatusEditing}
          currentStatus={status ? status : DealerStatus.OUT_OF_BUSINESS}
        />
      )}
      <hgroup className={"details-column-header-group"}>
        <h3>{t("entity-detail.dealer.details")}</h3>
        {statuses?.includes(EntityStatus.PROTECTED) && (
          <aside className={"entity-card-header-callout"}>
            <Flag
              id={id}
              data-testid={id}
              variant={"info"}
              startIcon={<LockClosedIcon />}>
              Protected
            </Flag>
          </aside>
        )}
        <PermissionBasedEditButton
          onClick={() => setEditingStatus(true)}
          qualifier={"business-status"}
          tooltip={t("entity-detail.dealer.status.edit")}
          permissions={[
            {
              resource: "operational-entity",
              action: "edit"
            }
          ]}
        />
      </hgroup>
    </header>
  );
};
