import {FC} from "react";
import {Badge} from "@interstate/components/Badge";

export const ScopeBadge: FC<{
  id: string;
  scope: string;
}> = ({id, scope}) => {
  return (
    <Badge id={id} data-testid={id} className={"scope-badge"} variant={"info"}>
      {scope}
    </Badge>
  );
};
