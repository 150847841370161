import {PropsWithChildren, ReactElement} from "react";
import {Runtime} from "@common-core/runtime-js/runtime";
import {CheckmarkSolidIcon} from "@interstate/components/Icons/CheckmarkSolidIcon";
import {useInterstateToken} from "@interstate/components/InterstateThemeProvider";
import {PropertyTable} from "./PropertyTable";
import {Section} from "./Section";

export interface DeploymentSectionProps<M> {
  runtime: Runtime<M>;
}

export function DeploymentSection<M>({
  runtime,
  children
}: PropsWithChildren<DeploymentSectionProps<M>>): ReactElement<
  any,
  any
> | null {
  const deployment = runtime.deployment;
  const token = useInterstateToken();

  return (
    <Section className={"deployment-section"}>
      <h4>Deployment</h4>
      <PropertyTable
        id={"deployment-table"}
        data={[
          {key: "id", name: "Identifier", value: deployment.id},
          {
            key: "hostname",
            name: "Hostname",
            value: deployment.hostname
          },
          {
            key: "port",
            name: "Port",
            value: deployment.port
          },
          {
            key: "secure",
            name: "Secure",
            value: deployment.secure ? (
              <CheckmarkSolidIcon color={token("sem.color.border.success")} />
            ) : (
              ""
            )
          }
        ]}
      />
      {children && (
        <Section className={"deployment-metadata-section"}>
          <h5>Metadata</h5>
          {children}
        </Section>
      )}
    </Section>
  );
}
