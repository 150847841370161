import {Runtime, RuntimeEnvironment} from "../runtime";
import {RuntimeEvent} from "../runtime/RuntimeEvent";

const current = {} as RuntimeEnvironment<any, any>;

export function resetRuntimeEnvironment(): void {
  current.runtime = undefined as unknown as Runtime<any>;
  current.environment = undefined;
}

export function setRuntimeEnvironment<M, E>({
  runtime,
  environment
}: RuntimeEnvironment<M, E>): void {
  current.runtime = runtime;
  current.environment = environment;
}

export function installRuntimeEnvironment<M, E>({
  current
}: RuntimeEvent<M, E>): void {
  setRuntimeEnvironment(current);
}

export function currentRuntimeEnvironment<M, E>(): RuntimeEnvironment<M, E> {
  return <RuntimeEnvironment<M, E>>{
    ...current
  };
}

export function currentRuntime<M>(): Runtime<M> {
  return current.runtime;
}

export function currentEnvironment<E>(): E {
  return current.environment;
}
