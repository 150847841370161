import {Partition} from "@common-core/runtime-js/partition";
import {Runtime} from "@common-core/runtime-js/runtime";
import {Metadata} from "./Metadata";
import {
  dangerzone,
  dangerzoneIntegration,
  dangerzoneLegacy,
  live,
  liveIntegration,
  liveNew,
  liveNewIntegration,
  local
} from "./deployments";

export const runtimes: Runtime<Metadata>[] = [
  {
    id: "local-local/non-prod",
    deployment: local,
    partition: Partition.NON_PROD,
    parameters: {
      api: "local"
    }
  },
  {
    id: "local-local/prod",
    deployment: local,
    partition: Partition.PROD,
    parameters: {
      api: "local"
    }
  },
  {
    id: "local-dz/non-prod",
    deployment: local,
    partition: Partition.NON_PROD
  },
  {
    id: "local-dz/prod",
    deployment: local,
    partition: Partition.PROD
  },
  {
    id: "local-live/non-prod",
    deployment: local,
    partition: Partition.NON_PROD,
    parameters: {
      api: "live"
    }
  },
  {
    id: "local-dz/us-east-1/prod",
    deployment: local,
    partition: Partition.PROD,
    parameters: {
      region: "us-east-1"
    }
  },
  {
    id: "dz/non-prod",
    deployment: dangerzone,
    partition: Partition.NON_PROD
  },
  {
    id: "dz-integration/non-prod",
    deployment: dangerzoneIntegration,
    partition: Partition.NON_PROD
  },
  {
    id: "dz/prod",
    deployment: dangerzone,
    partition: Partition.PROD
  },
  {
    id: "dz-legacy/prod",
    deployment: dangerzoneLegacy,
    partition: Partition.PROD
  },
  {
    id: "dz-legacy/non-prod",
    deployment: dangerzoneLegacy,
    partition: Partition.NON_PROD
  },
  {
    id: "dz-integration/prod",
    deployment: dangerzoneIntegration,
    partition: Partition.PROD
  },
  {
    id: "dz/it/us-east-1/prod",
    deployment: dangerzone,
    partition: Partition.PROD,
    parameters: {
      region: "us-east-1",
      api: "it"
    }
  },
  {
    id: "dz/it/us-west-2/prod",
    deployment: dangerzone,
    partition: Partition.PROD,
    parameters: {
      region: "us-west-2",
      api: "it"
    }
  },
  {
    id: "live/non-prod",
    deployment: live,
    partition: Partition.NON_PROD
  },
  {
    id: "live-integration/non-prod",
    deployment: liveIntegration,
    partition: Partition.NON_PROD
  },
  {
    id: "live-new/non-prod",
    deployment: liveNew,
    partition: Partition.NON_PROD
  },
  {
    id: "live-new-integration/non-prod",
    deployment: liveNewIntegration,
    partition: Partition.NON_PROD
  },
  {
    id: "live/prod",
    deployment: live,
    partition: Partition.PROD
  },
  {
    id: "live-integration/prod",
    deployment: liveIntegration,
    partition: Partition.PROD
  },
  {
    id: "live-new/prod",
    deployment: liveNew,
    partition: Partition.PROD
  },
  {
    id: "live-new-integration/prod",
    deployment: liveNewIntegration,
    partition: Partition.PROD
  }
];
