import {FC} from "react";
import {useTranslation} from "react-i18next";
import {TabBody, TabColumn, TabRow} from "../../tab-content";
import {QueryExample} from "./QueryExample";
import {FieldTable} from "./FieldTable";
import "./AvailableFieldsTabBody.scss";

const translationKey = (relativeKey: string): string => {
  return `entity-search.assistance.available-fields.${relativeKey}`;
};

export const AvailableFieldsTabBody: FC = () => {
  const {t} = useTranslation();
  return (
    <TabBody name={"available-fields"}>
      <TabColumn name={"entity-fields"}>
        <TabRow
          name={"id-fields"}
          header={<h4>{t(translationKey("categories.id-fields"))}</h4>}>
          <FieldTable
            category={"id"}
            data={[
              {
                name: "id",
                description: "Common Org Identifier",
                examples: (
                  <QueryExample>
                    id:0c50960b-6afd-40e3-b138-dde936aff50b
                  </QueryExample>
                )
              },
              {
                name: "cacmid",
                description: "CACM Identifier",
                examples: <QueryExample>cacmid:CA11284705</QueryExample>
              },
              {
                name: "boid",
                description: "Business Operation Identifier",
                examples: [
                  <QueryExample key="boid">boid:123</QueryExample>,
                  <QueryExample key="solution-code">
                    boid:"123 mmd"
                  </QueryExample>,
                  <QueryExample key="solution-name">
                    boid:"123 Deal360"
                  </QueryExample>
                ]
              }
            ]}
          />
        </TabRow>
        <TabRow
          name={"general-fields"}
          header={<h4>{t(translationKey("categories.general-fields"))}</h4>}>
          <FieldTable
            category={"general"}
            data={[
              {
                name: "type",
                description: "Entity Type",
                examples: <QueryExample>type:Dealer</QueryExample>
              },
              {
                name: "oem",
                description: "OEM",
                examples: <QueryExample>oem:Toyota</QueryExample>
              },
              {
                name: "solution",
                description: "Solution",
                examples: [
                  <QueryExample key={"code"}>solution:dt</QueryExample>,
                  <QueryExample key={"label"}>
                    solution:Dealertrack
                  </QueryExample>
                ]
              }
            ]}
          />
        </TabRow>
        <TabRow
          name={"business-fields"}
          header={<h4>{t(translationKey("categories.business-fields"))}</h4>}>
          <FieldTable
            category={"business"}
            data={[
              {
                name: "legal",
                description: "Legal Name",
                examples: <QueryExample>legal:Happy*</QueryExample>
              },
              {
                name: "dba",
                description: "DBA Name",
                examples: <QueryExample>dba:Happy*</QueryExample>
              },
              {
                name: "phone",
                description: "Primary Phone",
                examples: <QueryExample>phone:18085642400</QueryExample>
              },
              {
                name: "owner",
                description: "Principal Owner",
                examples: <QueryExample>owner:Smith</QueryExample>
              },
              {
                name: "status",
                description: "Operational Status",
                examples: [
                  <QueryExample key={"in"}>status:In Business</QueryExample>,
                  <QueryExample key={"out"}>
                    status:Out of Business
                  </QueryExample>
                ]
              },
              {
                name: "protected",
                description: "Protected Status",
                examples: [
                  <QueryExample key={"true"}>protected:true</QueryExample>,
                  <QueryExample key={"false"}>protected:false</QueryExample>
                ]
              }
            ]}
          />
        </TabRow>
        <TabRow
          name={"address-fields"}
          header={<h4>{t(translationKey("categories.address-fields"))}</h4>}>
          <FieldTable
            category={"address"}
            data={[
              {
                name: "street",
                description: "Street",
                examples: <QueryExample>street:2850 Pukoloa St</QueryExample>
              },
              {
                name: "city",
                description: "City",
                examples: <QueryExample>city:Honolulu</QueryExample>
              },
              {
                name: "state",
                description: "State",
                examples: [
                  <QueryExample key={"code"}>state:HI</QueryExample>,
                  <QueryExample key={"label"}>state:Hawaii</QueryExample>
                ]
              },
              {
                name: "zip",
                description: "Zip Code",
                examples: <QueryExample>zip:96819</QueryExample>
              },
              {
                name: "country",
                description: "Country",
                examples: <QueryExample>country:USA</QueryExample>
              }
            ]}
          />
        </TabRow>
      </TabColumn>
    </TabBody>
  );
};
