import {FC, PropsWithChildren} from "react";
import {Box} from "@interstate/components/Box";
import "./Breadcrumbs.scss";

export interface BreadcrumbsProps {
  id: string;
}

export const Breadcrumbs: FC<PropsWithChildren<BreadcrumbsProps>> = ({
  id,
  children
}) => {
  return (
    <Box
      component={"nav"}
      id={id}
      data-testid={id}
      className={"coat-breadcrumbs"}>
      {children}
    </Box>
  );
};
