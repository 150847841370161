import {Runtime} from "./Runtime";
import {RuntimeTransform} from "./RuntimeTransform";
import {RuntimeCollection} from "./RuntimeCollection";
import {EnvironmentResolver} from "./EnvironmentResolver";
import {RuntimeController} from "./RuntimeController";
import {DefaultRuntimeController} from "./DefaultRuntimeController";
import {newEnvironmentResolver} from "./newEnvironmentResolver";
import {newRuntimeResolver, RuntimeArray} from "./newRuntimeResolver";

// prettier-ignore
export function newRuntimeController<M, E>(
  runtimes:
    | Runtime<M>
    | Runtime<M>[]
    | RuntimeArray<M>
    | RuntimeCollection<M>,
  environments:
    | RuntimeTransform<M, E>
    | EnvironmentResolver<M, E>
    | Record<string, E | RuntimeTransform<M, E>>
): RuntimeController<M, E> {
  return new DefaultRuntimeController<M, E>(
    newRuntimeResolver(runtimes) as RuntimeCollection<M>,
    newEnvironmentResolver(environments));
}
