import {Box} from "@interstate/components/Box";
import {Spinner} from "@interstate/components/Spinner";
import "./ActivityIndicator.scss";

export const ActivityIndicator = () => {
  return (
    <Box
      id={"activity-indicator"}
      data-testid={"activity-indicator"}
      component={"figure"}
      className={"activity-indicator"}>
      <Spinner
        id={"loading-indicator-with-margin"}
        data-testid={"loading-indicator-with-margin"}
      />
    </Box>
  );
};
