/* eslint-disable no-console */
import {LDSingleKindContext} from "launchdarkly-js-client-sdk";
import {currentRuntime} from "@common-core/runtime-js/support";
import {Metadata} from "../runtime";
import {isAnonymous, Subject} from "../access";

export function toLDContext(subject: Subject): LDSingleKindContext {
  const runtime = currentRuntime<Metadata>();
  return {
    kind: "user",
    anonymous: isAnonymous(subject),
    key: subject.username,
    custom: {
      userId: subject.platformId,
      username: subject.username,
      partition: runtime.partition
    }
  };
}
