import {useState, useEffect, Dispatch, SetStateAction} from "react";
import {fromJson} from "../utils";

export function clearStickyState(key?: string): void {
  if (key) {
    window.localStorage.removeItem(key);
  } else {
    window.localStorage.clear();
  }
}

// https://www.joshwcomeau.com/react/persisting-react-state-in-localstorage/
export function useStickyState<T>(
  defaultValue: T,
  key: string
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() =>
    fromJson(window.localStorage.getItem(key), defaultValue)
  );

  // watch for changes externally
  useEffect(() => {
    function updateValue() {
      setValue(fromJson(window.localStorage.getItem(key), defaultValue));
    }

    window.addEventListener("storage", updateValue);

    // cleanup function
    return () => window.removeEventListener("storage", updateValue);
  }, [key, setValue, defaultValue]);

  // Watch for changes internally
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value, null, 2));
  }, [key, value]);
  return [value, setValue];
}
