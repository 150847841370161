import {FC} from "react";
import {useTranslation} from "react-i18next";
import {useEnvironment} from "@common-core/react-runtime/context";
import BridgeBar from "@common-core/react-bridge-bar";
import {Environment} from "./runtime";
import {isIdentified, useSubject} from "./access";
import "./GlobalHeader.scss";

export const GlobalHeader: FC<{}> = () => {
  const {i18n} = useTranslation();
  const environment = useEnvironment<Environment>();
  const {subject, authState} = useSubject();
  const sessionId = authState?.accessToken?.claims.jti;

  if (isIdentified(subject) && sessionId) {
    return (
      <BridgeBar
        options={{
          env: environment.bridgeBar,
          solutionGroupCode: environment.applicationId,
          sessionId,
          language: i18n.language.slice(0, 2)
        }}
      />
    );
  }
  return null;
};
