/* eslint-disable camelcase  */
import {FC, useLayoutEffect, useState} from "react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {useRuntime} from "@common-core/react-runtime/context";
import {PendoOptions} from "@common-core/react-pendo/model";
import {PendoInitializer} from "@common-core/react-pendo/initializer";
import {isLive, Metadata} from "../runtime";
import {isIdentified, useSubject} from "../access";
import {features} from "../features";

export const PendoOptionsProvider: FC = () => {
  const {subject} = useSubject();
  const flags = useFlags();
  const {deployment, partition} = useRuntime<Metadata>();
  const [options, setOptions] = useState<PendoOptions>();

  /**
   * This effect will run before the DOM is painted which ensures the agent
   * is initialized before any user interactions can occur in the app.
   */
  useLayoutEffect(() => {
    const visitorIdentified =
      isIdentified(subject) &&
      !!flags[features.pdt.pendo.scriptInjectionEnabled];

    if (visitorIdentified) {
      const cc_environment = isLive(deployment.metadata)
        ? "live"
        : "dangerzone";
      setOptions({
        visitor: {
          id: subject.platformId,
          username: subject.username,
          full_name: subject.name,
          first_name: subject.firstName,
          last_name: subject.lastName,
          internal: true,
          cc_environment,
          cc_partition: partition.toUpperCase().replace("-", "_"),
          product: "coco_COAT"
        },
        account: {
          id: `coco_coat_${cc_environment}`
        },
        observeShadowRoots: true
      });
    }
  }, [subject, flags, deployment.metadata, partition]);

  return <PendoInitializer options={options} />;
};
