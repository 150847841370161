import {FC, MouseEventHandler} from "react";
import {Button, ButtonSize} from "@interstate/components/Button";
import {PencilSquareIcon} from "@interstate/components/Icons";
import {Tooltip} from "@interstate/components/Tooltip";
import "./EditButton.scss";

export interface EditButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  size?: ButtonSize;
  qualifier: string;
  tooltip: string;
  inline?: boolean;
}

export const EditButton: FC<EditButtonProps> = ({
  onClick,
  loading = false,
  size = "default",
  qualifier,
  tooltip,
  inline = false
}) => {
  const className = inline ? "edit-button edit-button--inline" : "edit-button";
  return (
    <Tooltip position={"top"} toolTipContent={tooltip} size={"small"}>
      <Button
        isLoading={loading}
        id={`edit-${qualifier}-button`}
        data-testid={`edit-${qualifier}-button`}
        className={className}
        icon={<PencilSquareIcon className={"edit-icon"} />}
        size={size}
        buttonStyle={"secondary"}
        onClick={onClick}
      />
    </Tooltip>
  );
};
