/**
 * In access control vocabulary, a computer process acting on behalf of a user
 * is referred to as a {@link Subject}. A {@link Subject} encapsulates the
 * following:
 * <ul>
 *     <li>The identity of a user</li>
 * </ul>
 * <p>
 */
export interface Subject {
  platformId: string;
  name: string;
  username: string;
  firstName: string;
  lastName: string;
}

export const anonymous: Subject = {
  platformId: "anonymous",
  name: "Anonymous",
  username: "Anonymous",
  firstName: "Anonymous",
  lastName: "User"
};

export const isAnonymous = (subject: Subject): boolean => {
  return subject.platformId === "anonymous";
};

export const isIdentified = (subject: Subject): boolean => {
  return subject.platformId !== "anonymous";
};
