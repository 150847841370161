import {TabBody} from "../../../tab-content";
import {HashTab} from "../../../hash-tabs";
import {ClientHierarchyHierarchyColumn} from "./ClientHierarchyHierarchyColumn";
import {useLocation} from "react-router-dom";

export const ClientHierarchyHierarchyTab: HashTab = () => {
  // Prevents hierarchy column from rendering until tab is clicked on
  const location = useLocation();
  return (
    <TabBody name={"client-hierarchy-tab"}>
      {location.hash === "#hierarchy" && <ClientHierarchyHierarchyColumn />}
    </TabBody>
  );
};
