import {FC} from "react";
import {useTranslation} from "react-i18next";
import {TabBody, TabColumn, TabRow} from "../../tab-content";
import {Action} from "@interstate/components/Action";
import {TabController} from "./SearchAssistancePanel";
import {Callout} from "./Callout";
import {QueryExample} from "./QueryExample";
import {QueryBlock} from "./QueryBlock";
import "./SearchOverviewTabBody.scss";

export interface SearchOverviewProps {
  controller: TabController;
}

export const SearchOverviewTabBody: FC<SearchOverviewProps> = ({
  controller
}) => {
  const {t} = useTranslation();
  return (
    <TabBody name={"search-overview"}>
      <TabColumn name={"search-details"} header={<h3>Vocabulary</h3>}>
        <TabRow name={"terms"} header={<h4>Terms</h4>}>
          <p>
            The text from every attribute of an entity is gathered into{" "}
            <em>terms</em> and added to the search index. Searches are performed
            against the terms in the index.
          </p>
        </TabRow>
        <TabRow name={"fields"} header={<h4>Fields</h4>}>
          <p>
            Each term in the search index is associated with the entity
            attribute, or <em>field</em>, from which it originated. Fields may
            be referenced in a query with a <em>term</em> or <em>phrase</em> as
            follows:
          </p>
          <QueryBlock name={"field-example"}>
            <QueryExample>field:term</QueryExample>
            <QueryExample>field:"a phrase"</QueryExample>
          </QueryBlock>
          <Callout type={"tip"}>
            Field queries are an effective way to improve search result quality
            and may be combined to obtain highly specific results.
          </Callout>
          <Callout type={"info"}>
            A search term or phrase entered without a field is associated with
            the <em>default field</em>. It contains the text collected from
            every attribute of an entity and will produce broader results.
          </Callout>
          <p>For more information on constructing queries, see:</p>
          <ul>
            <li>
              <Action
                id={"queries-fields-button"}
                data-testid={"queries-fields-button"}
                onClick={controller.activateAvailableFieldsTab}>
                Available Fields
              </Action>
            </li>
            <li>
              <Action
                id={"queries-language-button"}
                data-testid={"queries-language-button"}
                onClick={controller.activateQueryLanguageTab}>
                Query Language
              </Action>
            </li>
          </ul>
        </TabRow>
      </TabColumn>
    </TabBody>
  );
};
