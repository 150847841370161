import {TabBody} from "../../../tab-content";
import {HashTab} from "../../../hash-tabs";

import "./ClientHierarchyOverviewTab.scss";

import {ClientHierarchyDetailsColumn} from "./ClientHierarchyDetailsColumn";
import {ClientHierarchyBoidsColumn} from "./ClientHierarchyBoidsColumn";

export const ClientHierarchyOverviewTab: HashTab = () => {
  return (
    <TabBody name={"overview"}>
      <ClientHierarchyDetailsColumn />
      <ClientHierarchyBoidsColumn />
    </TabBody>
  );
};
