import {FC} from "react";
import {MetadataProps, PropertyTable} from "@common-core/react-runtime/ui";
import {Metadata} from "./Metadata";

export const MetadataTable: FC<MetadataProps<Metadata>> = ({metadata}) => {
  return (
    <PropertyTable
      id={"metadata-table"}
      data={[
        {
          key: "type",
          name: "type",
          value: metadata?.type
        }
      ]}
    />
  );
};
