import {FC, ReactElement} from "react";
import {Runtime} from "@common-core/runtime-js/runtime";
import {MetadataProps} from "./MetadataProps";
import {RuntimeDetailsSection} from "./RuntimeDetailsSection";
import {RuntimeParametersSection} from "./RuntimeParametersSection";
import {DeploymentSection} from "./DeploymentSection";
import {Section} from "./Section";

export interface RuntimeSectionProps<M> {
  runtime: Runtime<M>;
  metadataUi?: FC<MetadataProps<M>>;
}

export function RuntimeSection<M>({
  runtime,
  metadataUi = (props: MetadataProps<M>) => null
}: RuntimeSectionProps<M>): ReactElement<any, any> {
  return (
    <Section className={"runtime-section"}>
      <RuntimeDetailsSection runtime={runtime} />
      <RuntimeParametersSection runtime={runtime} />
      <DeploymentSection runtime={runtime}>
        {metadataUi({metadata: runtime.deployment.metadata})}
      </DeploymentSection>
    </Section>
  );
}
