import {FC, PropsWithChildren} from "react";
import {ContentSection} from "@common-core/react-content-sections";
import "./QueryBlock.scss";

export interface QueryBlockProps {
  name: string;
  header?: string;
  description?: string;
}

export const QueryBlock: FC<PropsWithChildren<QueryBlockProps>> = ({
  name,
  header,
  description,
  children
}) => {
  return (
    <ContentSection
      type={"query"}
      disposition={"block"}
      name={name}
      header={header && <h6>{header}</h6>}
      description={description}>
      {children}
    </ContentSection>
  );
};
