import {FC} from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {BackendError} from "@common-core/runtime-js/backend";
import {ClientEntity} from "@common-core/coat-operational-hierarchy-appsync-model";
import {Endpoints} from "../../../runtime";
import {ActivityIndicator} from "../../../activity-indicator";
import {ErrorPage, PageTemplate} from "../../../layout";
import {useSidebarItems} from "../../../hooks";
import {EntityResponse, LookupClientEntity} from "../../backend";
import {OperationalEntityProvider} from "../../context";
import {newOperationalEntityFrom} from "./transforms";
import {OperationalEntityEditor} from "./OperationalEntityEditor";
import "./CreateOperationalEntityPage.scss";

interface RouteParams {
  id: string;
}

/**
 * The {@link CreateOperationalEntityPage} is connected to a route that contains
 * the identifier of the source {@link ClientEntity} from which a new
 * {@link OperationalEntity} will be derived.
 */
export const CreateOperationalEntityPage: FC = () => {
  const {id} = useParams<RouteParams>();
  const sidebarItems = useSidebarItems();
  const {loading, error, data} = useQuery<EntityResponse<ClientEntity>>(
    LookupClientEntity,
    {
      variables: {id},
      fetchPolicy: "no-cache",
      context: {
        endpoint: Endpoints.APPSYNC
      }
    }
  );

  // Return the ErrorPage when an error is present
  if (error) {
    return <ErrorPage error={error} logo={false} sidebarItems={sidebarItems} />;
  }

  // Return an empty page with the ActivityIndicator while loading the entity
  if (loading) {
    return (
      <PageTemplate
        id={"create-operational-entity-page"}
        title={"Create Operational Entity Page"}
        sidebarItems={sidebarItems}>
        <ActivityIndicator />
      </PageTemplate>
    );
  }

  // Return the ErrorPage when loading is complete and the entity was not found
  if (!data?.entity) {
    return (
      <ErrorPage
        error={new BackendError("Entity not found", 404)}
        logo={false}
        sidebarItems={sidebarItems}
      />
    );
  }

  return (
    <OperationalEntityProvider entity={newOperationalEntityFrom(data.entity)}>
      <OperationalEntityEditor source={data.entity} />
    </OperationalEntityProvider>
  );
};
