export class BackendError extends Error {
  readonly status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    this.name = "BackendError";
  }

  static fromResponse(response: Response): BackendError {
    return new BackendError(response.statusText, response.status);
  }
}
