import {FC} from "react";
import {useTranslation} from "react-i18next";
import {BoidGroup, byName, NamedBoidGroup} from "../../boids/model";

import {TabColumn, TabRow} from "../../../tab-content";
import {List, ListItem} from "@interstate/components/List";
import {Badge} from "@interstate/components/Badge";
import {useClientEntity} from "../../context";
import {useToastErrorHandler} from "../../../backend";
import {BoidAssignment} from "@common-core/coat-operational-hierarchy-appsync-model";
import {
  groupBoidAssignmentsByType,
  supportedBoidAssignments
} from "../../boids/transforms";

export const ClientHierarchyBoidsColumn: FC = () => {
  const {t} = useTranslation();
  const {entity} = useClientEntity();

  const toastError = useToastErrorHandler();
  const toNamedBoidGroup = (
    group: BoidGroup<string[]>
  ): NamedBoidGroup<string[]> => {
    return {
      name: t(`entity-detail.business-operation-types.${group.type}`),
      group
    };
  };

  return (
    <TabColumn
      name={"boids"}
      header={
        <hgroup className={"entity-detail-boid-column-header"}>
          <h3>{t("entity-detail.business-operations-ids")}</h3>
        </hgroup>
      }>
      <TabRow name={"client-hierarchy-boids"}>
        <List
          id={"client-hierarchy-boid-list"}
          data-testid={"client-hierarchy-boid-list"}
          className={"client-hierarchy-boid-list"}>
          {(entity.subaccounts || [])
            .map(su => {
              return {
                businessOperationType: su.subAccountType,
                businessOperationId: su.subAccountId
              } as BoidAssignment;
            })
            .reduce(groupBoidAssignmentsByType, supportedBoidAssignments())
            .map(toNamedBoidGroup)
            .sort(byName)
            .map(({name, group: {type, boids}}) => (
              <ListItem
                key={type}
                id={`${type}-boid-assignments-list-item`}
                data-testid={`${type}-boid-assignments-list-item`}
                className={"boid-assignments-list-item"}>
                <h4 className={"business-operation-type-name"}>{name}</h4>
                {boids.length > 0 ? (
                  <ul
                    id={`${type}-boid-badge-list`}
                    data-testid={`${type}-boid-badge-list`}
                    className={"boid-badge-list"}>
                    {boids
                      .sort((a: string, b: string) => a.localeCompare(b))
                      .map(boid => (
                        <li
                          key={`${type}-${boid}`}
                          id={`${type}-${boid}-boid-badge-item`}
                          data-testid={`${type}-${boid}-boid-badge-item`}
                          className={"boid-badge-item"}>
                          <Badge variant={"success"}>{boid}</Badge>
                        </li>
                      ))}
                  </ul>
                ) : (
                  <span
                    className={"no-assignments"}
                    data-testid={`no-${type}-assignments`}>
                    {t("entity-detail.business-operation-types.no-assignments")}
                  </span>
                )}
              </ListItem>
            ))}
        </List>
      </TabRow>
    </TabColumn>
  );
};
