export interface Pagination {
  page: number;
  size: number;
}

export type SortOrder = "ascend" | "descend";

export interface Sort {
  column?: string;
  order?: SortOrder;
}

export interface TableState {
  pagination: Pagination;
  sort: Sort;
}

export function defaultTableState(
  customizer: (state: TableState) => TableState = state => state
): TableState {
  return customizer({
    pagination: {
      page: 1,
      size: 20
    },
    sort: {}
  });
}
