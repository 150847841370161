import {Context, createContext, useContext} from "react";
import {
  Location,
  Runtime,
  RuntimeError,
  RuntimeEnvironment,
  RuntimeEventListener,
  RuntimeActivator,
  RuntimeController,
  isRuntimeController
} from "@common-core/runtime-js/runtime";

export const RuntimeContext = createContext<RuntimeActivator<any, any>>({
  activate(
    from: Runtime<any> | string | Location
  ): RuntimeEnvironment<any, any> {
    return {} as RuntimeEnvironment<any, any>;
  },
  activated(): boolean {
    return false;
  },
  current(): RuntimeEnvironment<any, any> {
    return {} as RuntimeEnvironment<any, any>;
  },
  onActivation(listener: RuntimeEventListener<any, any>) {
    return this;
  },
  remove(listener: RuntimeEventListener<any, any>) {
    return this;
  }
});

export function useRuntimeActivator<M, E>(): RuntimeActivator<M, E> {
  return useContext<RuntimeActivator<M, E>>(
    RuntimeContext as unknown as Context<RuntimeActivator<M, E>>
  );
}

export function useRuntimeController<M, E>(): RuntimeController<M, E> {
  const activator = useRuntimeActivator<M, E>();
  if (isRuntimeController<M, E>(activator)) {
    return activator;
  }
  throw new RuntimeError(`RuntimeProvider not properly configured`);
}

export function useRuntimeEnvironment<M, E>(): RuntimeEnvironment<M, E> {
  const activator = useRuntimeActivator<M, E>();
  return activator.current();
}

export function useRuntime<M>(): Runtime<M> {
  const {runtime} = useRuntimeEnvironment<M, any>();
  return runtime;
}

export function useEnvironment<E>(): E {
  const {environment} = useRuntimeEnvironment<any, E>();
  return environment;
}
