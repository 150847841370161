import {FC} from "react";
import {BadgeBar} from "../../badges";
import {EntityStatus} from "@common-core/coat-operational-hierarchy-appsync-model";
import {EntityStatusBadge} from "./EntityStatusBadge";

export interface EntityStatusBarProps {
  id: string;
  statuses: EntityStatus[];
  compact?: boolean;
  tooltip?: boolean;
}

export const EntityStatusBar: FC<EntityStatusBarProps> = ({
  id,
  statuses,
  compact,
  tooltip
}) => {
  return (
    <BadgeBar id={`entity-status-bar-${id}`} className={"entity-status-bar"}>
      {statuses.map(status => (
        <EntityStatusBadge
          id={id}
          key={status}
          status={status}
          compact={compact}
          tooltip={tooltip}
        />
      ))}
    </BadgeBar>
  );
};
