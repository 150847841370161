import {FC, ReactElement} from "react";
import {Tabs} from "@interstate/components/Tabs";
import {Runtime} from "@common-core/runtime-js/runtime";
import {MetadataProps} from "./MetadataProps";
import {EnvironmentProps} from "./EnvironmentProps";
import {Section} from "./Section";
import {RuntimeSection} from "./RuntimeSection";

export interface RuntimeEnvironmentSectionProps<M, E> {
  runtime: Runtime<M>;
  metadataUi?: FC<MetadataProps<M>>;
  environment: E;
  environmentUi: FC<EnvironmentProps<E>>;
}

export function RuntimeEnvironmentSection<M, E>({
  runtime,
  metadataUi,
  environment,
  environmentUi
}: RuntimeEnvironmentSectionProps<M, E>): ReactElement<any, any> | null {
  return (
    <Section className={"runtime-environment-section"}>
      <Tabs
        id={"runtime-inspector-tabs"}
        data-testid={"runtime-inspector-tabs"}
        tabs={[
          {
            "data-testid": "runtime-tab",
            "label": "Runtime",
            "component": (
              <RuntimeSection runtime={runtime} metadataUi={metadataUi} />
            )
          },
          {
            "data-testid": "environment-tab",
            "label": "Environment",
            "component": (
              <Section className={"environment-section"}>
                <h4>Environment Details</h4>
                {environmentUi({environment})}
              </Section>
            )
          }
        ]}
      />
    </Section>
  );
}
