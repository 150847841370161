import {FC, useEffect, useMemo, useState} from "react";
import {useAnalytics} from "@common-core/react-analytics";
import {SortableDetailsType, Table} from "@interstate/components/Table";
import "./OperationalSearch.scss";
import {useOperationalSearchState} from "./context";
import {useTranslation} from "react-i18next";
import {useLazyQuery} from "@apollo/client";
import {
  OperationalSearchResponse,
  SearchOperationalEntities
} from "../../backend";
import {
  useOperationalTableColumns,
  OperationalTableData,
  toOperationalTableData
} from "./table-data/";
import {Endpoints} from "../../../runtime";
import {GetComponentProps} from "rc-table/lib/interface";
import {EntitySourceType} from "../../EntitySourceType";
import {
  hasOperationalSearchResults,
  saveTablePaginationState,
  saveTableSortState
} from "../../../search/SearchFunctions";
import {SearchBar} from "../../../search/search-bar";
import {useNavigation} from "../../../hooks";

export const OperationalSearch: FC<{query: string | undefined}> = ({query}) => {
  const {t} = useTranslation();
  const {
    searchQuery,
    setSearchQuery,
    tableState,
    setTableState,
    searchResult,
    setSearchResult,
    resetSearchState
  } = useOperationalSearchState();
  const [initialRender, setInitialRender] = useState(true);
  const [searchValue, setSearchValue] = useState(query || searchQuery);
  const [search, {loading, error, data}] =
    useLazyQuery<OperationalSearchResponse>(SearchOperationalEntities);
  const {navigateToEntityFromEvent} = useNavigation();
  const columns = useOperationalTableColumns();
  const {productEvent} = useAnalytics();

  const onSearch = (event: any, query: string): void => {
    setSearchValue(query);
    // This messes up search if written as variables:{query: {query}}
    search({
      variables: {query},
      context: {
        endpoint: Endpoints.BACKEND
      }
    });
  };

  const onSearchResultInteraction: GetComponentProps<
    OperationalTableData
  > = record => {
    return {
      onClick: (event: any) => {
        productEvent({
          name: "dealer_selected",
          properties: {
            location: `${t(
              `analytics.location.${EntitySourceType.OPERATIONAL}`
            )} Search Results`,
            value: `${record.key}`,
            result: "Dealer Details Displayed"
          }
        });
        navigateToEntityFromEvent(
          event,
          record.key,
          EntitySourceType.OPERATIONAL,
          "/search"
        );
      }
    };
  };

  /**
   * This effect monitors changes to the search result which will be
   * updated asynchronously after the search is initiated. When the
   * result is updated internally by the entity search hook, this
   * effect will run. The dependency list reflects the fact that
   * this effect only cares about changes to the result.
   */
  useEffect(() => {
    // When there are results, save to search state
    if (data) {
      setSearchQuery(searchValue);
      setSearchResult(data.searchResult);
    }
  }, [data, error]);

  /**
   * This effect monitors changes to the search value and the filters
   * and initiates a search when they change.
   */
  useEffect(() => {
    // If this is the first render and there are cached search results,
    // do not initiate a search
    try {
      const displayCachedResults =
        initialRender &&
        hasOperationalSearchResults(searchResult) &&
        searchValue === searchQuery;
      if (!displayCachedResults && searchValue.trim()) {
        // I dont know why this requires the query key and the other call doesn't
        // Could it be because its in a useEffect hook?
        search({variables: {query: searchValue}});
      }
    } finally {
      setInitialRender(false);
    }
  }, []);

  const tableData = useMemo<OperationalTableData[]>(() => {
    return searchResult?.results.map(toOperationalTableData) || [];
  }, [searchResult]);

  return (
    <div
      className="operational-search"
      data-testid={"operational-entity-search"}>
      <div className="operational-search-controls">
        <SearchBar
          id={"entity-search-box"}
          query={searchValue}
          onQueryCleared={() => {
            setSearchValue("");
            resetSearchState();
          }}
          onQueryChange={query => {
            setSearchValue(query);
          }}
          onSearch={onSearch}
          analyticsLocation={EntitySourceType.OPERATIONAL}
        />
      </div>
      <Table
        id="entity-table"
        data-testid="entity-table"
        onChangePageSize={size => {
          saveTablePaginationState(
            {
              page: tableState.pagination.page,
              size
            },
            setTableState
          );
        }}
        onChangePage={page => {
          saveTablePaginationState(
            {
              page,
              size: tableState.pagination.size
            },
            setTableState
          );
        }}
        onChangeSorter={(sorter: any) => {
          productEvent({
            name: "table_column_sorted",
            properties: {
              value: `${sorter.field}`,
              location: `${t(
                `analytics.location.${EntitySourceType.OPERATIONAL}`
              )} Search Results`,
              result: `Columns Sorted ${sorter.order}`
            }
          });
          saveTableSortState(
            {
              column: sorter.field,
              order: sorter.order
            },
            setTableState
          );
        }}
        onRowCallback={onSearchResultInteraction}
        emptyText={
          searchQuery
            ? t("operational-search.no-results") || "???"
            : t("operational-search.suggestion") || "???"
        }
        isLoading={loading}
        sortableColumns={true}
        sortableDetails={
          {
            activeSortColumn: tableState.sort.column,
            sortOrder: tableState.sort.order,
            // The list of sortable columns is dictated by which columns in the column definition have a sorter attached,
            // but this sortableColumns attribute must be supplied in order to prevent all columns from being sortable.
            sortableColumns: []
          } as SortableDetailsType
        }
        columns={columns}
        data={tableData}
        enablePagination={true}
        paginationCurrent={tableState.pagination.page}
        displayPageSizeSelector={true}
        defaultPageSize={10}
        currentPageSize={tableState.pagination.size}
        paginationPageSizeOptions={[10, 20, 50, 100]}
        pageSizePosition={"bottom"}
        bordered={true}
        borderType={"contained"}
        size={"small"}
        dataDensity={"small"}
        highlightOnHover={true}
      />
    </div>
  );
};
