import {FC, PropsWithChildren} from "react";
import "./ProfileSection.scss";

export interface ProfileSectionProps {
  id: string;
  title: string;
}

export const ProfileSection: FC<PropsWithChildren<ProfileSectionProps>> = ({
  id,
  title,
  children
}) => {
  return (
    <section id={id} data-testid={id} className={"profile-section"}>
      <h2>{title}</h2>
      {children}
    </section>
  );
};
