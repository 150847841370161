import {FC, PropsWithChildren} from "react";
import {Box} from "@interstate/components/Box";
import "./Banner.scss";

export const Banner: FC<PropsWithChildren> = ({children}) => {
  return (
    <Box data-testid={"coat-banner"} className={"coat-banner"}>
      {children}
    </Box>
  );
};
