import {FC, ReactNode} from "react";
import "./Section.scss";

export const Section: FC<{
  className: string;
  testId?: string;
  children: ReactNode;
}> = ({className, testId = className, children}) => {
  return (
    <section data-testid={testId} className={`${className} runtime-ui-section`}>
      {children}
    </section>
  );
};
