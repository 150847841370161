import {FC} from "react";
import {Box} from "@interstate/components/Box";
import {Typography} from "@interstate/components/Typography";
import "./PageHeading.scss";

export interface BasicBannerProps {
  title: string;
  summary?: string | null;
}

export const PageHeading: FC<BasicBannerProps> = ({title, summary}) => {
  return (
    <Box
      component={"hgroup"}
      data-testid={"coat-page-heading"}
      className={"coat-page-heading"}>
      <Typography
        tag={"h1"}
        variant={"h1"}
        className={"coat-page-heading-title"}
        color={"sem.color.on-surface.inverse"}>
        {title}
      </Typography>
      {summary && (
        <Typography
          tag={"p"}
          variant={"label-md"}
          className={"coat-page-heading-summary"}
          color={"sem.color.on-surface.inverse"}>
          {summary}
        </Typography>
      )}
    </Box>
  );
};
