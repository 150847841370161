import {FC} from "react";
import {useTranslation} from "react-i18next";
import {useSidebarItems} from "../hooks";
import {PageHeading, PageTemplate} from "../layout";
import {AccessSection} from "./AccessSection";
import {AnalyticsSection} from "./AnalyticsSection";
import {LaunchDarklySection} from "./LaunchDarklySection";
import "./ProfilePage.scss";

export const ProfilePage: FC = () => {
  const {t} = useTranslation();
  const sidebarItems = useSidebarItems();

  return (
    <PageTemplate
      id={"profile-page"}
      title={"Profile Page"}
      banner={
        <PageHeading
          title={t("profile-page.title")}
          summary={t("profile-page.summary", {defaultValue: null})}
        />
      }
      sidebarItems={sidebarItems}>
      <AccessSection />
      <AnalyticsSection />
      <LaunchDarklySection />
    </PageTemplate>
  );
};
