import {FC} from "react";
import "./DetailsColumnHeader.scss";

export const DetailsColumnHeader: FC<{label: string; id: string}> = ({
  label,
  id
}) => {
  return (
    <header
      id={`details-column-header-${id}`}
      data-testid={`details-column-header-${id}`}
      className={"details-column-header"}>
      <hgroup className={"details-column-header-group"}>
        <h3>{label}</h3>
      </hgroup>
    </header>
  );
};
