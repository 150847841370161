import {FC} from "react";
import {Badge} from "@interstate/components/Badge";
import {Partition} from "@common-core/runtime-js/partition";

export const PartitionBadge: FC<{id: string; partition: Partition}> = ({
  id,
  partition
}) => {
  return (
    <Badge
      id={id}
      data-testid={id}
      className={"partition-badge"}
      variant={partition === Partition.PROD ? "error" : "success"}>
      {partition}
    </Badge>
  );
};
