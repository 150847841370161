import {FC, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {BodySection, ColumnSection} from "@common-core/react-content-sections";
import {Button} from "@interstate/components/Button";
import {Tabs} from "@interstate/components/Tabs";
import {AvailableFieldsTabBody} from "./AvailableFieldsTabBody";
import {QueryLanguageTabBody} from "./QueryLanguageTabBody";
import {SearchOverviewTabBody} from "./SearchOverviewTabBody";
import "./SearchAssistancePanel.scss";

export interface TabController {
  activateOverviewTab: () => void;
  activateAvailableFieldsTab: () => void;
  activateQueryLanguageTab: () => void;
}

export interface SearchAssistancePanelProps {
  close: () => void;
}

export const SearchAssistancePanel: FC<SearchAssistancePanelProps> = ({
  close
}) => {
  const {t} = useTranslation();
  const [activeTab, setActiveTab] = useState<number>(0);
  const controller = useMemo<TabController>(() => {
    return {
      activateOverviewTab: () => setActiveTab(0),
      activateAvailableFieldsTab: () => setActiveTab(1),
      activateQueryLanguageTab: () => setActiveTab(2)
    };
  }, []);

  return (
    <BodySection type={"panel"} name={"search-assistance"}>
      <ColumnSection name={"search-assistance-topics"}>
        <Tabs
          id={"search-assistance-tabs"}
          data-testid={"search-assistance-tabs"}
          onActivate={setActiveTab}
          tabs={[
            {
              active: activeTab === 0,
              label: t("entity-search.assistance.search-overview.header"),
              component: <SearchOverviewTabBody controller={controller} />
            },
            {
              active: activeTab === 1,
              label: t("entity-search.assistance.available-fields.header"),
              component: <AvailableFieldsTabBody />
            },
            {
              active: activeTab === 2,
              label: t("entity-search.assistance.query-language.header"),
              component: <QueryLanguageTabBody />
            }
          ]}
        />
        <div className={"search-assistance-button-bar"}>
          <Button
            id={"close-search-assistance-button"}
            data-testid={"close-search-assistance-button"}
            onClick={close}>
            {t("entity-search.assistance.close")}
          </Button>
        </div>
      </ColumnSection>
    </BodySection>
  );
};
