import {FC} from "react";
import {
  EnvironmentProps,
  Section,
  PropertyTable,
  AuthConfigurationSection,
  BackendSection
} from "@common-core/react-runtime/ui";
import {Environment} from "./Environment";

export const EnvironmentTable: FC<EnvironmentProps<Environment>> = ({
  environment
}) => {
  return (
    <div>
      <AuthConfigurationSection configuration={environment.auth} />
      <BackendSection backend={environment.backend} />
      <Section className={"other-section"}>
        <h5>Other</h5>
        <PropertyTable
          id={"other-table"}
          data={[
            {
              key: "bridgeBar",
              name: "BridgeBar",
              value: environment.bridgeBar
            },
            {
              key: "ldClientSideId",
              name: "LD Client Side ID",
              value: environment.ldClientSideID
            },
            {
              key: "landingPage",
              name: "Landing Page Link",
              value: environment.landingPage
            }
          ]}
        />
      </Section>
    </div>
  );
};
