import {businessOperationTypes} from "@common-core/coat-operational-hierarchy-appsync-model";
import {BoidGroup} from "../model";

/**
 * This function creates an accumulator for a {@link SubAccount} reduction function
 * using the supported business operation types to create an empty allocation for
 * each type.
 *
 * This accumulator guarantees that all known types will have an associated display
 * item while supporting additional types that may be discovered during the reduction
 * phase of stream processing. This allows the testing types to show up if they are
 * present in an assignment.
 *
 * @see {@link subAccountsToBoidAllocations} for the reduction function
 * @see {@link mergeBoidAssignmentsIntoBoidAllocations} for merging assignments
 */
export const supportedBoidAllocations = (): BoidGroup<
  Record<string, boolean>
>[] => {
  return businessOperationTypes().map(
    type =>
      ({
        type,
        boids: {}
      }) satisfies BoidGroup<Record<string, boolean>>
  );
};
