import {FC} from "react";
import {useTranslation} from "react-i18next";
import {useSubject} from "../access";
import {toLDContext} from "../features";
import {ProfileSection} from "./ProfileSection";

export const LaunchDarklySection: FC = () => {
  const {t} = useTranslation();
  const {subject} = useSubject();

  return (
    <ProfileSection
      id={"launch-darkly-section"}
      title={t("profile-page.section.launch-darkly")}>
      <pre>{JSON.stringify(toLDContext(subject), null, 2)}</pre>
    </ProfileSection>
  );
};
