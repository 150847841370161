import {useTranslation} from "react-i18next";
import {TableColumn} from "@interstate/components/Table";
import {OperationalTableData} from "./OperationalTableData";
import {statesMapping} from "../../../../utils/dataMappers";
import {EntityStatusBar} from "../../../badges";
import {useMemo} from "react";

export function useOperationalTableColumns(): TableColumn[] {
  const {t} = useTranslation();

  return useMemo(
    () => [
      {
        title: t("table.columns.name"),
        dataIndex: "name",
        className: "entity-name",
        columnSortable: true,
        sorter: (a: OperationalTableData, b: OperationalTableData) =>
          a.name.localeCompare(b.name)
      },
      {
        title: t("table.columns.city"),
        dataIndex: "city",
        className: "entity-city",
        columnSortable: true,
        sorter: (a: OperationalTableData, b: OperationalTableData) =>
          a.city.localeCompare(b.city)
      },
      {
        title: t("table.columns.state"),
        dataIndex: "state",
        className: "entity-state",
        columnSortable: true,
        sorter: (a: OperationalTableData, b: OperationalTableData) =>
          a.state.localeCompare(b.state),
        render: (text: string, record: OperationalTableData) =>
          statesMapping[record.state] || record.state
      },
      {
        title: t("table.columns.oems"),
        dataIndex: "oems",
        className: "entity-oems",
        columnSortable: false,
        render: (text: string, record: OperationalTableData) =>
          record.oems.map(oem => oem.nameplateDescription).join(", ")
      },
      {
        title: t("table.columns.solutions"),
        dataIndex: "solutions",
        className: "entity-solutions",
        columnSortable: false,
        render: (text: string, record: OperationalTableData) =>
          (record.boidAssignments || [])
            .map(assignment => assignment.businessOperationType.toUpperCase())
            .reduce((types, type) => {
              if (!types.includes(type)) {
                types.push(type);
              }
              return types;
            }, [] as string[])
            .map(type => t(`entity-detail.business-operation-types.${type}`))
            .join(", ")
      },
      {
        title: t("table.columns.status"),
        key: "status",
        className: "entity-status",
        columnSortable: false,
        render: (text: string, record: OperationalTableData) => (
          <EntityStatusBar id={record.key} statuses={record.statuses} />
        )
      }
    ],
    [t]
  );
}
