/* eslint-disable no-console */
import {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {UrlBuilder} from "@common-core/runtime-js/util";
import {injectParameters} from "@common-core/runtime-js/support";
import {useRuntime} from "@common-core/react-runtime/context";
import {Metadata} from "../runtime";
import {EntitySourceType} from "../entities";

export type EventHandler = (event: any) => void;
export type SearchEventHandler = (event: any, query?: string) => void;
export type DomainObjectEventHandler = (
  event: any,
  id: string,
  entitySourceType?: EntitySourceType,
  currentPath?: string,
  hash?: string
) => void;
export type CreateEntityEventHandler = (
  event: any,
  id: string,
  currentPath?: string
) => void;

export interface Navigation {
  navigateToHome: EventHandler;
  navigateToSearch: SearchEventHandler;
  navigateToClientSearch: SearchEventHandler;
  navigateToEntityFromEvent: DomainObjectEventHandler;
  navigateToEntity: (id: string, entitySourceType: EntitySourceType) => void;
  navigateToCreateEntity: CreateEntityEventHandler;
  navigateToCreateOperationalEntity: CreateEntityEventHandler;
}

export function useNavigation(): Navigation {
  const history = useHistory();
  const runtime = useRuntime<Metadata>();

  const buildHomeRoute = useCallback((): string => {
    return UrlBuilder.builder()
      .withRelative(true)
      .withPathname("/")
      .withSearch(injectParameters(runtime))
      .build();
  }, [runtime]);

  const navigateToHome = useCallback<EventHandler>(
    (event: any): void => {
      const url = buildHomeRoute();
      if (event?.metaKey) {
        window.open(url, "_blank");
      } else {
        history.push(url);
      }
    },
    [history, buildHomeRoute]
  );

  const buildSearchRoute = useCallback(
    (pathName = "/search"): string => {
      return UrlBuilder.builder()
        .withRelative(true)
        .withPathname(pathName)
        .withSearch(injectParameters(runtime))
        .build();
    },
    [runtime]
  );

  const navigateToSearch = useCallback<SearchEventHandler>(
    (event: any, query?: string): void => {
      const url = buildSearchRoute();
      if (event?.metaKey) {
        window.open(url, "_blank");
      } else {
        history.push(url, {query});
      }
    },
    [history, buildSearchRoute]
  );

  const navigateToClientSearch = useCallback<SearchEventHandler>(
    (event: any, query?: string): void => {
      const url = buildSearchRoute("/client-hierarchy-search");
      if (event?.metaKey) {
        window.open(url, "_blank");
      } else {
        history.push(url, {query});
      }
    },
    [history, buildSearchRoute]
  );

  const buildEntityRoute = useCallback(
    (
      id: string,
      entitySourceType: EntitySourceType,
      hash = "overview"
    ): string => {
      // TODO: Is there a better way to provide paths?
      let pathname: string;
      switch (entitySourceType) {
        case EntitySourceType.CLIENT_HIERARCHY:
          pathname = "/client-hierarchy";
          break;
        case EntitySourceType.OLD:
        case EntitySourceType.OPERATIONAL:
        default:
          pathname = "/entity";
      }

      const url = UrlBuilder.builder()
        .withRelative(true)
        .withPathname(`${pathname}/${id}`)
        .withSearch(injectParameters(runtime))
        .withHash(hash)
        .build();
      console.log(`Navigating to ${url}`);
      return url;
    },
    [runtime]
  );

  const buildCreateRoute = useCallback(
    (pathname: string, id: string): string => {
      const url = UrlBuilder.builder()
        .withRelative(true)
        .withPathname(`${pathname}/${id}`)
        .build();
      console.log(`Navigating to ${url}`);
      return url;
    },
    [runtime]
  );

  const navigateToEntityFromEvent = useCallback<DomainObjectEventHandler>(
    (
      event: React.MouseEvent<Element, MouseEvent>,
      id: string,
      entitySourceType = EntitySourceType.OPERATIONAL,
      currentPath?: string,
      hash?: string
    ): void => {
      const url = buildEntityRoute(id, entitySourceType, hash);
      if (event?.metaKey) {
        window.open(url, "_blank");
      } else {
        history.push(url, {from: currentPath});
      }
    },
    [history, buildEntityRoute]
  );

  const navigateToEntity = useCallback<
    (id: string, entitySourceType: EntitySourceType) => void
  >(
    (id, entitySourceType = EntitySourceType.OPERATIONAL): void => {
      const url = buildEntityRoute(id, entitySourceType);
      window.open(url, "_blank");
    },
    [buildEntityRoute]
  );

  const navigateToCreateEntity = useCallback<CreateEntityEventHandler>(
    (event: any, id: string, currentPath?: string): void => {
      const url = buildCreateRoute("/create-entity", id);
      if (event?.metaKey) {
        window.open(url, "_blank");
      } else {
        history.push(url, {from: currentPath});
      }
    },
    [history, buildCreateRoute]
  );

  const navigateToCreateOperationalEntity =
    useCallback<CreateEntityEventHandler>(
      (event: any, id: string, currentPath?: string): void => {
        const url = buildCreateRoute("/create-operational-entity", id);
        if (event?.metaKey) {
          window.open(url, "_blank");
        } else {
          history.push(url, {from: currentPath});
        }
      },
      [history, buildCreateRoute]
    );

  return {
    navigateToHome,
    navigateToSearch,
    navigateToClientSearch,
    navigateToEntityFromEvent,
    navigateToEntity,
    navigateToCreateEntity,
    navigateToCreateOperationalEntity
  };
}
