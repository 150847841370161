import {FC, ReactElement} from "react";
import {useLDClient} from "launchdarkly-react-client-sdk";

export interface FeatureGuardProps {
  children: ReactElement;
}

/**
 * This component ensures that no children will be rendered until the
 * LDClient has been initialized by the LDProvider
 *
 * @param children
 * @constructor
 */
export const FeatureGuard: FC<FeatureGuardProps> = ({children}) => {
  const ldClient = useLDClient();
  return ldClient ? children : null;
};
