import {FC, PropsWithChildren} from "react";
import "./BadgeBar.scss";

export interface BadgeBarProps {
  id?: string;
  className?: string;
}

export const BadgeBar: FC<PropsWithChildren<BadgeBarProps>> = ({
  id,
  className,
  children
}) => {
  return (
    <div
      id={id}
      data-testid={id}
      className={className ? `coat-badge-bar ${className}` : "coat-badge-bar"}>
      {children}
    </div>
  );
};
