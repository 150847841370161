export enum DealerStatus {
  IN_BUSINESS = "IN BUSINESS",
  OUT_OF_BUSINESS = "OUT OF BUSINESS"
}

const values = Object.values(DealerStatus);

export function dealerStatuses(): DealerStatus[] {
  return values;
}

export function dealerStatus(alias: string): DealerStatus | null {
  // prettier-ignore
  const normalized = alias?.toUpperCase()
    .replaceAll("-", " ")
    .replaceAll("_", " ");
  return values.find(type => type.valueOf() === normalized) || null;
}

export function dealerStatusKey(type: DealerStatus): string {
  return type.toLowerCase().replaceAll(" ", "-");
}
