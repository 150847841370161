import {use} from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import {initReactI18next} from "react-i18next";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/es";
import en from "./locales/en/translations.json";

const locales: Record<string, object> = {
  en
};

// prettier-ignore
export const i18n = use(LanguageDetector)
  .use(resourcesToBackend((locale, namespace, callback) => {
    const translations = locales[locale];
    if (translations) {
      callback(null, translations);
    } else {
      callback(new Error(`${locale} is not supported`),null);
    }
  }))
  .use(initReactI18next)
  .init({
    initImmediate: false,
    defaultNS: "translations",
    fallbackLng: "en",
    lng: window.navigator.language,
    interpolation: {
      escapeValue: false, // react already safes from xss
      format(value, format, locale = "en") {
        if (value instanceof Date) {
          return moment(value)
            .locale(locale)
            .format(format);
        }
        return value;
      }
    },
    react: {
      useSuspense: false
    }
  });
