/* eslint-disable no-console */
import {FC} from "react";
import {WebComponentLoader} from "@common-core/react-composable/web-components";
import {EmbeddedComponent} from "../../runtime";
import {EmbeddedComponentFailure} from "./EmbeddedComponentFailure";
import "./EmbeddedComponentLoader.scss";

export interface EmbeddedComponentProps {
  definition: EmbeddedComponent;
  entity: string;
}

export const EmbeddedComponentLoader: FC<EmbeddedComponentProps> = ({
  definition,
  entity
}) => {
  console.info(`Rendering EmbeddedComponentLoader:${definition.element}`);
  return (
    <WebComponentLoader
      script={definition.script}
      failure={EmbeddedComponentFailure}
      element={definition.element}
      attributes={{
        ...definition.attributes,
        "common-org-id": entity
      }}
    />
  );
};
