import {FC} from "react";
import {Badge} from "@interstate/components/Badge";
import {TabRow} from "../../../tab-content";
import "./ClientHierarchyStatusBar.scss";

export interface ClientHierarchyStatusBarProps {
  id: string;
  status: string | undefined;
}

export const ClientHierarchyStatusBar: FC<ClientHierarchyStatusBarProps> = ({
  id,
  status
}) => {
  if (status) {
    const badgeId = `client-hierarchy-status-${status}-${id}`;

    return (
      <TabRow name={"client-hierarchy-status-bar"}>
        <Badge
          id={`${badgeId}-badge`}
          data-testid={badgeId}
          className={`client-hierarchy-status-badge client-hierarchy-badge-${status}`}
          variant={"info"}>
          <span className={"client-hierarchy-status-badge-label"}>
            {status}
          </span>
        </Badge>
      </TabRow>
    );
  }

  return null;
};
