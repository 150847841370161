import {FC, ReactElement, useEffect, useState} from "react";
import {TabColumn} from "../../../tab-content";
import {TreeItem, TreeView} from "@mui/x-tree-view";
import {ClientHierarchyNode} from "./ClientHierarchyNode";
import {useClientEntity} from "../../context";
import {useNavigation} from "../../../hooks";
import {useToastErrorHandler} from "../../../backend";
import {useInterstateToken} from "@interstate/components/InterstateThemeProvider";
import {
  ArrowLongRightIcon,
  ChevronDownIcon,
  ChevronRightIcon
} from "@interstate/components/Icons";
import {ClientHierarchyNodeLabel} from "./ClientHierarchyNodeLabel";
import {EntitySourceType} from "../../EntitySourceType";
import {Spinner} from "@interstate/components/Spinner";
import {useLazyQuery} from "@apollo/client";
import {
  ClientHierarchyNodeResponse,
  LookUpClientHierarchyByUltimateParent
} from "../backend";
import {Endpoints} from "../../../runtime";
import {BackendError} from "@common-core/runtime-js/backend";
import {collectClientSelectionPath} from "./collectClientSelectionPath";
import "./ClientHierarchyHierarchyColumn.scss";

export const ClientHierarchyHierarchyColumn: FC = () => {
  const toastError = useToastErrorHandler();
  const token = useInterstateToken();
  const [expanded, setExpanded] = useState<string[]>(() => []);
  const [root, setRoot] = useState<ClientHierarchyNode>();
  const {
    entity: {caId, custUltimateParentId}
  } = useClientEntity();
  const {navigateToEntity} = useNavigation();

  const [lookupRoot] = useLazyQuery<ClientHierarchyNodeResponse>(
    LookUpClientHierarchyByUltimateParent,
    {fetchPolicy: "no-cache"}
  );

  const renderTree = (
    clientHierarchyNode: ClientHierarchyNode
  ): ReactElement => {
    const selected = clientHierarchyNode.caId === caId;
    const children = clientHierarchyNode.children
      ? [...clientHierarchyNode.children]
      : [];
    return (
      <TreeItem
        key={clientHierarchyNode.caId}
        nodeId={clientHierarchyNode.caId}
        data-testid={`client-hierarchy-node-${clientHierarchyNode.caId}`}
        className={`client-hierarchy-node${selected ? " client-hierarchy-node--selected" : ""}`}
        icon={
          selected ? (
            <ArrowLongRightIcon color={token("sem.color.on-surface.primary")} />
          ) : undefined
        }
        label={
          <ClientHierarchyNodeLabel
            clientHierarchyNode={clientHierarchyNode}
            onClick={e => {
              navigateToEntity(
                clientHierarchyNode.caId,
                EntitySourceType.CLIENT_HIERARCHY
              );
            }}
          />
        }>
        {children
          ?.sort((a, b) => {
            if (expanded.includes(a.caId)) {
              return -1;
            }
            if (expanded.includes(b.caId)) {
              return 1;
            }
            return a.custName.localeCompare(b.custName);
          })
          .map(child => renderTree(child))}
      </TreeItem>
    );
  };
  useEffect(() => {
    const rootId = custUltimateParentId ? custUltimateParentId : caId;
    lookupRoot({
      variables: {id: rootId},
      context: {
        endpoint: Endpoints.APPSYNC
      }
    })
      .then(result => {
        if (!result.data?.hierarchyNode) {
          throw new BackendError(
            `Root hierarchy node of entity ${rootId} not found`,
            404
          );
        }
        return result.data.hierarchyNode;
      })
      .then(root => {
        const expanded: string[] = [];
        if (collectClientSelectionPath(root, caId, expanded)) {
          setExpanded(expanded);
          setRoot(root);
        }
      })
      .catch(toastError);
  }, []);

  if (root === undefined || expanded.length === 0) {
    return (
      <TabColumn name={"client-hierarchy-column"}>
        <Spinner />
      </TabColumn>
    );
  }

  return (
    <TabColumn name={"client-hierarchy-column"}>
      <TreeView
        id={`client-hierarchy-tree-view-${root?.caId}`}
        data-testid={`client-hierarchy-tree-view-${root?.caId}`}
        className={"client-hierarchy-tree-view"}
        defaultCollapseIcon={<ChevronDownIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={expanded}
        defaultSelected={caId}>
        {renderTree(root)}
      </TreeView>
    </TabColumn>
  );
};
