import {FC, PropsWithChildren, useState} from "react";
import {
  AnalyticsProvider,
  GoogleTagManager
} from "@common-core/react-analytics";
import {createAnalyticsConfiguration} from "./createAnalyticsConfiguration";
import {useAnalyticsIdentity} from "./useAnalyticsIdentity";

/**
 * The {@link AnalyticsFacade} component is responsible for configuring the
 * {@link AnalyticsProvider}.
 *
 * Note that Analytics depends upon the following contexts to have been
 * established within the application:
 * <ul>
 *   <li>Runtime</li>
 *   <li>Security</li>
 * </ul>
 *
 * @param children
 * @constructor
 * @see Application
 * @see createAnalyticsConfiguration
 * @see useAnalyticsIdentity
 */
export const AnalyticsFacade: FC<PropsWithChildren> = ({children}) => {
  const [gtm] = useState<GoogleTagManager>(() => {
    const gtm = new GoogleTagManager();
    gtm.initialize(createAnalyticsConfiguration());
    return gtm;
  });
  return (
    <AnalyticsProvider service={gtm} useIdentity={useAnalyticsIdentity}>
      {children}
    </AnalyticsProvider>
  );
};
