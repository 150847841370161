import {FC} from "react";
import {TabRow} from "../../tab-content";
import {EntityStatusBar} from "../badges";
import {EntityStatus} from "@common-core/coat-operational-hierarchy-appsync-model";
interface DealerStatusBarProps {
  id: string;
  statuses: EntityStatus[];
}
export const DealerStatusBar: FC<DealerStatusBarProps> = ({id, statuses}) => {
  return (
    <TabRow name={"dealer-status-bar"}>
      <EntityStatusBar
        id={id}
        statuses={statuses.filter(status => status !== EntityStatus.PROTECTED)}
        compact={false}
      />
    </TabRow>
  );
};
