import {FC} from "react";
import {useTranslation} from "react-i18next";
import {
  SimpleTable,
  TableBody,
  TableCell,
  TableRow
} from "@interstate/components/SimpleTable";

import "./ClientHierarchyBusinessInformation.scss";
import {TabRow} from "../../../tab-content";
import {useAnalytics} from "@common-core/react-analytics";
import {ActionButtons, CopyButton} from "../../../action-buttons";
import {useNavigation} from "../../../hooks";
import {Popover} from "@interstate/components/Popover";
import {Button} from "@interstate/components/Button";
import {EntitySourceType} from "../../EntitySourceType";
import {useHistory} from "react-router-dom";
import {ClientHierarchyEntityCard} from "../card";

export interface ClientBusinessInformationProps {
  caId: string;
  custLegalName?: string;
  custParentId?: string;
}

export const ClientHierarchyBusinessInformation: FC<
  ClientBusinessInformationProps
> = ({caId, custLegalName, custParentId}) => {
  const {t} = useTranslation();
  const {productEvent} = useAnalytics();
  const {navigateToEntityFromEvent} = useNavigation();
  const history = useHistory();
  return (
    <TabRow
      name={"business-information"}
      header={<h4>{t("entity-detail.dealer.business-information")}</h4>}>
      <SimpleTable
        id={`business-information-table-${caId}`}
        data-testid={`business-information-table-${caId}`}
        dataDensity={"small"}
        background={"white"}
        className={"business-information-table"}
        bordered={false}
        hover={false}
        striped={false}>
        <TableBody>
          <TableRow>
            <TableCell className={"information-field"}>
              {t("entity-detail.dealer.legal-name")}
            </TableCell>
            <TableCell
              className={"information-value"}
              id={"dealer-information-legal-name"}>
              {custLegalName}
            </TableCell>
            <TableCell className={"information-actions"} />
          </TableRow>
          <TableRow>
            <TableCell className={"information-field"}>
              {t("entity-detail.cacm-id")}
            </TableCell>
            <TableCell className={"information-value"} id={"cacm-id"}>
              {caId}
            </TableCell>
            <TableCell className={"information-actions"} />
          </TableRow>
          <TableRow>
            <TableCell className={"information-field"}>
              {t("entity-detail.parent-id")}
            </TableCell>
            <TableCell
              className={"information-value"}
              id={"client-hierarchy-entity-information-parent-id"}
              data-testid={"client-hierarchy-entity-information-parent-id"}>
              {custParentId ? (
                <Popover
                  id={"client-hierarchy-entity-parent-popover"}
                  data-testid={"client-hierarchy-entity-parent-popover"}
                  trigger={"outsideClick"}
                  position={"bottom"}
                  popoverContent={
                    <ClientHierarchyEntityCard
                      entityId={custParentId}
                      className={"popover-entity-card"}
                      heading={"h4"}
                      footer={
                        <Button
                          id={`client-hierarchy-entity-card-button-${custParentId}`}
                          className={`entity-card-button-parent`}
                          buttonStyle={"tertiary"}
                          onClick={e => {
                            e.preventDefault();
                            // Make TS inspector happy
                            if (custParentId) {
                              navigateToEntityFromEvent(
                                e,
                                custParentId,
                                EntitySourceType.CLIENT_HIERARCHY
                              );
                            }
                          }}>
                          {t("entity-detail.view-parent")}
                        </Button>
                      }
                      // TODO: Update what's passed in here, once we have the parent name available in the graphql query.
                      entityName={custParentId}
                    />
                  }>
                  <span
                    className={"entity-parent"}
                    onClick={() => {
                      productEvent({
                        name: "parent_clicked",
                        properties: {
                          location: "Client Hierarchy Details Overview",
                          value: `${custParentId}`,
                          result: "Parent Displayed"
                        }
                      });
                    }}>
                    {custParentId}
                  </span>
                </Popover>
              ) : (
                t("entity-detail.no-parent")
              )}
            </TableCell>
            <TableCell className={"information-actions"}>
              <ActionButtons>
                {custParentId && (
                  <CopyButton
                    content={custParentId}
                    qualifier={"parent-id"}
                    item={t("entity-detail.parent-id")}
                    inline={true}
                  />
                )}
              </ActionButtons>
            </TableCell>
          </TableRow>
        </TableBody>
      </SimpleTable>
    </TabRow>
  );
};
