import {
  Nameplate,
  Oem
} from "@common-core/coat-operational-hierarchy-appsync-model";

export const toOem = (nameplate: Nameplate): Oem => ({
  id: nameplate.oemId,
  abbreviation: nameplate.oemAbbreviation,
  locationId: nameplate.oemLocationId,
  nameplateDescription: nameplate.nameplateDesc
});
