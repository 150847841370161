import {FC, PropsWithChildren} from "react";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  StarIcon
} from "@interstate/components/Icons";
import {IconType} from "../../lib/interstate";
import "./Callout.scss";

function selectIcon(type: CalloutType): IconType {
  switch (type) {
    case "tip":
      return StarIcon;
    case "info":
      return InformationCircleIcon;
    case "alert":
      return ExclamationTriangleIcon;
  }
}

export type CalloutType = "tip" | "info" | "alert";

export interface CalloutProps {
  type: CalloutType;
}

export const Callout: FC<PropsWithChildren<CalloutProps>> = ({
  type,
  children
}) => {
  const Icon = selectIcon(type);
  return (
    <div className={`assistance-callout assistance-callout-${type}`}>
      <Icon className={"assistance-callout-icon"} />
      <div className={"assistance-callout-content"}>{children}</div>
    </div>
  );
};
