/* eslint-disable react-hooks/exhaustive-deps */
import {AuthState, OktaAuth} from "@okta/okta-auth-js";
import {useOktaAuth} from "@okta/okta-react";
import {Subject, anonymous} from "./Subject";
import {useMemo} from "react";

const subjectFrom = (authState: AuthState | null): Subject => {
  return authState?.subject ? authState.subject : anonymous;
};

export interface SubjectContext {
  subject: Subject;
  oktaAuth: OktaAuth;
  authState: AuthState | null;
}

/**
 * Defines a hook that eliminates the need to repeatedly perform
 * {@link AuthState} checks before accessing the {@link Subject}.
 *
 * If the {@link Subject} has not yet been injected into the
 * {@link AuthState}, an unknown {@link Subject} will be provided.
 *
 * @return SubjectContext
 */
export const useSubject = (): SubjectContext => {
  const {oktaAuth, authState} = useOktaAuth();
  return useMemo<SubjectContext>(() => {
    const subject = subjectFrom(authState);
    return {
      subject,
      oktaAuth,
      authState
    };
  }, [oktaAuth, authState]);
};
