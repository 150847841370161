import {FC} from "react";
import {useTranslation} from "react-i18next";
import {useSubject} from "../access";
import {toAnalyticsUser} from "../analytics";
import {ProfileSection} from "./ProfileSection";

export const AnalyticsSection: FC = () => {
  const {t} = useTranslation();
  const {subject} = useSubject();

  return (
    <ProfileSection
      id={"analytics-section"}
      title={t("profile-page.section.analytics")}>
      <pre>{JSON.stringify(toAnalyticsUser(subject), null, 2)}</pre>
    </ProfileSection>
  );
};
