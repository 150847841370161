import {FC, PropsWithChildren} from "react";
import {Box} from "@interstate/components/Box";
import "./Alert.scss";

export const Alert: FC<PropsWithChildren> = ({children}) => {
  return (
    <Box data-testid={"coat-alert"} className={"coat-alert"}>
      {children}
    </Box>
  );
};
