import {FC, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "@interstate/components/Button";
import {ClipboardCheckIcon, ClipboardIcon} from "@interstate/components/Icons";
import {useInterstateToken} from "@interstate/components/InterstateThemeProvider";
import {Tooltip} from "@interstate/components/Tooltip";
import "./CopyButton.scss";

interface CopyState {
  copied: boolean;
  message: string;
}

export interface CopyButtonProps {
  content: string;
  qualifier: string;
  item: string;
  inline?: boolean;
}

export const CopyButton: FC<CopyButtonProps> = ({
  content,
  qualifier,
  item,
  inline
}) => {
  const mounted = useRef(true);
  const {t} = useTranslation();
  const token = useInterstateToken();
  const [state, setState] = useState<CopyState>({
    copied: false,
    message: t("clipboard.initial-message", {
      item
    })
  });

  const copyItemToClipboard = () => {
    navigator.clipboard
      .writeText(content)
      .then(() =>
        setState({
          copied: true,
          message: t("clipboard.copied-message", {
            item
          })
        })
      )
      .then(() =>
        setTimeout(
          () =>
            mounted.current &&
            setState({
              copied: false,
              message: t("clipboard.initial-message", {
                item
              })
            }),
          4000
        )
      );
  };

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  const className = inline ? "copy-button copy-button--inline" : "copy-button";

  return (
    <Tooltip position={"top"} toolTipContent={state.message} size={"small"}>
      <Button
        className={className}
        id={`copy-${qualifier}-button`}
        data-testid={`copy-${qualifier}-button`}
        buttonStyle={"tertiary"}
        icon={
          state.copied ? (
            <ClipboardCheckIcon color={token("sem.color.border.success")} />
          ) : (
            <ClipboardIcon />
          )
        }
        onClick={copyItemToClipboard}
      />
    </Tooltip>
  );
};
