import {HierarchyNode} from "./HierarchyNode";

export function collectSelectionPath(
  node: HierarchyNode,
  selected: string,
  accumulator: string[] = []
): boolean {
  if (node.id === selected) {
    accumulator.push(node.id);
    return true;
  }
  for (const child of node.children || []) {
    if (collectSelectionPath(child, selected, accumulator)) {
      accumulator.push(node.id);
      return true;
    }
  }
  return false;
}
