import {Permission, Protected} from "../access";
import {EditButton, EditButtonProps} from "./EditButton";

export interface PermissionBasedEditButtonProps extends EditButtonProps {
  permissions: Permission[];
}
export const PermissionBasedEditButton: React.FC<
  PermissionBasedEditButtonProps
> = ({
  onClick,
  loading = false,
  size = "default",
  qualifier,
  tooltip,
  inline = false,
  permissions
}) => {
  return (
    <Protected permissions={permissions}>
      <EditButton
        onClick={onClick}
        loading={loading}
        size={size}
        qualifier={qualifier}
        tooltip={tooltip}
        inline={inline}
      />
    </Protected>
  );
};
