import {createContext} from "react";
import {defaultTableState, TableState} from "../../../../search/context";
import {ClientEntity} from "@common-core/coat-operational-hierarchy-appsync-model";
import {ClientHierarchySearchState} from "./ClientHierarchySearchState";

export function createClientHierarchySearchStateContext() {
  return createContext<ClientHierarchySearchState>({
    searchQuery: "",
    setSearchQuery(value: ((prevState: string) => string) | string): void {},
    tableState: defaultTableState(),
    setTableState(
      value: ((prevState: TableState) => TableState) | TableState
    ): void {},
    searchResult: undefined,
    setSearchResult(
      value:
        | ((
            prevState: ClientEntity[] | undefined
          ) => ClientEntity[] | undefined)
        | ClientEntity[]
        | undefined
    ): void {},
    resetSearchState() {}
  });
}
