/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback} from "react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {Permission} from "./Permission";
import {AuthorizationFunction} from "./AuthorizationFunction";

function toFlag(permission: Permission): string {
  return `pdt.permission.${permission.resource}.${permission.action}`;
}

/**
 * <i><b>Note:</b> currently access control is being implemented
 * with LaunchDarkly; this hook is intended to insulate
 * callers from a change in the access control mechanism. It
 * is expected that in the future, perform an authorization
 * check against some service for the current {@link Subject}</i>
 */
export const useAuthorization = (): AuthorizationFunction => {
  const flags = useFlags();
  function flagIsEnabled(flag: string): boolean {
    return !!flags[flag];
  }

  return useCallback<AuthorizationFunction>(
    // prettier-ignore
    permissions => Promise.resolve(permissions
      .map(toFlag)
      .every(flagIsEnabled)),
    [flags]
  );
};
