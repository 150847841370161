import {isTransform, RuntimeTransform} from "./RuntimeTransform";
import {
  EnvironmentResolver,
  isEnvironmentResolver,
  asEnvironmentResolver
} from "./EnvironmentResolver";
import {DefaultEnvironmentResolver} from "./DefaultEnvironmentResolver";

export function newEnvironmentResolver<M, E>(
  environments:
    | RuntimeTransform<M, E>
    | EnvironmentResolver<M, E>
    | Record<string, E | RuntimeTransform<M, E>>
): EnvironmentResolver<M, E> {
  if (isEnvironmentResolver<M, E>(environments)) {
    return environments;
  }
  if (isTransform<M, E>(environments)) {
    return asEnvironmentResolver<M, E>(environments);
  }
  return new DefaultEnvironmentResolver<M, E>(environments);
}
