import {FC} from "react";
import {useTranslation} from "react-i18next";
import {Badge, BadgeVariant} from "@interstate/components/Badge";
import {Tooltip} from "@interstate/components/Tooltip";
import {
  BuildingOfficeIcon,
  LockClosedIcon,
  MegaphoneIcon,
  XCircleIcon
} from "@interstate/components/Icons";
import {IconType} from "../../lib/interstate";
import {EntityStatus} from "@common-core/coat-operational-hierarchy-appsync-model";
import "./EntityStatusBadge.scss";

export interface EntityStatusBadgeProps {
  id: string;
  status: EntityStatus;
  compact?: boolean;
  tooltip?: boolean;
}

interface EntityStatusMetadata {
  badge: {variant: BadgeVariant};
  icon: {
    type: IconType;
  };
}

const statusMetadata: Record<EntityStatus, EntityStatusMetadata> = {
  [EntityStatus.PROTECTED]: {
    badge: {variant: "info"},
    icon: {type: LockClosedIcon}
  },
  [EntityStatus.IN_BUSINESS]: {
    badge: {variant: "success"},
    icon: {type: BuildingOfficeIcon}
  },
  [EntityStatus.OUT_OF_BUSINESS]: {
    badge: {variant: "error"},
    icon: {type: XCircleIcon}
  },
  [EntityStatus.PENDING_OPENING]: {
    badge: {variant: "caution"},
    icon: {type: MegaphoneIcon}
  },
  [EntityStatus.CONFIDENTIAL_PENDING_OPENING]: {
    badge: {variant: "caution"},
    icon: {type: MegaphoneIcon}
  },
  [EntityStatus.CHANGE_IN_OWNERSHIP]: {
    badge: {variant: "caution"},
    icon: {type: MegaphoneIcon}
  },
  [EntityStatus.CONFIDENTIAL_CHANGE_IN_OWNERSHIP]: {
    badge: {variant: "caution"},
    icon: {type: MegaphoneIcon}
  }
};

const translationDefaults = {
  defaultValue: "???"
};

export const EntityStatusBadge: FC<EntityStatusBadgeProps> = ({
  id,
  status,
  compact = true,
  tooltip = compact
}) => {
  const badgeId = `entity-status-${status}-${id}`;
  const iconId = `entity-status-${status}-${id}-badge-icon`;
  const fullTranslationKey = `badges.entity-status.${status}.label`;
  const translationKey = compact
    ? `badges.entity-status.${status}.mnemonic`
    : fullTranslationKey;
  const {i18n} = useTranslation();
  const metadata = statusMetadata[status];
  const StatusIcon = metadata.icon.type;

  return tooltip ? (
    <Tooltip
      id={`entity-status-${status}-${id}-badge-tooltip`}
      position={"top"}
      size={"small"}
      toolTipContent={i18n.t(fullTranslationKey, translationDefaults)}>
      <Badge
        id={`${badgeId}-badge`}
        data-testid={badgeId}
        className={`entity-status-badge entity-status-badge-${status}`}
        variant={metadata.badge.variant}
        startIcon={
          <StatusIcon
            id={iconId}
            data-testid={iconId}
            className={"entity-status-badge-icon"}
          />
        }>
        <span className={"entity-status-badge-label"}>
          {i18n.t(translationKey, translationDefaults)}
        </span>
      </Badge>
    </Tooltip>
  ) : (
    <Badge
      id={`${badgeId}-badge`}
      data-testid={badgeId}
      className={`entity-status-badge entity-status-badge-${status}`}
      variant={metadata.badge.variant}
      startIcon={
        <StatusIcon
          id={iconId}
          data-testid={iconId}
          className={"entity-status-badge-icon"}
        />
      }>
      <span className={"entity-status-badge-label"}>
        {i18n.t(translationKey, translationDefaults)}
      </span>
    </Badge>
  );
};
