import {Runtime} from "./Runtime";

/**
 * This function returns a memoized {@link Runtime} filter will match
 * when the supplied {@link URLSearchParams} contains parameters that
 * match the parameters of the tested {@link Runtime}.
 *
 * @param parameters
 * @param checkSize
 */

const ignoreParams = ["state", "code", "partition"];

export function withMatchingParameters<M>(
  parameters: URLSearchParams,
  checkSize = false
) {
  return (runtime: Runtime<M>): boolean => {
    if (runtime.parameters) {
      const expected = runtime.parameters;
      if (
        checkSize &&
        Object.keys(expected).length !==
          Array.from(parameters).filter(
            tuple => !ignoreParams.includes(tuple[0])
          ).length
      )
        return false;
      return Object.keys(expected).reduce((matches, name) => {
        return (
          matches &&
          parameters.has(name) &&
          parameters.get(name) === decodeURIComponent(expected[name])
        );
      }, true);
    }
    return false;
  };
}
