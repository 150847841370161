import {OperationalHierarchyColumn} from "./OperationalHierarchyColumn";
import {useLocation} from "react-router-dom";
import {HashTab} from "../../../hash-tabs";
import {TabBody} from "../../../tab-content";

export const OperationalHierarchyTab: HashTab = () => {
  // Prevents hierarchy column from rendering until tab is clicked on
  const location = useLocation();
  return (
    <TabBody name={"hierarchy-tab"}>
      {location.hash === "#hierarchy" && <OperationalHierarchyColumn />}
    </TabBody>
  );
};
