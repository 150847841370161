import {FC} from "react";
import {Runtime} from "@common-core/runtime-js/runtime";
import {PartitionBadge} from "./PartitionBadge";
import {PropertyTable} from "./PropertyTable";
import {Section} from "./Section";

export const RuntimeDetailsSection: FC<{runtime: Runtime<any>}> = ({
  runtime
}) => {
  return (
    <Section className={"runtime-details-section"}>
      <h4>Runtime Details</h4>
      <PropertyTable
        id={"runtime-table"}
        data={[
          {key: "id", name: "Identifier", value: runtime.id},
          {
            key: "partition",
            name: "Partition",
            value: (
              <PartitionBadge
                id={`runtime-${runtime.id}-partition`}
                partition={runtime.partition}
              />
            )
          }
        ]}
      />
    </Section>
  );
};
