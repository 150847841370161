import {Location} from "history";

export const DEFAULT_NAME = "default";

const encode = ([name, tab]: string[]): string => {
  // prettier-ignore
  return name === DEFAULT_NAME
      ? `${tab}`
      : `${name}:${tab}`;
};

export class HashParameters {
  private readonly tabs: Record<string, string> = {};

  set(name: string, tab: string): HashParameters {
    this.tabs[name] = tab;
    return this;
  }

  get(name: string): string {
    return this.tabs[name];
  }

  hash(): string {
    const entries = Array.from(Object.entries(this.tabs));
    // prettier-ignore
    return entries.length > 0
        ? `#${entries.map(encode).join("::")}`
        : "";
  }

  static value(hash: string): string {
    // prettier-ignore
    return hash.startsWith("#")
          ? hash.slice(1)
          : hash;
  }

  static from(location: Location): HashParameters {
    const hash = new HashParameters();
    if (location.hash) {
      const value = this.value(location.hash);
      value.split("::").forEach(pair => {
        const entry = pair.split(":");
        if (entry.length == 1) {
          hash.set("default", entry[0]);
        } else {
          hash.set(entry[0], entry[1]);
        }
      });
    }
    return hash;
  }
}
