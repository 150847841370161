import {Location} from "./Location";
import {Runtime} from "./Runtime";
import {RuntimeCollection} from "./RuntimeCollection";

/**
 * This {@link RuntimeCollection} implementation holds a single {@link Runtime}
 * that is always found and resolved.
 */
// prettier-ignore
export class SingleRuntimeCollection<M>
  implements RuntimeCollection<M> {
  private readonly runtime: Runtime<M>;

  constructor(runtime: Runtime<M>) {
    this.runtime = runtime;
  }

  find(id: string): Runtime<M> {
    return this.runtime;
  }

  list(): Runtime<M>[] {
    return [this.runtime];
  }

  resolve(location: Location): Runtime<M> {
    return this.runtime;
  }
}
