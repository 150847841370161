import {currentPartition, PartitionOptions} from "../partition";
import {Location} from "./Location";
import {RuntimeError} from "./RuntimeError";
import {Runtime} from "./Runtime";
import {RuntimeCollection} from "./RuntimeCollection";
import {withMatchingParameters} from "./withMatchingParameters";

export interface DefaultRuntimeCollectionOptions {
  partition?: PartitionOptions;
}

/**
 * The {@link RuntimeCollection} implementation that holds an array of {@link Runtime}
 * instances.
 */
// prettier-ignore
export class DefaultRuntimeCollection<M>
  implements RuntimeCollection<M> {
  private readonly runtimes: Runtime<M>[];
  private readonly options: DefaultRuntimeCollectionOptions;

  constructor(
    runtimes: Runtime<M>[],
    options: DefaultRuntimeCollectionOptions = {}
  ) {
    this.runtimes = runtimes;
    this.options = options;
  }

  list(): Runtime<M>[] {
    return this.runtimes;
  }

  find(id: string): Runtime<M> {
    const runtime = this.runtimes.find(runtime => runtime.id === id);
    if (!runtime) {
      throw new RuntimeError(`No runtime identified by ${id}`);
    }
    return runtime;
  }

  resolve(location: Location): Runtime<M> {
    // Start with just the hostname
    let candidates = this.for(location.hostname);
    if (candidates.length == 0) {
      throw new RuntimeError(`No runtime available for ${location.hostname}`);
    }
    // Look no further if there is only one match
    if (candidates.length === 1) {
      return candidates[0];
    }
    // Multiple matches, filter by partition matching partition
    const parameters = new URLSearchParams(location.search);
    const partition = currentPartition(parameters, this.options.partition);
    candidates = candidates.filter(runtime => {
      return runtime.partition === partition;
    });
    // Look no further if there is only one match
    if (candidates.length === 1) {
      return candidates[0];
    }
    // Multiple matches for host and partition, attempt to match search parameters
    const matched = this.findWithMatchingParameters(candidates, parameters);
    // Look no further if there was a match
    if (matched) {
      return matched;
    }
    // Use the first without search parameters (more general)
    const first = candidates.find(runtime => !runtime.parameters);
    if (first) {
      return first;
    }
    throw new RuntimeError(`No runtime available for ${location}`);
  }

  for(hostname: string): Runtime<M>[] {
    return this.runtimes.filter(
      runtime =>
        runtime.deployment.hostname.toLowerCase() === hostname.toLowerCase()
    );
  }

  private findWithMatchingParameters(candidates: Runtime<M>[], parameters: URLSearchParams): Runtime<M> | undefined {
    const strictMatch = candidates.find(withMatchingParameters(parameters, true));
    // Look no further if there was a strict match
    if (strictMatch) {
      return strictMatch;
    }
    return candidates.find(withMatchingParameters(parameters));
  }
}
