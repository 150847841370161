import {FC} from "react";
import {useTranslation} from "react-i18next";
import {useEnvironment} from "@common-core/react-runtime/context";
import {AlertSeverity} from "@interstate/components/Alert/Types/alertTypes";
import {Alert} from "@interstate/components/Alert";
import {Link} from "@interstate/components/Link";
import {Environment} from "../runtime";
import {isError} from "../utils";
import {PageTemplate} from "./PageTemplate";
import {CoxAutoHeader} from "./CoxAutoHeader";
import {SidebarItem} from "./Sidebar";
import "./ErrorPage.scss";

export interface ErrorPageProps {
  error: any;
  severity?: AlertSeverity;
  logo?: boolean;
  sidebarItems?: SidebarItem[];
}

export const ErrorPage: FC<ErrorPageProps> = ({
  error,
  severity = "caution",
  logo = true,
  sidebarItems
}) => {
  const {t} = useTranslation();
  const environment = useEnvironment<Environment>();

  // Handle access denied
  if (error.message === "User is not assigned to the client application.") {
    return (
      <PageTemplate
        id={"error-page"}
        title={"Error"}
        header={logo && <CoxAutoHeader />}
        sidebarItems={sidebarItems}
        alert={
          <Alert id={"error"} type={severity}>
            {t("error-page.access-denied")}
            <Link
              id="link-to-landing-page"
              href={environment.landingPage}
              size={"sm"}
              visitedStyle={false}>
              {t("error-page.landing-page-link")}
            </Link>
          </Alert>
        }
      />
    );
  }

  // Handle a generic Error
  if (isError(error)) {
    return (
      <PageTemplate
        id={"error-page"}
        title={"Error"}
        header={logo && <CoxAutoHeader />}
        sidebarItems={sidebarItems}
        alert={
          <Alert id={"error"} type={severity}>
            <span className={"error-name"}>{error.name}:</span>
            <span className={"error-message"}>{error.message}</span>
          </Alert>
        }
      />
    );
  }

  // Handle unknown error
  return (
    <PageTemplate
      id={"error-page"}
      title={"Error"}
      header={logo && <CoxAutoHeader />}
      sidebarItems={sidebarItems}
      alert={
        <Alert id={"error"} type={severity}>
          {error.toString()}
        </Alert>
      }
    />
  );
};
