import {ComboBoxOption} from "@interstate/components/ComboBox";
import {Runtime} from "@common-core/runtime-js/runtime";
import {RuntimeGroup} from "./RuntimeGroup";

export type RuntimeGroupMapper<M> = (group: RuntimeGroup<M>) => ComboBoxOption[];

export function toComboBoxOptions<M>(selected?: Runtime<M>): RuntimeGroupMapper<M> {
  return (group: RuntimeGroup<M>): ComboBoxOption[] => {
    return group.members
      .map(runtime => {
        return {
          value: runtime.id,
          label: runtime.id,
          selected: runtime.id === selected?.id
        } as ComboBoxOption
      })
      .reduce(
        (options, option) => {
          options.push(option)
          return options;
        },
        [{groupHeader: true, value: group.name, label: group.name}] as ComboBoxOption[]
      );
  }
}
