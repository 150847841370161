import {FC, PropsWithChildren, useState} from "react";
import {OperationalEntityContext} from "./OperationalEntityContext";
import {OperationalEntity} from "@common-core/coat-operational-hierarchy-appsync-model";

export interface OperationalEntityProviderProps {
  entity: OperationalEntity;
}

export const OperationalEntityProvider: FC<
  PropsWithChildren<OperationalEntityProviderProps>
> = ({entity, children}) => {
  const [statefulEntity, setStatefulEntity] = useState(entity);

  return (
    <OperationalEntityContext.Provider
      value={{
        entity: statefulEntity,
        setEntity: setStatefulEntity
      }}>
      {children}
    </OperationalEntityContext.Provider>
  );
};
