import {FC, ReactNode, useEffect} from "react";
import {Box} from "@interstate/components/Box";
import {useRuntime} from "@common-core/react-runtime/context";
import {Metadata} from "../runtime";
import {useActivityIndicator} from "../activity-indicator";
import "./BasicPage.scss";

export interface PageProps {
  id: string;
  title?: string;
  className?: string;
  children: ReactNode;
}

/**
 * The {@link BasicPage} establishes the most basic layout used by all
 * pages within the application. It does the following:
 *
 * <ul>
 *   <li>Disables the loading state when mounted</li>
 *   <li>Renders children within a top level div</li>
 * </ul>
 *
 * This page can be used for both authenticated and unauthenticated
 * routes as it makes no assumptions about the authentication state.
 *
 * @param id The id of the page
 * @param title The title of the page
 * @param className Additional classes to attach to the root element
 * @param children The contained components
 * @constructor
 */
export const BasicPage: FC<PageProps> = ({
  id,
  title = id,
  className,
  children
}) => {
  const runtime = useRuntime<Metadata>();
  const [, setWorking] = useActivityIndicator();
  const classes = className ? ` ${className}` : "";

  useEffect(() => {
    setWorking(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      id={id}
      data-testid={id}
      className={`coat-page coat-page--${runtime.partition}${classes}`}>
      {children}
    </Box>
  );
};
