import {AuthState, OktaAuth, OktaAuthOptions} from "@okta/okta-auth-js";
import {AuthConfiguration, buildOktaAuth} from "@bridge/okta-auth";
import {LDClient} from "launchdarkly-js-client-sdk";
import {transformAuthState} from "./transformAuthState";

export function createOktaAuth(
  configuration: AuthConfiguration,
  ldClient?: LDClient
): OktaAuth {
  return buildOktaAuth(
    configuration,
    (options: OktaAuthOptions): OktaAuthOptions => {
      options.transformAuthState = async (
        oktaAuth,
        authState
      ): Promise<AuthState> =>
        transformAuthState(oktaAuth, authState, ldClient);
      if (options.tokenManager) {
        options.tokenManager.autoRemove = true;
      }
      if (options.services) {
        options.services.autoRemove = true;
      }
      options.devMode = true;
      return options;
    }
  );
}
