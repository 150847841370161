import {BoidAssignment} from "@common-core/coat-operational-hierarchy-appsync-model";
import {BoidGroup} from "../model";

export type BoidAllocationMapper = (
  allocations: BoidGroup<Record<string, boolean>>[]
) => BoidGroup<Record<string, boolean>>[];

/**
 * This memoized function produces a mapping function that is capable of merging
 * the supplied {@link BoidAssignment} collection into an existing collection
 * used to track assigned and unassigned BOIDs of a given type.
 *
 * @param assignments
 * @see {@link collectBoidAllocationsFromClientEntities}
 */
export const mergeBoidAssignmentsIntoBoidAllocations = (
  assignments: BoidAssignment[] = []
): BoidAllocationMapper => {
  return allocations => {
    assignments.forEach(assignment => {
      const allocation = allocations.find(
        allocation => allocation.type === assignment.businessOperationType
      );
      // If there is an existing allocation defined for the type then mark
      // the BOID as assigned
      if (allocation) {
        allocation.boids[assignment.businessOperationId] = true;
      }
      // Otherwise add a new allocation for the type
      else {
        allocations.push({
          type: assignment.businessOperationType,
          boids: {[assignment.businessOperationId]: true}
        });
      }
    });
    return allocations;
  };
};
