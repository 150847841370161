import {Partition, PartitionPredicate} from "../partition";
import {Runtime} from "../runtime/Runtime";

export interface ParameterOptions {
  search?: URLSearchParams | string;
  include?: PartitionPredicate;
}

export function injectParameters<M>(
  runtime: Runtime<M>,
  {
    search,
    include = partition => partition !== Partition.PROD
  }: ParameterOptions = {}
): URLSearchParams {
  // prettier-ignore
  const searchParameters =
    typeof search === "undefined"
      ? new URLSearchParams()
      : typeof search === "string"
        ? new URLSearchParams(search)
        : search;
  const runtimeParameters = runtime.parameters;
  if (runtimeParameters) {
    // prettier-ignore
    Object.entries(runtimeParameters)
      .forEach(([name, value]) =>
        searchParameters.set(name, value));
  }
  if (include(runtime.partition)) {
    searchParameters.set("partition", runtime.partition);
  }
  return searchParameters;
}
