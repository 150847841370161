import {FC} from "react";
import {useLocation} from "react-router-dom";
import {ClientHierarchySearch} from "./ClientHierarchySearch";
import {SearchPageState} from "../../../search";
import {useSidebarItems} from "../../../hooks";
import {useTranslation} from "react-i18next";
import {PageHeading, PageTemplate} from "../../../layout";
import {BackToButton, Pages} from "../../../back-buttons";

/**
 * The {@link OperationalSearchPage} component provides the page layout plugging
 * in components where needed. It also concerns itself with routing
 * information and extracts what it needs to prepare the {@link OldCommonOrgEntitySearch}
 * component for display.
 *
 * @constructor
 */
export const ClientHierarchySearchPage: FC = () => {
  const location = useLocation<SearchPageState>();
  const query = location.search.match(/query=/i)
    ? new URLSearchParams(location.search).get("query") || ""
    : location.state?.query || "";
  const sidebarItems = useSidebarItems("client-hierarchy-search");
  const {t} = useTranslation();

  return (
    <PageTemplate
      banner={
        <PageHeading
          title={t("client-hierarchy.banner.title")}
          summary={t("client-hierarchy.banner.summary")}
        />
      }
      id={"client-hierarchy-search-page"}
      title={"Search Page"}
      breadcrumbs={<BackToButton page={Pages.Home} />}
      sidebarItems={sidebarItems}>
      <ClientHierarchySearch query={query} />
    </PageTemplate>
  );
};
