import {FC} from "react";
import {Heading} from "../../card";
import "../../card/EntityCardHeader.scss";
import {OperationalEntity} from "@common-core/coat-operational-hierarchy-appsync-model";

export interface OperationalEntityCardHeaderProps {
  entity: OperationalEntity;
  heading: Heading;
}

export const OperationalEntityCardHeader: FC<
  OperationalEntityCardHeaderProps
> = ({entity, heading}) => {
  const Heading = heading;
  return (
    <header
      id={`entity-card-header-${entity.id}`}
      data-testid={`entity-card-header-${entity.id}`}
      className={"entity-card-header"}>
      <Heading className={"entity-card-header-heading"}>
        {entity.organizationInformation.dbaName}
      </Heading>
    </header>
  );
};
