import {FC, PropsWithChildren, useState} from "react";
import {Snackbar} from "@interstate/components/Snackbar";
import {Feedback} from "./Feedback";
import {FeedbackContext} from "./FeedbackContext";

export interface FeedbackProviderProps {
  initial?: Feedback;
}

export const FeedbackProvider: FC<PropsWithChildren<FeedbackProviderProps>> = ({
  initial,
  children
}) => {
  const [feedback, setFeedback] = useState(initial);
  return (
    <FeedbackContext.Provider value={[feedback, setFeedback]}>
      {feedback && (
        <Snackbar
          id={"coat-user-feedback"}
          data-testid={"coat-user-feedback"}
          className={"user-feedback"}
          show={true}
          position={feedback.position || "bottom-center"}
          type={feedback.type}
          autoHide={feedback.autoHide}
          message={feedback.message}
          action={feedback.action}
          onClose={(event, reason) => {
            try {
              feedback?.onClose && feedback.onClose(event, reason);
            } finally {
              setFeedback(undefined);
            }
          }}
        />
      )}
      {children}
    </FeedbackContext.Provider>
  );
};
