import {FC} from "react";
import {useTranslation} from "react-i18next";
import {useSubject} from "../access";
import {ProfileSection} from "./ProfileSection";

const initials = (name: string): string => {
  return name
    .split(/\s+/)
    .map(part => part.charAt(0).toUpperCase())
    .reduce((initials, part) => `${initials}${part}`);
};

export const AccessSection: FC = () => {
  const {t} = useTranslation();
  const {subject} = useSubject();

  return (
    <ProfileSection
      id={"access-section"}
      title={t("profile-page.section.access")}>
      <pre>{JSON.stringify(subject, null, 2)}</pre>
    </ProfileSection>
  );
};
