import {BoidAssignment} from "@common-core/coat-operational-hierarchy-appsync-model";
import {BoidGroup} from "../model";

/**
 * This reducer function collects a list of {@link BoidGroup} instances from a stream of
 * {@link BoidAssignment} instances grouping them by type.
 *
 * @param groups The accumulator holding the collected groups
 * @param assignment The current {@link BoidAssignment} being processed from the stream
 *
 * @see {@link supportedBoidAssignments} for creating the accumulator
 */
export const groupBoidAssignmentsByType = (
  groups: BoidGroup<string[]>[],
  assignment: BoidAssignment
): BoidGroup<string[]>[] => {
  // Find the group matching the assignment's business operation type
  const group = groups.find(
    group =>
      group.type.toUpperCase() ===
      assignment.businessOperationType.toUpperCase()
  );
  // If the group is present, add the current assigned id to the list
  if (group) {
    group.boids?.push(assignment.businessOperationId);
  }
  // Otherwise add a new assignment group for the type with the
  // current assignment's id as the initial entry
  else {
    groups.push({
      type: assignment.businessOperationType.toUpperCase(),
      boids: [assignment.businessOperationId]
    });
  }
  return groups;
};
