import {Location} from "./Location";
import {RuntimeEventListener} from "./RuntimeEventListener";
import {RuntimeEnvironment} from "./RuntimeEnvironment";
import {Runtime} from "./Runtime";
import {RuntimeError} from "./RuntimeError";
import {RuntimeEvent} from "./RuntimeEvent";
import {RuntimeActivator} from "./RuntimeActivator";
import {RuntimeResolver} from "./RuntimeResolver";
import {EnvironmentResolver} from "./EnvironmentResolver";

// prettier-ignore
export class DefaultRuntimeActivator<M, E>
  implements RuntimeActivator<M, E> {
  private readonly listeners: RuntimeEventListener<M, E>[] = [];
  protected readonly runtimes: RuntimeResolver<M>;
  protected readonly environments: EnvironmentResolver<M, E>;

  private active: RuntimeEnvironment<M, E>;

  constructor(
    runtimes: RuntimeResolver<M>,
    environments: EnvironmentResolver<M, E>
  ) {
    this.runtimes = runtimes;
    this.environments = environments;
    this.active = {} as RuntimeEnvironment<M, E>;
  }

  public activated(): boolean {
    const {runtime, environment} = this.active;
    return !!runtime && !!environment;
  }

  public current(): RuntimeEnvironment<M, E> {
    return this.active;
  }

  public onActivation(listener: RuntimeEventListener<M, E>) {
    this.listeners.push(listener);
    return this;
  }

  public remove(listener: RuntimeEventListener<M, E>) {
    const index = this.listeners.indexOf(listener);
    if (index >= 0) {
      this.listeners.splice(index, 1);
    }
    return this;
  }

  public activationListeners(): RuntimeEventListener<M, E>[] {
    return [...this.listeners];
  }

  public resolve(location: Location): Runtime<M> | never {
    const runtime = this.runtimes.resolve(location);
    if (!runtime) {
      throw new RuntimeError(`No runtime available for ${location}`);
    }
    return runtime;
  }

  public activate(from: Location): RuntimeEnvironment<M, E> | never {
    const runtime = this.resolve(from);
    const environment = this.environment(runtime);
    return this.apply({runtime, environment});
  }

  protected environment(runtime: Runtime<M>): E | never {
    const environment = this.environments.resolve(runtime);
    if (!environment) {
      throw new RuntimeError(
        `No environment available for runtime ${runtime.id}`
      );
    }
    return environment;
  }

  protected apply(current: RuntimeEnvironment<M, E>): RuntimeEnvironment<M, E> {
    const previous = this.active;
    this.active = current;
    const event: RuntimeEvent<M, E> = {current, previous};
    this.listeners.forEach(listener => {
      try {
        listener(event);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
    return this.active;
  }
}
