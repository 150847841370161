import {
  OperationalEntity,
  OperationalEntityInput
} from "@common-core/coat-operational-hierarchy-appsync-model";

export const toOperationalEntityInput = (
  entity: OperationalEntity
): OperationalEntityInput => {
  return {
    parentId: entity.parent?.id,
    sources: entity.sources || [],
    organizationInformation: entity.organizationInformation,
    oems: entity.oems || []
  };
};
