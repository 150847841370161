import {Environment} from "./Environment";
import {AuthConfiguration, bridge, BridgeRuntime} from "@bridge/okta-auth";
import {UrlBuilder} from "@common-core/runtime-js/util";
import {Partition} from "@common-core/runtime-js/partition";
import {Runtime, RuntimeTransform} from "@common-core/runtime-js/runtime";
import {Metadata} from "./Metadata";
import {BridgeBarRuntime} from "@common-core/react-bridge-bar";
// during deployment tests the new BFF instances are available on this port
const IT_PORT = ":8443";

const landingPageUrlDz =
  "https://mycoxautomotive.dangerzone.coxautoservices.com/";
const landingPageUrlLive = "https://mycoxautomotive.coxautoinc.com/";

const headers: Record<string, string> = {
  "accept": "application/json",
  "content-type": "application/json"
};
const enum BridgeClient {
  PRODUCTION = "0oaumndm6412vvKyP357",
  NON_PRODUCTION = "0oaumn4aagc4OaElL357",
  DZ_PRODUCTION = "0oa1rzjngzpqHJquf0h8",
  DZ_NON_PRODUCTION = "0oa1rzjcwdrmrgKAP0h8"
}
const pendoApiKey = "5a4bf409-42ed-4e44-4800-d7c61f4262f6";

export function buildRedirectUri(runtime: Runtime<Metadata>): string {
  const deployment = runtime.deployment;
  return UrlBuilder.builder(deployment.secure)
    .withHostname(deployment.hostname)
    .withPort(deployment.port)
    .withPathname("/login/callback")
    .withParameters(() => {
      return runtime.partition === Partition.NON_PROD
        ? {partition: Partition.NON_PROD}
        : null;
    })
    .withParameters(runtime.parameters)
    .build();
}

export function buildAuthConfig(
  bridgeRuntime: BridgeRuntime,
  clientId: string,
  runtime: Runtime<Metadata>
): AuthConfiguration {
  return {
    idp: bridge(bridgeRuntime),
    clientId,
    redirectUri: buildRedirectUri(runtime),
    scopes: [
      "bridge.signin",
      "openid",
      "profile",
      "enterprise.commons-operations-admin.read"
    ],
    renewTokensOnExpiration: true
  };
}

export function buildBffUrl(
  baseDomain: string,
  runtime: Runtime<Metadata>
): string {
  let subdomain = "bff";

  let port = "";
  if (runtime.parameters) {
    const api = runtime.parameters["api"];

    if (api === "local") {
      return "http://localhost:8080";
    }

    const region = runtime.parameters["region"];
    if (region) {
      subdomain = `${region}-${subdomain}`;
    }

    if (api === "it") {
      port = IT_PORT;
    }
  }
  return `https://${subdomain}.${baseDomain}${port}`;
}

export const environments: Record<
  string,
  RuntimeTransform<Metadata, Environment>
> = {
  // Local UI with Local Backend
  "local-local/non-prod": runtime => {
    return dzNonProd(runtime);
  },
  "local-local/prod": runtime => {
    return dzProd(runtime);
  },
  // Local UI with Dangerzone Backend
  "local-dz/non-prod": runtime => {
    return dzNonProd(runtime);
  },
  "local-dz/prod": runtime => {
    return dzProd(runtime);
  },
  // Local UI with Live Backend
  "local-live/non-prod": runtime => {
    return liveNonProd(runtime);
  },
  "local-dz/us-east-1/prod": runtime => {
    return dzProd(runtime);
  },
  // Dangerzone
  "dz/non-prod": runtime => {
    return dzNonProd(runtime);
  },
  "dz-integration/non-prod": runtime => {
    return dzNonProd(runtime);
  },
  "dz/prod": runtime => {
    return dzProd(runtime);
  },
  "dz-legacy/non-prod": runtime => {
    return dzNonProd(runtime);
  },
  "dz-legacy/prod": runtime => {
    return dzProd(runtime);
  },
  // for Backend BFF Deployment tests
  "dz/it/us-east-1/prod": runtime => {
    return dzProd(runtime);
  },
  "dz/it/us-west-2/prod": runtime => {
    return dzProd(runtime);
  },
  "dz-integration/prod": runtime => {
    return dzProd(runtime);
  },
  // Live
  "live-integration/non-prod": runtime => {
    return liveNonProd(runtime);
  },
  "live/non-prod": runtime => {
    return liveNonProd(runtime);
  },
  "live-new/non-prod": runtime => {
    return liveNonProd(runtime);
  },
  "live-new-integration/non-prod": runtime => {
    return liveNonProd(runtime);
  },
  "live/prod": runtime => {
    return liveProd(runtime);
  },
  "live-integration/prod": runtime => {
    return liveProd(runtime);
  },
  "live-new/prod": runtime => {
    return liveProd(runtime);
  },
  "live-new-integration/prod": runtime => {
    return liveProd(runtime);
  }
};

const dzNonProd = (runtime: Runtime<Metadata>): Environment => {
  return {
    applicationId: "2e96522e-6a04-49d0-8be9-0dee2af1c494",
    auth: buildAuthConfig(
      BridgeRuntime.DZ_NON_PRODUCTION,
      BridgeClient.DZ_NON_PRODUCTION,
      runtime
    ),
    backend: {
      baseUri: buildBffUrl("dangerzone.coat2.coxautoservices.com", runtime),
      headers
    },
    appsync:
      "https://os2y2gjigfbidal5c5hdlwjudm.appsync-api.us-east-1.amazonaws.com/graphql",
    bridgeBar: "dz_np" as BridgeBarRuntime,
    landingPage: `${landingPageUrlDz}?partition=${runtime.partition}`,
    ldClientSideID: "620519d94d6d4813df2b4004",
    pendoApiKey,
    tabComponents: [
      {
        script: {
          id: "pbc-prototype-source",
          source: {
            module:
              "https://dangerzone.wappr.coxautoservices.com/web-component-application-prototype.esm.js",
            fallback:
              "https://dangerzone.wappr.coxautoservices.com/web-component-application-prototype.js"
          }
        },
        element: "web-component-application-prototype"
      },
      {
        script: {
          id: "bad-component-source",
          source: {
            classic:
              "https://dangerzone.wappr.coxautoservices.com/bad-component.js"
          }
        },
        element: "bad-component"
      },
      {
        script: {
          id: "common-consumer-coorg-back-office-ui",
          source: {
            module:
              "https://integ-back-office-ui-wfe.vin-cedricnp.cloud/back-office-ui/web-components/back-office-ui-web-comp-latest.js",
            fallback:
              "https://integ-back-office-ui-wfe.vin-cedricnp.cloud/back-office-ui/web-components/back-office-ui-web-comp-latest.js"
          }
        },
        element: "common-consumer-coorg-back-office-ui",
        attributes: {
          partition: "non-production"
        }
      },
      {
        script: {
          id: "cvi-dealer-result-component",
          source: {
            module:
              "https://cvi-admin-tools.coxautoinventory-np.com/cvi-dealer-result-component.js",
            fallback:
              "https://cvi-admin-tools.coxautoinventory-np.com/cvi-dealer-result-component.js"
          }
        },
        element: "cvi-dealer-result-component"
      },
      {
        script: {
          id: "dealer-user-search-component",
          source: {
            module:
              "https://admin.bridge.dangerzone.coxautoservices.com/components/user-search/dealer-user-search-component.esm.js",
            fallback:
              "https://admin.bridge.dangerzone.coxautoservices.com/components/user-search/dealer-user-search-component.js"
          }
        },
        element: "dealer-user-search-component",
        hash: "users",
        attributes: {deployment: "dz", partition: "non-prod"}
      },
      {
        script: {
          id: "entitlement-manager-component",
          source: {
            module:
              "https://ui.org-app-entitlement.dangerzone.coxautoservices.com/entitlement-manager-component.esm.js",
            fallback:
              "https://ui.org-app-entitlement.dangerzone.coxautoservices.com/entitlement-manager-component.js"
          }
        },
        element: "entitlement-manager-component",
        hash: "entitlements",
        attributes: {deployment: "dz", partition: "non-prod"}
      }
    ],
    historyComponent: {
      script: {
        id: "entity-history-component",
        source: {
          module:
            "https://static.history-ui.common-org.dangerzone.coxautoservices.com/history-web-component.esm.js",
          fallback:
            "https://static.history-ui.common-org.dangerzone.coxautoservices.com/history-web-component.js"
        }
      },
      element: "history-component",
      attributes: {deployment: "dz", partition: "non-prod"}
    }
  };
};

const dzProd = (runtime: Runtime<Metadata>): Environment => {
  return {
    applicationId: "2ca4aec9-452a-4b54-8b19-ed3e16432064",
    auth: buildAuthConfig(
      BridgeRuntime.DZ_PRODUCTION,
      BridgeClient.DZ_PRODUCTION,
      runtime
    ),
    backend: {
      baseUri: buildBffUrl("dangerzone.coat2.coxautoservices.com", runtime),
      headers
    },
    appsync:
      "https://os2y2gjigfbidal5c5hdlwjudm.appsync-api.us-east-1.amazonaws.com/graphql",
    bridgeBar: "dz_prod" as BridgeBarRuntime,
    landingPage: `${landingPageUrlDz}?partition=${runtime.partition}`,
    ldClientSideID: "620519d94d6d4813df2b4004",
    pendoApiKey,
    tabComponents: [
      {
        script: {
          id: "dealer-user-search-component",
          source: {
            module:
              "https://admin.bridge.dangerzone.coxautoservices.com/components/user-search/dealer-user-search-component.esm.js",
            fallback:
              "https://admin.bridge.dangerzone.coxautoservices.com/components/user-search/dealer-user-search-component.js"
          }
        },
        element: "dealer-user-search-component",
        hash: "users",
        attributes: {deployment: "dz", partition: "prod"}
      },
      {
        script: {
          id: "entitlement-manager-component",
          source: {
            module:
              "https://ui.org-app-entitlement.dangerzone.coxautoservices.com/entitlement-manager-component.esm.js",
            fallback:
              "https://ui.org-app-entitlement.dangerzone.coxautoservices.com/entitlement-manager-component.js"
          }
        },
        element: "entitlement-manager-component",
        hash: "entitlements",
        attributes: {deployment: "dz", partition: "prod"}
      }
    ],
    historyComponent: {
      script: {
        id: "entity-history-component",
        source: {
          module:
            "https://static.history-ui.common-org.dangerzone.coxautoservices.com/history-web-component.esm.js",
          fallback:
            "https://static.history-ui.common-org.dangerzone.coxautoservices.com/history-web-component.js"
        }
      },
      element: "history-component",
      attributes: {deployment: "dz", partition: "prod"}
    }
  };
};

const liveNonProd = (runtime: Runtime<Metadata>): Environment => {
  return {
    applicationId: "a99ca99d-e408-4337-97e7-f005ab5b7551",
    auth: buildAuthConfig(
      BridgeRuntime.NON_PRODUCTION,
      BridgeClient.NON_PRODUCTION,
      runtime
    ),
    backend: {
      baseUri: buildBffUrl("coat2.coxautoinc.com", runtime),
      headers
    },
    appsync:
      "https://mpsoe43fbnbzxpg7o3qpp3maa4.appsync-api.us-east-1.amazonaws.com/graphql",
    bridgeBar: "qa" as BridgeBarRuntime,
    landingPage: `${landingPageUrlLive}?partition=${runtime.partition}`,
    ldClientSideID: "620519d94d6d4813df2b4006",
    pendoApiKey,
    tabComponents: [
      {
        script: {
          id: "pbc-prototype-source",
          source: {
            module:
              "https://wappr.coxautoinc.com/web-component-application-prototype.esm.js",
            fallback:
              "https://wappr.coxautoinc.com/web-component-application-prototype.js"
          }
        },
        element: "web-component-application-prototype"
      },
      {
        script: {
          id: "common-consumer-coorg-back-office-ui",
          source: {
            module:
              "https://integ-back-office-ui-wfe.vin-cedricnp.cloud/back-office-ui/web-components/back-office-ui-web-comp-latest.js",
            fallback:
              "https://integ-back-office-ui-wfe.vin-cedricnp.cloud/back-office-ui/web-components/back-office-ui-web-comp-latest.js"
          }
        },
        element: "common-consumer-coorg-back-office-ui",
        attributes: {
          partition: "non-production"
        }
      },
      {
        script: {
          id: "cvi-dealer-result-component",
          source: {
            module:
              "https://cvi-admin-tools.coxautoinventory-np.com/cvi-dealer-result-component.js",
            fallback:
              "https://cvi-admin-tools.coxautoinventory-np.com/cvi-dealer-result-component.js"
          }
        },
        element: "cvi-dealer-result-component"
      },
      {
        script: {
          id: "dealer-user-search-component",
          source: {
            module:
              "https://admin.bridge.coxautoinc.com/components/user-search/dealer-user-search-component.esm.js",
            fallback:
              "https://admin.bridge.coxautoinc.com/components/user-search/dealer-user-search-component.js"
          }
        },
        element: "dealer-user-search-component",
        hash: "users",
        attributes: {deployment: "live", partition: "non-prod"}
      },
      {
        script: {
          id: "entitlement-manager-component",
          source: {
            module:
              "https://ui.org-app-entitlement.coxautoservices.com/entitlement-manager-component.esm.js",
            fallback:
              "https://ui.org-app-entitlement.coxautoservices.com/entitlement-manager-component.js"
          }
        },
        element: "entitlement-manager-component",
        hash: "entitlements",
        attributes: {deployment: "live", partition: "non-prod"}
      },
      {
        script: {
          id: "epam-provisioning-component",
          source: {
            module: "https://ui.np.dcpnc.app.coxautoinc.com/main.bundle.esm.js",
            fallback: "https://ui.np.dcpnc.app.coxautoinc.com/main.bundle.js"
          }
        },
        element: "dcpnc-admin-tool",
        hash: "provisioning",
        attributes: {deployment: "live", partition: "non-prod"}
      }
    ],
    historyComponent: {
      script: {
        id: "entity-history-component",
        source: {
          module:
            "https://static.history-ui.common-org.coxautoservices.com/history-web-component.esm.js",
          fallback:
            "https://static.history-ui.common-org.coxautoservices.com/history-web-component.js"
        }
      },
      element: "history-component",
      attributes: {deployment: "live", partition: "non-prod"}
    }
  };
};

const liveProd = (runtime: Runtime<Metadata>): Environment => {
  return {
    applicationId: "e093ea7a-a94a-4c35-8270-cd0a4bd84ffd",
    auth: buildAuthConfig(
      BridgeRuntime.PRODUCTION,
      BridgeClient.PRODUCTION,
      runtime
    ),
    backend: {
      baseUri: buildBffUrl("coat2.coxautoinc.com", runtime),
      headers
    },
    appsync:
      "https://mpsoe43fbnbzxpg7o3qpp3maa4.appsync-api.us-east-1.amazonaws.com/graphql",
    bridgeBar: "prod" as BridgeBarRuntime,
    landingPage: `${landingPageUrlLive}?partition=${runtime.partition}`,
    ldClientSideID: "620519d94d6d4813df2b4006",
    pendoApiKey,
    tabComponents: [
      {
        script: {
          id: "common-consumer-coorg-back-office-ui",
          source: {
            module:
              "https://prod-back-office-ui-wfe.vin-cedric.cloud/back-office-ui/web-components/back-office-ui-web-comp-latest.js",
            fallback:
              "https://prod-back-office-ui-wfe.vin-cedric.cloud/back-office-ui/web-components/back-office-ui-web-comp-latest.js"
          }
        },
        element: "common-consumer-coorg-back-office-ui",
        attributes: {
          partition: "production"
        }
      },
      {
        script: {
          id: "cvi-dealer-result-component",
          source: {
            module:
              "https://cvi-admin-tools.coxautoinventory.com/cvi-dealer-result-component.js",
            fallback:
              "https://cvi-admin-tools.coxautoinventory.com/cvi-dealer-result-component.js"
          }
        },
        element: "cvi-dealer-result-component"
      },
      {
        script: {
          id: "dealer-user-search-component",
          source: {
            module:
              "https://admin.bridge.coxautoinc.com/components/user-search/dealer-user-search-component.esm.js",
            fallback:
              "https://admin.bridge.coxautoinc.com/components/user-search/dealer-user-search-component.js"
          }
        },
        element: "dealer-user-search-component",
        hash: "users",
        attributes: {deployment: "live", partition: "prod"}
      },
      {
        script: {
          id: "entitlement-manager-component",
          source: {
            module:
              "https://ui.org-app-entitlement.coxautoservices.com/entitlement-manager-component.esm.js",
            fallback:
              "https://ui.org-app-entitlement.coxautoservices.com/entitlement-manager-component.js"
          }
        },
        element: "entitlement-manager-component",
        hash: "entitlements",
        attributes: {deployment: "live", partition: "prod"}
      },
      {
        script: {
          id: "epam-provisioning-component",
          source: {
            module: "https://ui.dcpnc.app.coxautoinc.com/main.bundle.esm.js",
            fallback: "https://ui.dcpnc.app.coxautoinc.com/main.bundle.js"
          }
        },
        element: "dcpnc-admin-tool",
        hash: "provisioning",
        attributes: {deployment: "live", partition: "prod"},
        featureFlag: "pdt.display-provisioning-tab"
      }
    ],
    historyComponent: {
      script: {
        id: "entity-history-component",
        source: {
          module:
            "https://static.history-ui.common-org.coxautoservices.com/history-web-component.esm.js",
          fallback:
            "https://static.history-ui.common-org.coxautoservices.com/history-web-component.js"
        }
      },
      element: "history-component",
      attributes: {deployment: "live", partition: "prod"}
    }
  };
};
