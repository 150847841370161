import {Runtime} from "./Runtime";
import {
  asRuntimeResolver,
  isRuntimeResolver,
  isRuntimeResolverFn,
  RuntimeResolver,
  RuntimeResolverFn
} from "./RuntimeResolver";
import {
  DefaultRuntimeCollection,
  DefaultRuntimeCollectionOptions
} from "./DefaultRuntimeCollection";
import {SingleRuntimeCollection} from "./SingleRuntimeCollection";

export interface RuntimeArray<M> {
  items: Runtime<M>[];
  options?: DefaultRuntimeCollectionOptions;
}

function isRuntimeArray<M>(arg: any): arg is RuntimeArray<M> {
  return "items" in arg && Array.isArray(arg.items);
}

export function newRuntimeResolver<M>(
  runtimes:
    | Runtime<M>
    | Runtime<M>[]
    | RuntimeArray<M>
    | RuntimeResolver<M>
    | RuntimeResolverFn<M>
): RuntimeResolver<M> {
  if (isRuntimeResolver(runtimes)) {
    return runtimes;
  }
  if (isRuntimeResolverFn(runtimes)) {
    return asRuntimeResolver(runtimes);
  }
  if (isRuntimeArray(runtimes)) {
    return new DefaultRuntimeCollection(runtimes.items, runtimes.options);
  }
  if (Array.isArray(runtimes)) {
    return new DefaultRuntimeCollection(runtimes);
  }
  return new SingleRuntimeCollection(runtimes);
}
