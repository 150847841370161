import {
  ClientEntity,
  OperationalEntity
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {toCacmSource} from "./toCacmSource";
import {toOrganizationInformation} from "./toOrganizationInformation";
import {toOems} from "./toOems";

/**
 * This function creates a new {@link OperationalEntity} from a
 * {@link ClientEntity} serving as the source of initial
 * values for the {@link OperationalEntity} being constructed.
 *
 * It is expected that the returned entity will enter a construction
 * process in the UI until the entity has been satisfactorily
 * configured at which point it will be saved.
 *
 * @param source The {@link ClientEntity} serving as the source
 *               of initial values for the {@link OperationalEntity}
 *               being constructed
 */
export const newOperationalEntityFrom = (
  source: ClientEntity
): OperationalEntity => ({
  id: "",
  sources: [toCacmSource(source.caId)],
  organizationInformation: toOrganizationInformation(source),
  oems: source.clientNameplates ? toOems(source.clientNameplates) : [],
  statuses: [],
  boidAssignments: []
});
