import {FC, useState} from "react";
import {PageTemplate} from "../../layout";
import {useNavigation, useSidebarItems} from "../../hooks";
import {SearchBar} from "../../search/search-bar";
import {OperationalRecentlyViewedSection} from "./OperationalRecentlyViewedSection";
import {useTranslation} from "react-i18next";
import "./OperationalHomePage.scss";
export const OperationalHomePage: FC<{}> = () => {
  const [quickSearchValue, setQuickSearchValue] = useState("");
  const navigation = useNavigation();
  const sidebarItems = useSidebarItems("home");
  const {t} = useTranslation();
  const submitSearch = (event: Event, query: string) => {
    navigation.navigateToSearch(event, query);
  };

  return (
    <PageTemplate
      id={"home-page"}
      title={"Home Page"}
      sidebarItems={sidebarItems}>
      <div className={"quick-search-box-container"}>
        <h1> {t("entity-search.search")} </h1>
        <SearchBar
          id={"quick-search-box"}
          query={quickSearchValue}
          onQueryChange={setQuickSearchValue}
          onSearch={submitSearch}
          analyticsLocation={"home-page"}
          displayLabel={false}
        />
      </div>
      <div className={"page-content"}>
        <OperationalRecentlyViewedSection />
      </div>
    </PageTemplate>
  );
};
