import {FC, MouseEventHandler} from "react";
import {ClientHierarchyNode} from "./ClientHierarchyNode";
import {ClientAddress} from "@common-core/coat-operational-hierarchy-appsync-model";
import "./ClientHierarchyNodeLabel.scss";

export interface ClientHierarchyNodeLabelProps {
  clientHierarchyNode: ClientHierarchyNode;
  onClick?: MouseEventHandler;
}
export const ClientHierarchyNodeLabel: FC<ClientHierarchyNodeLabelProps> = ({
  clientHierarchyNode,
  onClick
}) => {
  let addressToDisplay: ClientAddress | undefined = undefined;
  const {custName, addresses, custStatus} = clientHierarchyNode;
  if (addresses?.length) {
    addressToDisplay =
      addresses.find(address => address.primaryFlag?.toUpperCase() === "Y") ||
      addresses[0];
  }
  return (
    <div
      id={`client-hierarchy-node-label-${clientHierarchyNode.caId}`}
      data-testid={`client-hierarchy-node-label-${clientHierarchyNode.caId}`}
      className={"client-hierarchy-node-label"}
      onClick={onClick}>
      <div className={"dbaName"}>{custName}</div>
      <div className={"address-line"}>
        {addressToDisplay?.addrLine1 +
          ", " +
          (addressToDisplay?.addrLine2
            ? addressToDisplay?.addrLine2 + ", "
            : "") +
          addressToDisplay?.city +
          ", " +
          addressToDisplay?.provinceCd}
      </div>
    </div>
  );
};
