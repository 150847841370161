import {
  FC,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from "react";
import {Button} from "@interstate/components/Button";
import {ComboBox} from "@interstate/components/ComboBox";
import {SlideOut} from "@interstate/components/SlideOut";
import {Runtime, RuntimeTransform} from "@common-core/runtime-js/runtime";
import {useRuntimeController} from "../context";
import {
  EnvironmentProps,
  MetadataProps,
  RuntimeEnvironmentSection
} from "../ui";
import {RuntimeFilter} from "./RuntimeFilter";
import {useAvailableRuntimes} from "./useAvailableRuntimes";
import "./RuntimeControl.scss";

export interface RuntimeControlProps<M, E> {
  useInspector?: (current: Runtime<M>) => boolean;
  environmentUi?: FC<EnvironmentProps<E>>;
  metadataUi?: FC<MetadataProps<M>>;
  filter?: RuntimeFilter<M>;
  grouper?: RuntimeTransform<M, string>;
  panelWidth?: string | number;
  displayOn?: (event: KeyboardEvent) => boolean;
}

export function RuntimeControl<M, E>({
  useInspector = () => true,
  environmentUi,
  metadataUi,
  filter,
  grouper,
  panelWidth = "75%",
  displayOn = event => event.altKey && event.metaKey && event.code === "KeyR"
}: RuntimeControlProps<M, E>): ReactElement<any, any> {
  // Playing tricks to avoid a really nasty prettier setting
  const controller = useRuntimeController<M, E>();
  const [selected, setSelected] = useState<Runtime<M>>(
    () => controller.current().runtime
  );
  const [visible, setVisible] = useState<boolean>(false);
  const keyDownHandler: (event: KeyboardEvent) => void = useCallback(
    (event: KeyboardEvent): void => {
      if (displayOn(event)) {
        setVisible(true);
      }
    },
    [displayOn, setVisible]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [keyDownHandler]);

  const activateSelectedRuntime: MouseEventHandler<HTMLButtonElement> = () => {
    setVisible(false);
    controller.activate(selected);
  };

  const options = useAvailableRuntimes(selected, {filter, grouper});
  const {runtime: current} = controller.current();
  const inspecting = useInspector(current);

  return (
    <SlideOut
      id={"runtime-control-drawer"}
      data-testid={"runtime-control-drawer"}
      className={"runtime-control-drawer"}
      position={"left"}
      show={visible}
      header={"Runtime Control"}
      panelWidth={panelWidth}
      onHide={() => setVisible(false)}>
      <section data-testid={"runtime-control"} className={"runtime-control"}>
        <div className={"runtime-activator"}>
          <ComboBox
            id={"supported-runtimes"}
            data-testid={"supported-runtimes"}
            label={"Supported Runtimes"}
            selectionLimit={1}
            size={"small"}
            options={options}
            value={options.filter(option => option.selected)}
            onChange={event => {
              if (
                event.target.value.length === 1 &&
                event.target.value[0] !== undefined
              ) {
                const option = event.target.value[0];
                setSelected(controller.find(option.value));
              }
            }}
          />
          <Button
            htmlId={"activate-runtime-button"}
            data-testid={"activate-runtime-button"}
            className={"activate-runtime-button"}
            buttonStyle={"primary"}
            disabled={current.id === selected.id}
            onClick={activateSelectedRuntime}>
            Activate
          </Button>
        </div>
        <h2>{selected.id}</h2>
        {environmentUi && inspecting && (
          <RuntimeEnvironmentSection
            runtime={selected}
            metadataUi={metadataUi}
            environment={controller.environment(selected)}
            environmentUi={environmentUi}
          />
        )}
      </section>
    </SlideOut>
  );
}
