import {identity} from "../util";
import {
  SearchParameterArguments,
  resolveSearchParameter
} from "./resolveSearchParameter";

export interface StoredSearchParameterArguments<R>
  extends SearchParameterArguments<R> {
  key: string;
  storage?: Storage;
  clear?: string;
}

/**
 * Delegates to {@link resolveSearchParameter} to resolve the named URL
 * search parameter. If the requested parameter is present, then the
 * supplied storage is updated with the resolved value. If the requested
 * parameter is not present then the value of the specified storage
 * key is returned if bound, otherwise the default value is returned.
 *
 * @param parameters The collection of parameters to examine
 * @param name The name of the parameter of interest
 * @param defaultValue The default value to use if the parameter is not present;
 * null by default
 * @param mapper A function to map the resolved search parameter
 * @param key The key under which the value is bound in the specified {@link Storage}
 * @param storage The {@link Storage} holding the resolved value;
 * defaults to {@link sessionStorage}
 * @param clear The parameter value that will cause the stored value to be cleared;
 * the {@link defaultValue} will be resolved
 */
export function resolveStoredSearchParameter<R>({
  parameters,
  name,
  mapper,
  defaultValue = null,
  key,
  storage = sessionStorage,
  clear = "clear"
}: StoredSearchParameterArguments<R>): R | null | undefined {
  // Resolve the raw search parameter value
  const resolved = resolveSearchParameter<string>({
    parameters,
    name,
    mapper: identity<string>,
    defaultValue: null
  });
  if (resolved) {
    if (resolved === clear) {
      storage.removeItem(key);
    } else {
      const mapped = mapper(resolved);
      if (mapped) {
        storage.setItem(key, resolved);
        return mapped;
      }
    }
  }
  // Value was not resolved, not mappable, or cleared
  return mapper(storage.getItem(key) || defaultValue);
}
