import {FC, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {ArrowUturnLeftIcon} from "@interstate/components/Icons";
import {Button} from "@interstate/components/Button";
import {EventHandler, useNavigation} from "../hooks";
import "./BackButton.scss";

export enum Pages {
  Search = "search",
  Home = "home",
  ClientSearch = "client-search"
}

export const BackToButton: FC<{page: Pages}> = ({page}) => {
  const {t} = useTranslation();
  const {navigateToHome, navigateToSearch, navigateToClientSearch} =
    useNavigation();
  const navigateFunction = useMemo<EventHandler>(() => {
    switch (page) {
      case Pages.Home:
        return navigateToHome;
      case Pages.Search:
        return navigateToSearch;
      case Pages.ClientSearch:
        return navigateToClientSearch;
    }
  }, [navigateToClientSearch, navigateToHome, navigateToSearch, page]);

  return (
    <Button
      id={"back-to-button"}
      data-testid={"back-to-button"}
      className={"back-to-button"}
      buttonStyle={"tertiary"}
      startIcon={<ArrowUturnLeftIcon />}
      onClick={navigateFunction}>
      {t("navigation.back", {location: t(`navigation.${page}`)})}
    </Button>
  );
};
