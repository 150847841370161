import {FC, PropsWithChildren} from "react";
import {SectionProps, RowSection} from "@common-core/react-content-sections";
import "./TabRow.scss";

export const TabRow: FC<PropsWithChildren<SectionProps>> = ({
  name,
  qualifier,
  header,
  description,
  children
}) => {
  return (
    <RowSection
      type={"tab"}
      name={name}
      qualifier={qualifier}
      header={header}
      description={description}>
      {children}
    </RowSection>
  );
};
