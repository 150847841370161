import {
  ClientEntity,
  Nameplate,
  SubAccount
} from "@common-core/coat-operational-hierarchy-appsync-model";

export interface ClientHierarchyTableData {
  key: string;
  name: string;
  city: string;
  state: string;
  nameplates: Nameplate[];
  subaccounts: SubAccount[];
  status: string;
}

export function toClientTableData(
  entity: ClientEntity
): ClientHierarchyTableData {
  return <ClientHierarchyTableData>{
    key: entity.caId,
    name: entity.custName,
    city: entity.addresses?.at(0)?.city || "",
    state: entity.addresses?.at(0)?.provinceCd || "",
    nameplates: entity.clientNameplates || [],
    subaccounts: entity.subaccounts || [],
    status: entity.custStatus || []
  };
}

/**
 * Filters out the typename returned with all apollo graphql queries.
 * .reduce builds a new object from the filtered array linkedSolutions and returns it
 * */
//
// function filterTypeName(subaccounts: SubAccount[]): any {
//   return Object.keys(subaccounts)
//     .filter(subaccount => subaccount != "__typename")
//     .reduce((obj, subaccount: string) => {
//       return {
//         ...obj,
//         [subaccount]: subaccounts[subaccount]
//       };
//     }, {});
// }
