import {FC, PropsWithChildren, ReactNode, useState} from "react";
import {ActivityIndicatorContext} from "./ActivityIndicatorContext";
import {ActivityIndicator} from "./ActivityIndicator";

export interface ActivityIndicatorProviderProps {
  working?: boolean;
  indicator?: ReactNode;
}

export const ActivityIndicatorProvider: FC<
  PropsWithChildren<ActivityIndicatorProviderProps>
> = ({working = true, indicator, children}) => {
  const controller = useState<boolean>(working);
  return (
    <ActivityIndicatorContext.Provider value={controller}>
      {controller[0] && (indicator || <ActivityIndicator />)}
      {children}
    </ActivityIndicatorContext.Provider>
  );
};
