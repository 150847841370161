import {FC, MouseEvent} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "@interstate/components/Button";
import "./SaveOperationalEntityButton.scss";

export type SaveHandler = (event: MouseEvent<HTMLButtonElement>) => void;

export interface SaveOperationalEntityButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onClick: SaveHandler;
}

export const SaveOperationalEntityButton: FC<
  SaveOperationalEntityButtonProps
> = ({disabled, loading, onClick}) => {
  const {t} = useTranslation();
  return (
    <Button
      id={"entity-save-button"}
      data-testid={"entity-save-button"}
      className={"entity-save-button"}
      buttonStyle={"primary"}
      disabled={disabled}
      isLoading={loading}
      onClick={event => onClick(event)}>
      {t("entity-detail.save-button-text")}
    </Button>
  );
};
