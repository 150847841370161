import {
  ClientAddress,
  ClientEntity,
  OrganizationInformation
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {toOrganizationAddress} from "./toOrganizationAddress";
import {toTelecomNumbers} from "./toTelecomNumbers";

// TODO: Determine the range of actual values for the primaryFlag value
const primaryFlagValues = ["Y", "YES", "T", "TRUE", "1"];

const primaryAddress = (address: ClientAddress): boolean => {
  return primaryFlagValues.includes(address.primaryFlag?.toUpperCase());
};

export const toOrganizationInformation = (
  source: ClientEntity
): OrganizationInformation => ({
  dbaName: source.custName,
  legalName: source.custLegalName,
  // Per Mackenzie, it's ok to set status as IN BUSINESS on create
  status: "IN BUSINESS",
  telecomNumbers: toTelecomNumbers(source.custPhoneNumber),
  address:
    source.addresses && source.addresses.length > 0
      ? toOrganizationAddress(
          source.addresses.find(primaryAddress) || source.addresses[0]
        )
      : undefined
});
