import {RuntimeTransform} from "./RuntimeTransform";

export interface EnvironmentResolver<M, E> {
  resolve: RuntimeTransform<M, E>;
}

export function isEnvironmentResolver<M, E>(
  arg: any
): arg is EnvironmentResolver<M, E> {
  return !!arg.resolve && typeof arg.resolve === "function";
}

export function asRuntimeTransform<M, E>(
  resolver: EnvironmentResolver<M, E>
): RuntimeTransform<M, E> {
  return runtime => resolver.resolve(runtime);
}

export function asEnvironmentResolver<M, E>(
  transform: RuntimeTransform<M, E>
): EnvironmentResolver<M, E> {
  return {resolve: transform};
}
