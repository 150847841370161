import {createRoot} from "react-dom/client";
import "./i18n";
import "./index.scss";
import {Application} from "./Application";

if (module.hot) {
  module.hot.accept();
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById("root")!).render(<Application />);
