import {Runtime, RuntimeTransform} from "@common-core/runtime-js/runtime";
import {Metadata} from "./Metadata";
import {DeploymentType} from "./DeploymentType";

export const grouper: RuntimeTransform<Metadata, string> = (
  runtime: Runtime<Metadata>
): string => {
  const metadata = runtime.deployment.metadata;
  if (
    metadata?.type === DeploymentType.LIVE ||
    metadata?.type === DeploymentType.LIVE_NEW ||
    metadata?.type === DeploymentType.LIVE_INTEGRATION ||
    metadata?.type === DeploymentType.LIVE_NEW_INTEGRATION
  ) {
    return "Live Deployments";
  }
  if (
    metadata?.type === DeploymentType.DZ ||
    metadata?.type === DeploymentType.DZ_LEGACY ||
    metadata?.type === DeploymentType.DZ_INTEGRATION
  ) {
    return "Dangerzone Deployments";
  }
  return "Local Deployments";
};
