import {FC, PropsWithChildren, useState} from "react";
import {ClientEntity} from "@common-core/coat-operational-hierarchy-appsync-model";
import {ClientEntityContext} from "./ClientEntityContext";

export interface ClientEntityProviderProps {
  entity: ClientEntity;
}

export const ClientEntityProvider: FC<
  PropsWithChildren<ClientEntityProviderProps>
> = ({entity, children}) => {
  const [statefulEntity, setStatefulEntity] = useState(entity);

  return (
    <ClientEntityContext.Provider
      value={{
        entity: statefulEntity,
        setEntity: setStatefulEntity
      }}>
      {children}
    </ClientEntityContext.Provider>
  );
};
