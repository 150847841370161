import {FC, MouseEvent} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "@interstate/components/Button";

export type CancelHandler = (event: MouseEvent<HTMLButtonElement>) => void;

export interface CancelWorkflowButtonProps {
  disabled?: boolean;
  onClick: CancelHandler;
}

export const CancelWorkflowButton: FC<CancelWorkflowButtonProps> = ({
  disabled,
  onClick
}) => {
  const {t} = useTranslation();
  return (
    <Button
      id={"cancel-workflow-button"}
      data-testid={"cancel-workflow-button"}
      className={"cancel-workflow-button"}
      buttonStyle={"tertiary"}
      disabled={disabled}
      onClick={event => onClick(event)}>
      {t("entity-detail.cancel-workflow-button-text")}
    </Button>
  );
};
