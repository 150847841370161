import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  RecentlyViewed,
  useNavigation,
  useRecentlyViewedOperationalEntities,
  useStickyState
} from "../../hooks";
import {useAnalytics} from "@common-core/react-analytics";
import {useQuery} from "@apollo/client";
import {
  LookupOperationalEntitySummaries,
  OperationalSummaryResponse
} from "../../entities/backend";
import {Endpoints} from "../../runtime";
import {OperationalEntity} from "@common-core/coat-operational-hierarchy-appsync-model";
import {SplitButton} from "@interstate/components/SplitButton";
import {ArrowsUpDownIcon, ClockIcon} from "@interstate/components/Icons";
import {BodySection} from "@common-core/react-content-sections";
import "./OperationalRecentlyViewedSection.scss";
import {Skeleton} from "@interstate/components/Skeleton";
import ReactTimeAgo from "react-timeago";
import {OperationalEntityCard} from "../../entities/operational-hierarchy/card/OperationalEntityCard";

enum EntitySort {
  name = "name",
  time = "time"
}

export interface OperationalEntityWithTimestamp {
  id: string;
  timestamp: number;
  entity: OperationalEntity;
}

export const OperationalRecentlyViewedSection: FC = () => {
  const {browsedOperationalEntities} = useRecentlyViewedOperationalEntities();
  const {t} = useTranslation();
  const {productEvent} = useAnalytics();
  const {navigateToEntityFromEvent} = useNavigation();
  const timeDescriptor = t("homepage.recently-viewed.time-descriptor");
  const {loading, error, data} = useQuery<OperationalSummaryResponse>(
    LookupOperationalEntitySummaries,
    {
      variables: {
        ids: browsedOperationalEntities.map(
          (entity: RecentlyViewed) => entity.id
        )
      },
      context: {
        endpoint: Endpoints.APPSYNC
      }
    }
  );

  const [timestampedEntities, setTimestampedEntities] = useState<
    OperationalEntityWithTimestamp[]
  >([]);

  const [entitySort, setEntitySort] = useStickyState<EntitySort>(
    EntitySort.time,
    t("homepage.recently-viewed.title")
  );

  const updateEntitiesWithTimestamps = async (
    entities: OperationalEntity[]
  ) => {
    const timeStampedEntities: OperationalEntityWithTimestamp[] = [];
    entities.forEach(e => {
      const currentlyViewed: RecentlyViewed | undefined =
        browsedOperationalEntities.find(viewed => viewed.id === e.id);
      if (currentlyViewed !== undefined) {
        timeStampedEntities.push({
          id: currentlyViewed.id,
          timestamp: currentlyViewed.timestamp,
          entity: e
        });
      }
    });
    return timeStampedEntities;
  };

  const updateSort = (value: EntitySort) => {
    setEntitySort(value);
    productEvent({
      name: `${t(`analytics.event-name.recently-viewed`, {lng: "en"})}_sorted`,
      properties: {
        value: `${value}`,
        location: "Home Page",
        result: `${t(`analytics.result.recently-viewed`)} Sorted`
      }
    });
  };

  const sortEntities = (
    entities: OperationalEntityWithTimestamp[]
  ): OperationalEntityWithTimestamp[] => {
    if (entitySort === EntitySort.name.valueOf()) {
      return entities.sort((entityOne, entityTwo) =>
        entityOne.entity.organizationInformation.dbaName.localeCompare(
          entityTwo.entity.organizationInformation.dbaName
        )
      );
    }
    if (entitySort === EntitySort.time.valueOf()) {
      return entities.sort(
        (entityOne, entityTwo) => entityTwo.timestamp - entityOne.timestamp
      );
    }
    return entities;
  };

  const timeAgoFormatter = (value: number, unit: string, suffix: string) => {
    if (unit !== "second") {
      return [value, unit + (value !== 1 ? "s" : ""), suffix].join(" ");
    }

    if (suffix === "ago") {
      return "a few seconds ago";
    }
  };

  useEffect(() => {
    if (data?.entities) {
      updateEntitiesWithTimestamps(data.entities).then(entitiesWithTimestamps =>
        setTimestampedEntities(sortEntities(entitiesWithTimestamps))
      );
    }
  }, [data, entitySort]);
  return (
    <section
      className={"homepage-section"}
      id={`homepage-section-recently-viewed`}>
      <hgroup className={"homepage-section-headers"}>
        <h2>{t("homepage.recently-viewed.title")}</h2>
        <SplitButton
          id={`sort-menu-recently-viewed`}
          data-testid={`sort-menu-recently-viewed`}
          icon={<ArrowsUpDownIcon />}
          size={"small"}
          buttonStyle={"secondary"}
          options={[
            {
              id: `sort-header-recently-viewed`,
              value: "Sort",
              label: t("homepage.sort.title") || "???",
              divider: true,
              disabled: true
            },
            {
              id: `sort-by-time-recently-viewed`,
              value: EntitySort.time,
              label: timeDescriptor,
              onSelect: () => updateSort(EntitySort.time)
            },
            {
              id: `sort-by-name-recently-viewed`,
              value: EntitySort.name,
              label: t("homepage.sort.name") || "???",
              onSelect: () => updateSort(EntitySort.name)
            }
          ]}>
          <span className={"sort-cards-by"}>
            {entitySort === EntitySort.time
              ? timeDescriptor
              : t("homepage.sort.name")}
          </span>
        </SplitButton>
      </hgroup>
      <BodySection name={"homepage"}>
        {loading
          ? Array(12)
              .fill(true)
              .map((_, i) => (
                <Skeleton
                  key={`${i}-card-skeleton`}
                  variant={{height: 113.9, type: "rounded"}}
                />
              ))
          : timestampedEntities?.map(entity => (
              <OperationalEntityCard
                id={`homepage-entity-card-${entity.id}`}
                key={entity.id}
                entity={entity}
                className={"homepage-entity-card"}
                onClick={event => {
                  productEvent({
                    name: `${t(`analytics.event-name.recently-viewed`, {
                      lng: "en"
                    })}_selected`,
                    properties: {
                      location: "Home Page",
                      value: `${entity.id}`,
                      result: `${t(
                        `analytics.result.recently-viewed`
                      )} Entity Selected`
                    }
                  });
                  navigateToEntityFromEvent(event, entity.id);
                }}
                footer={
                  <div className={"homepage-entity-card-time"}>
                    <ClockIcon className={"homepage-entity-card-time-icon"} />
                    <span>{timeDescriptor}</span>
                    <ReactTimeAgo
                      date={entity.timestamp}
                      formatter={timeAgoFormatter}
                    />
                  </div>
                }
              />
            ))}
      </BodySection>
    </section>
  );
};
